import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const ContactPreviewPage = lazy(() => import('./ContactPreviewPage'));

export default (props: ComponentProps<typeof ContactPreviewPage>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ContactPreviewPage {...props} />
  </Suspense>
);
