import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const FavoriteSportsCompetitions = lazy(
  () => import('./FavoriteSportsCompetitions')
);

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <FavoriteSportsCompetitions {...props} />
  </Suspense>
);
