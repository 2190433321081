import React from 'react';

import { BellNotifications } from '@monorepo/auth';
import { Icon } from '@monorepo/icons';

import { transformCmsIcons } from '../../../../helpers';
import Item from '../Item';
import MetaVerseItem from '../MetaVerseItem';

type Props = {
  title: string;
  iconName: string;
  link: string;
  disabled?: boolean;
  itemClassName: string;
  disabledClassName: string;
  iconClassName: string;
  activeClassName: string;
};
const MenuItem = ({
  title,
  iconName,
  link,
  disabled,
  itemClassName,
  disabledClassName,
  iconClassName,
  activeClassName
}: Props) => {
  if (iconName === 'block-to-right') {
    return (
      <li className={itemClassName}>
        <MetaVerseItem link={link} className={itemClassName}>
          {Boolean(iconName) && (
            <Icon
              name={transformCmsIcons(iconName)}
              className={iconClassName}
            />
          )}
          {title}
        </MetaVerseItem>
      </li>
    );
  }

  if (iconName === 'notification') {
    return (
      <li className={itemClassName}>
        <BellNotifications />
      </li>
    );
  }

  return (
    <li className={itemClassName}>
      <Item
        link={link}
        disabled={disabled}
        className={`${itemClassName} ${disabled ? disabledClassName : ''}`}
        activeClassName={activeClassName}
      >
        {Boolean(iconName) && (
          <Icon name={transformCmsIcons(iconName)} className={iconClassName} />
        )}
        {title}
      </Item>
    </li>
  );
};
export default MenuItem;
