export enum FILTER_PARAMS {
  all = 'all',
  page = 'page',
  provider = 'provider',
  tag = 'tag',
  search = 'search',
  status = 'status',
  registrationStatus = 'registrationStatus',
  entryType = 'entryType'
}

export enum SORT_PARAMS {
  TIME = 'create_time',
  LIKE = 'likes_count'
}

export enum SORT_ORDER_PARAMS {
  ASC = 'asc',
  DESC = 'desc'
}

export enum SEARCH_STRING_PARAMS {
  code = 'code',
  redirectUrl = 'redirectUrl',
  backPath = 'backPath'
}
