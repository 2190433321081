import React from 'react';

import { InnerHTMLSnippet } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component, UseCountry } from '@monorepo/type';
import { Box, FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../constants';
import usePaymentService from '../../../hooks/usePaymentService';
import DepositForm from '../DepositForm';
import NoPaymentSystem from '../NoPaymentSystem';
import PaymentInfo from '../PaymentInfo';

import styles from './index.module.css';

type Props = {
  id: string;
  countriesFromJSON: UseCountry[];
  isWithProfileCheck?: boolean;
  defaultCountry: string;
  quickDeposit?: boolean;
};

const DepositMenu: Component<Props> = ({
  id,
  countriesFromJSON,
  isWithProfileCheck = false,
  defaultCountry,
  quickDeposit
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { system } = usePaymentService(SERVICE_TYPES.DEPOSIT, id);

  if (!system) {
    return <NoPaymentSystem />;
  }

  return (
    <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
      <PaymentInfo serviceType={SERVICE_TYPES.DEPOSIT} serviceId={id} />
      <Box className={styles.description}>{t('wallet.deposit_message')}</Box>
      <DepositForm
        key={id}
        serviceId={id}
        countriesFromJSON={countriesFromJSON}
        isWithProfileCheck={isWithProfileCheck}
        defaultCountry={defaultCountry}
        quickDeposit={quickDeposit}
      />
      <Box className={styles.subscription}>
        <InnerHTMLSnippet
          html={t(
            `wallet.deposit.description.${id}`,
            t(`wallet.deposit.description.undefined`)
          )}
        />
      </Box>
    </FlexBox>
  );
};

export default DepositMenu;
