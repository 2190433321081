import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import {
  EllipsisText,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  getTooltipDataAttributes
} from '@monorepo/ui-kit';

import { TLinkConfig } from '../../types';
import supportChatOpen from '../../utils/supportChatOpen';

import styles from './index.module.css';

const HeaderMobLink = ({ config }: { config: TLinkConfig }) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { title, link, iconName, target } = config;
  const isSupportChat = link?.includes('openSupportChat');
  const titleName = t(`navigation.${title}`);
  const tooltipAttrs = useMemo(
    () =>
      getTooltipDataAttributes({
        content: titleName,
        hidden: !title
      }),
    [title]
  );

  const onMenuItemClick = useCallback(
    (event: React.MouseEvent) => {
      if (isSupportChat) {
        event.stopPropagation();
        event.preventDefault();
        supportChatOpen();
      }
    },
    [isSupportChat]
  );

  return (
    <NavLink key={title} to={link} target={target} onClick={onMenuItemClick}>
      <FlexBox
        direction={FLEX_DIRECTION.column}
        justify={FLEX_JUSTIFY.center}
        {...tooltipAttrs}
      >
        <Icon name={iconName} title={title} className={styles.icon} />
        <EllipsisText className={styles.title}>{titleName}</EllipsisText>
      </FlexBox>
    </NavLink>
  );
};

export default HeaderMobLink;
