import { useSiteSettings } from '@monorepo/site-settings';
import { TQueryResult } from '@monorepo/type';

import { TGetRegionsRequest, TNormalizedRegions } from '../../types';
import useGetData from '../useGetData';
import useGetRegions from '../useGetRegions';
import useSportsParams from '../useSportsParams';

const useGetMenuRegions = (
  args: Partial<TGetRegionsRequest> = {},
  options = {}
) => {
  const { gameType, sportAlias, time, search, competitionIds, gameIds } =
    useSportsParams();

  const { languages } = useSiteSettings();

  return useGetRegions(
    {
      gameType: gameType!,
      sportAlias,
      time,
      search,
      competitions: competitionIds,
      gameIds,
      languages,
      ...args
    },
    options
  );
};

export const useMenuRegionsData = <
  ReturnType = any,
  QueryResultType = TNormalizedRegions
>(
  dataSelector: (result: TQueryResult<QueryResultType>) => any,
  queryArgs: Partial<TGetRegionsRequest> = {},
  queryOptions: Record<string, any> = {}
) =>
  useGetData<ReturnType, QueryResultType>(
    useGetMenuRegions,
    dataSelector,
    queryArgs,
    queryOptions
  );

export default useGetMenuRegions;
