import { createApi } from '@reduxjs/toolkit/query/react';

import { unsubscribe } from '@monorepo/common';
import { wsBaseQuery } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../constants';
import {
  TBetInfoRequest,
  TBets,
  TBetsHistoryRequest,
  TBoostedOddsResponse,
  TCompetitionsListRequest,
  TGameEvent,
  TGameEventRequest,
  TGameIdRequest,
  TGameResults,
  TGetCompetitionRequest,
  TGetCompetitionsRequest,
  TGetCompetitionsWithSportsArgs,
  TGetEventMarketsRequest,
  TGetGameRequest,
  TGetGamesRequest,
  TGetMarketsRequest,
  TGetMatchInfo,
  TGetRegionsRequest,
  TGetSportsRequest,
  TNormalizedCompetition,
  TNormalizedCompetitions,
  TNormalizedGames,
  TNormalizedLineMarkets,
  TNormalizedMarketGroups,
  TNormalizedPopularCompetitions,
  TNormalizedRegions,
  TNormalizedSports,
  TResultCompetitions,
  TResultsGamesRequest
} from '../types';

import getActiveCompetitions from './api/getActiveCompetitions';
import getBet from './api/getBet';
import getBetInfo from './api/getBetInfo';
import getBetsCount from './api/getBetsCount';
import getBetsHistory from './api/getBetsHistory';
import getBoostedOdds from './api/getBoostedOdds';
import getCompetition from './api/getCompetition';
import getCompetitions from './api/getCompetitions';
import getCompetitionsWithSports from './api/getCompetitionsWithSports';
import getFavoriteTeam from './api/getFavoriteTeam';
import getFreeBet from './api/getFreeBet';
import getGame from './api/getGame';
import getGameDetailsForHistory from './api/getGameDetailsForHistory';
import getGameInfo from './api/getGameInfo';
import getGameMarkets from './api/getGameMarkets';
import getGames from './api/getGames';
import getMarketGroups from './api/getMarketGroups';
import getMarkets from './api/getMarkets';
import getMarketTypes from './api/getMarketTypes';
import getMatchScores from './api/getMatchScores';
import getMaxBet from './api/getMaxBet';
import getRegions from './api/getRegions';
import getResultGames from './api/getResultGames';
import getSports from './api/getSports';
import getStream from './api/getStream';
import getStreams from './api/getStreams';
import getSuggestedGames from './api/getSuggestedGames';
import getTeamMatches from './api/getTeamMatches';
import makeCashOut from './api/makeCashOut';
import placeBet from './api/placeBet';
import removeFavoriteTeam from './api/removeFavoriteTeam';
import setFavoriteTeam from './api/setFavoriteTeam';

export const sportsBookApi = createApi({
  reducerPath: 'sportsBookApi',
  baseQuery: wsBaseQuery(),
  tagTypes: [
    SPORTS_QUERY_KEYS.BETS,
    SPORTS_QUERY_KEYS.BET,
    SPORTS_QUERY_KEYS.BET_INFO,
    SPORTS_QUERY_KEYS.COMPETITION,
    SPORTS_QUERY_KEYS.COMPETITIONS,
    SPORTS_QUERY_KEYS.POPULAR_COMPETITIONS,
    SPORTS_QUERY_KEYS.EVENT_MARKETS,
    SPORTS_QUERY_KEYS.GAME,
    SPORTS_QUERY_KEYS.GAMES,
    SPORTS_QUERY_KEYS.MARKET_GROUPS,
    SPORTS_QUERY_KEYS.MARKETS,
    SPORTS_QUERY_KEYS.REGIONS,
    SPORTS_QUERY_KEYS.SPORTS
  ],
  endpoints: (builder) => ({
    unsubscribe: builder.mutation(unsubscribe()),
    getMarketTypes: builder.query(getMarketTypes()),
    getMaxBet: builder.query(getMaxBet()),
    getBetsHistory: builder.query<TBets[], TBetsHistoryRequest>(
      getBetsHistory()
    ),
    getBetInfo: builder.query<TBets[], TBetInfoRequest>(getBetInfo()),
    getMatchScores: builder.query<TGetMatchInfo | {}, TGameIdRequest>(
      getMatchScores()
    ),
    getActiveCompetitions: builder.query<
      TResultCompetitions[],
      TCompetitionsListRequest
    >(getActiveCompetitions()),
    getResultGames: builder.query<TGameResults[], TResultsGamesRequest>(
      getResultGames()
    ),
    getGameInfo: builder.query<TGameEvent[], TGameEventRequest>(getGameInfo()),
    getStreams: builder.query<TGameEvent[], TGameEventRequest>(getStreams()),
    makeCashOut: builder.mutation(makeCashOut()),
    getBetsCount: builder.query<null | number, any>(getBetsCount()),
    getSuggestedGames: builder.query<null | number, any>(getSuggestedGames()),
    getBoostedOdds: builder.query<TBoostedOddsResponse, undefined>(
      getBoostedOdds()
    ),
    placeBet: builder.mutation<any, any>(placeBet()),
    getFreeBet: builder.query<any, any>(getFreeBet()),
    getFavoriteTeam: builder.query<any, any>(getFavoriteTeam()),
    getTeamMatches: builder.query<any, any>(getTeamMatches()),
    setFavoriteTeam: builder.mutation(setFavoriteTeam()),
    removeFavoriteTeam: builder.mutation(removeFavoriteTeam())
  })
});

sportsBookApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegions: builder.query<TNormalizedRegions, TGetRegionsRequest>(
      getRegions(sportsBookApi)
    ),
    getSports: builder.query<TNormalizedSports, TGetSportsRequest>(
      getSports(sportsBookApi)
    ),
    getGameMarkets: builder.query<any, TGetEventMarketsRequest>(
      getGameMarkets(sportsBookApi)
    ),
    getGame: builder.query<any, any>(getGame(sportsBookApi)),
    getGames: builder.query<TNormalizedGames, TGetGamesRequest>(
      getGames(sportsBookApi)
    ),
    getMarkets: builder.query<TNormalizedLineMarkets, TGetMarketsRequest>(
      getMarkets(sportsBookApi)
    ),
    getMarketGroups: builder.query<TNormalizedMarketGroups, TGetGameRequest>(
      getMarketGroups(sportsBookApi)
    ),
    getCompetitionsWithSports: builder.query<
      TNormalizedPopularCompetitions,
      TGetCompetitionsWithSportsArgs
    >(getCompetitionsWithSports(sportsBookApi)),
    getCompetitions: builder.query<
      TNormalizedCompetitions,
      TGetCompetitionsRequest
    >(getCompetitions(sportsBookApi)),
    getCompetition: builder.query<
      TNormalizedCompetition,
      TGetCompetitionRequest
    >(getCompetition(sportsBookApi)),
    getBet: builder.query<any, any>(getBet(sportsBookApi)),
    getGameDetailsForHistory: builder.query(
      getGameDetailsForHistory(sportsBookApi)
    ),
    getStream: builder.query<any, any>(getStream())
  })
});

export const {
  useMakeCashOutMutation,
  useGetBetsHistoryQuery,
  useGetBetInfoQuery,
  useGetBetsCountQuery,
  useGetSuggestedGamesQuery,
  useGetMatchScoresQuery,
  useGetActiveCompetitionsQuery,
  useGetResultGamesQuery,
  useGetGameInfoQuery,
  useGetGameQuery,
  useGetSportsQuery,
  useGetGamesQuery,
  useGetRegionsQuery,
  useGetMarketTypesQuery,
  useGetMaxBetQuery,
  useGetMarketGroupsQuery,
  useGetMarketsQuery,
  useGetGameMarketsQuery,
  useGetCompetitionsQuery,
  useGetCompetitionsWithSportsQuery,
  useGetBetQuery,
  usePlaceBetMutation,
  useGetCompetitionQuery,
  useGetBoostedOddsQuery,
  useGetGameDetailsForHistoryQuery,
  useGetStreamsQuery,
  useGetStreamQuery,
  useGetFreeBetQuery,
  useSetFavoriteTeamMutation,
  useRemoveFavoriteTeamMutation,
  useGetFavoriteTeamQuery,
  useGetTeamMatchesQuery
} = sportsBookApi;
