import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetSportsRequest } from '../../../types';
import {
  calcTimeFilter,
  createCompetitionWhereIdInQuery,
  createIdsQuery,
  createSearchCompetitionsNameQuery
} from '../../utils';
import { normalizeSportsResponse } from '../adapters/sportsAdapter';
import sportUpdater from '../updaters/sportUpdater';

const getSports = (api) => ({
  query: ({
    gameType,
    sportsType = [1, 4],
    search,
    time = '',
    competitions,
    languages,
    gameIds = []
  }: TGetSportsRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          sport: ['id', 'alias', 'name', 'order'],
          game: '@count'
        },
        where: {
          game: {
            type: gameType, // Prematch or live
            ...calcTimeFilter(time),
            ...(gameIds?.length && { id: createIdsQuery(gameIds) })
          },
          ...createSearchCompetitionsNameQuery(search, languages),
          ...createCompetitionWhereIdInQuery(competitions),
          sport: {
            type: {
              '@nin': sportsType // TODO decide what to do with this. 0: Electronic 1: Virtual 2: General 4: Racing 5: Fighting
            }
          }
        },
        subscribe: true
      }
    }
  }),
  keepUnusedDataFor: 5,
  providesTags: [SPORTS_QUERY_KEYS.SPORTS],
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => sportUpdater(draft, newData));
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.SPORTS]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      console.error(e);
    }
  },
  transformResponse: normalizeSportsResponse
});
export default getSports;
