import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const EventStatistics = lazy(() => import('./EventStatistics'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <EventStatistics {...props} />
  </Suspense>
);
