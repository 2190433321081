import { Context, createContext } from 'react';

type TinitialState = {
  isTableOpen: boolean;
  toggleTable: () => void;
};

export const initialState: TinitialState = {
  isTableOpen: false,
  toggleTable: () => {}
};

const TableContext: Context<TinitialState> = createContext(initialState);

export default TableContext;
