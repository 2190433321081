import PromoCategoriesList from '../common/PromoCategoriesList';
import ContentPage from '../components/ContentPage';
import FooterNavMenu from '../components/FooterNavMenu';
import Promo from '../components/Promo';
import Promotion from '../components/Promotion';
import PromotionFavorite from '../components/PromotionFavorite';
import Promotions from '../components/Promotions';
import PromotionsCategoriesFilter from '../components/PromotionsCategoriesFilter';
import PromotionsHeader from '../common/PromotionsHeader';
import PromotionSlots from '../components/PromotionSlots';
import PromotionsSlots from '../components/PromotionsSlots';
import VideoFavorite from '../components/VideoFavorite';

import COMPONENT_TYPES from './componentTypes';

const COMPONENTS_MAP = {
  [COMPONENT_TYPES.FOOTER_USER_MENU]: FooterNavMenu,
  [COMPONENT_TYPES.FAVORITE_WALL]: PromotionFavorite,
  [COMPONENT_TYPES.FAVORITE_VIDEO]: VideoFavorite,
  [COMPONENT_TYPES.CONTENT_PAGE]: ContentPage,
  [COMPONENT_TYPES.PROMOTIONS_LIST]: Promotions,
  [COMPONENT_TYPES.PROMOTIONS_HEADER]: PromotionsHeader,
  [COMPONENT_TYPES.PROMOTIONS_SLOTS_LIST]: PromotionsSlots,
  [COMPONENT_TYPES.PROMOTION]: Promotion,
  [COMPONENT_TYPES.PROMO]: Promo,
  [COMPONENT_TYPES.PROMOTION_SLOTS]: PromotionSlots,
  [COMPONENT_TYPES.PROMOTIONS_CATEGORIES_FILTER]: PromotionsCategoriesFilter,
  [COMPONENT_TYPES.PROMO_CATEGORIES_FILTER]: PromoCategoriesList
};

export default COMPONENTS_MAP;
