import React, { useState } from 'react';

import {
  useGetAvailableCurrencies,
  useGetChildBalances,
  useGetPartnerConfig,
  useLocalActions,
  useShowZeroBalance
} from '@monorepo/auth';
import useGetUser from '@monorepo/auth/src/hooks/useGetUser';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import Wallet from '../Wallet';

import styles from './index.module.css';

const MultiWalletMenu = ({
  onChangeCurrency,
  menuClassName
}: {
  onChangeCurrency: () => void;
  menuClassName?: string;
}) => {
  const { data: partnerConfig } = useGetPartnerConfig();
  const isShowZeroBalance = useShowZeroBalance();
  const { setShowZeroBalance } = useLocalActions();
  const { data: profile } = useGetUser();
  const { currencyName, parentCurrency } = profile || {};
  const { multWalletCurrencies } = partnerConfig || {};
  const { data: availableCurrencies } =
    useGetAvailableCurrencies(multWalletCurrencies);
  const { data: balances, isLoading } = useGetChildBalances();
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const [isCreateNew, setIsCreateNew] = useState(false);
  const balancesValue = !isShowZeroBalance
    ? balances?.filter((balance) => balance?.Balance !== 0)
    : balances;

  const allCurrencies = availableCurrencies
    ? Object.values(availableCurrencies)
    : [];
  const availableWallets = multWalletCurrencies?.filter(
    (x) => !balances?.map((curr) => curr?.CurrencyId).includes(x)
  );
  const availableNewWallets = availableWallets?.filter(
    (x) => allCurrencies?.map((curr) => curr?.iso_long).includes(x) || []
  );

  const modalClassName = `${styles.menu} ${menuClassName}`;
  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.root}
    >
      <FlexBox className={modalClassName} direction={FLEX_DIRECTION.column}>
        <FlexBox className={styles.header} justify={FLEX_JUSTIFY.end}>
          {availableWallets?.length > 0 && !isLoading ? (
            <Button
              intent={BUTTON_INTENT.tertiary}
              size={BUTTON_SIZE.h40}
              onClick={() => setIsCreateNew(!isCreateNew)}
              className={styles.button}
            >
              <Icon
                name={isCreateNew ? 'close_1' : 'plus_1'}
                className={styles.icon}
              />
              {t(isCreateNew ? `multiwallet.add.back` : `multiwallet.add.new`)}
            </Button>
          ) : null}
          {!isCreateNew && !isLoading && (
            <Button
              intent={BUTTON_INTENT.tertiary}
              size={BUTTON_SIZE.h40}
              onClick={() => setShowZeroBalance(!isShowZeroBalance)}
              className={styles.button}
            >
              <Icon
                name={`eye_${isShowZeroBalance ? '1' : '3'}`}
                className={styles.icon}
              />
              {t(`multiwallet.visibility.${Number(!isShowZeroBalance)}`)}
            </Button>
          )}
        </FlexBox>
        <FlexBox direction={FLEX_DIRECTION.column}>
          <Wallet
            handleClick={onChangeCurrency}
            balances={balancesValue}
            currencyName={currencyName}
            parentCurrency={parentCurrency}
            availableBalances={availableNewWallets}
            isCreateNew={isCreateNew}
            isLoading={isLoading}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
export default MultiWalletMenu;
