import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const mHashApi = createApi({
  reducerPath: 'mHashApi',
  baseQuery: fetchBaseQuery({ baseUrl: import.meta.env.VITE_APP_LOGGER_HOST }),
  endpoints: (builder) => ({
    getMHashId: builder.mutation({
      query: ({ sid, partnerId }) => ({
        url: '/bot-api/get-m-hash',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: { sid, partner_id: partnerId }
      })
    })
  })
});

export const { useGetMHashIdMutation } = mHashApi;
