import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { useGetFavoriteIdsData } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  getTooltipDataAttributes
} from '@monorepo/ui-kit';

import { FAVORITE_LINK_TYPES, FAVORITE_TYPE } from '../../constants';
import useGetFavoriteUrl from '../../hooks/useGetFavoritesUrl';

import styles from './index.module.css';

type Props = {
  type?: FAVORITE_LINK_TYPES;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  activeStatus?: boolean;
};

const isEmpty = (favorites: any, type: FAVORITE_LINK_TYPES) => {
  if (!favorites) {
    return true;
  }
  if (type === FAVORITE_LINK_TYPES.SPORT) {
    return !(
      favorites[FAVORITE_TYPE.COMPETITION]?.length ||
      favorites[FAVORITE_TYPE.GAME]?.length
    );
  }
  if (type === FAVORITE_LINK_TYPES.CASINO) {
    return !favorites[FAVORITE_TYPE.CASINO]?.length;
  }
  if (type === FAVORITE_LINK_TYPES.WALL) {
    return !favorites[FAVORITE_TYPE.WALL]?.length;
  }
  // custom type for game with id
  return !favorites[type]?.length;
};

const FavoriteLinkButton: CustomFC<Props> = ({
  type = FAVORITE_LINK_TYPES.SPORT,
  onClick,
  activeStatus = false
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();
  const navigate = useNavigate();
  const { isActive: isActivePage, getFavoriteUrl } = useGetFavoriteUrl();
  const allFavoritesState = useGetFavoriteIdsData();
  const onClickAction = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
      onClick ? onClick(e) : navigate(getFavoriteUrl(type)),
    [onClick, getFavoriteUrl, type]
  );
  const styleType =
    type === FAVORITE_LINK_TYPES.CASINO || FAVORITE_LINK_TYPES.WALL
      ? type
      : FAVORITE_LINK_TYPES.SPORT;

  const classNames = `${styles.favorite} ${
    isEmpty(allFavoritesState, type) && !isActivePage ? styles.empty : ''
  } ${activeStatus || isActivePage ? styles.active : ''}`;
  const iconClassName = `${styles.icon} ${styles[styleType] || ''}`;
  const tooltipAttrs = useMemo(
    () =>
      getTooltipDataAttributes({
        content: t('filter.favorites'),
        hidden: isDesktop
      }),
    [isDesktop]
  );

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={classNames}
    >
      <Button
        intent={BUTTON_INTENT.tertiary}
        isOnlyIcon
        onClick={onClickAction}
        {...tooltipAttrs}
      >
        <Icon
          name={activeStatus || isActivePage ? 'star_3' : 'star_3_1'}
          className={iconClassName}
          title={t('filter.favorites')}
        />
      </Button>
    </FlexBox>
  );
};

export default FavoriteLinkButton;
