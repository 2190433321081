import get from 'lodash/get';

import { TNormalizedSports, TSport } from '../../../types';

type TRawResponse = {
  data: {
    sport: Record<string, TSport>;
  };
  subid: string;
};

export const normalizeSportsResponse = (
  rawResponse: TRawResponse
): TNormalizedSports => {
  const sport = get(rawResponse, 'data.sport', {});
  const subid = get(rawResponse, 'subid', '');
  return {
    sport,
    subid
  };
};
