import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const PromotionsHeader = lazy(() => import('./PromotionsHeader'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <PromotionsHeader config={config} />
  </Suspense>
);
