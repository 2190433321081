import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const Footer = lazy(() => import('./Footer'));

export default () => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Footer />
  </Suspense>
);
