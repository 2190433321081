import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const BackHeaderResults = lazy(() => import('./BackHeaderResults'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BackHeaderResults {...props} />
  </Suspense>
);
