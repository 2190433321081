import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';

import { Icon } from '@monorepo/icons';
import { classNameProp } from '@monorepo/type';

import {
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_ALIGN,
  FLEX_DIRECTION
} from '../../constants';
import { ButtonProps } from '../../types';
import Button from '../Button';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

type TAccordionProps = {
  isDefaultOpen?: boolean;
  label?: ReactNode;
  activeClassName?: string;
  headerClassName?: string;
  headerIconClassName?: string;
  bodyClassName?: string;
  labelClassName?: string;
  buttonClassName?: string;
  intentType?: BUTTON_INTENT;
  buttonSize?: BUTTON_SIZE;
  innerRef?: any;
  onClick?: () => void;
  onChange?: (isOpen: boolean) => void;
  labelIcon?: string;
  labelIconClassName?: string;
} & Omit<ButtonProps, 'onClick' | 'onChange'> &
  classNameProp;

const Accordion: ForwardRefRenderFunction<void, TAccordionProps> = (
  {
    children,
    className = '',
    activeClassName = '',
    headerClassName = '',
    bodyClassName = '',
    labelClassName = '',
    headerIconClassName = '',
    labelIcon,
    labelIconClassName = '',
    intent = BUTTON_INTENT.tertiary,
    size = BUTTON_SIZE.h48,
    isDefaultOpen = false,
    label = null,
    innerRef,
    onClick,
    onChange
  },
  ref
) => {
  const [isOpen, setIsOpen] = useState<SetStateAction<boolean>>(
    isDefaultOpen as boolean
  );

  useImperativeHandle(ref, () => ({
    close: () => setIsOpen(false)
  }));

  useEffect(() => {
    setIsOpen(isDefaultOpen);
  }, [isDefaultOpen]);

  const toggleOpen = () => {
    if (onChange) {
      onChange(!isOpen);
    }
    setIsOpen((prev: boolean) => !prev);
  };

  return (
    <div
      className={`${styles.wrap} ${isOpen ? activeClassName : ''} ${className}`}
      onClick={onClick}
      ref={innerRef}
    >
      <Button
        intent={intent}
        size={size}
        onClick={toggleOpen}
        className={`${styles.header} ${headerClassName}`}
      >
        <FlexBox
          align={FLEX_ALIGN.center}
          className={`${styles.label} ${labelClassName}`}
        >
          {labelIcon && (
            <Icon
              name={labelIcon}
              className={`${styles.labelIcon} ${labelIconClassName}`}
            />
          )}
          {Boolean(label) && label}
        </FlexBox>
        <Icon
          name={`arrow_${isOpen ? 'up-2' : 'down-3'}`}
          className={`${styles.icon} ${headerIconClassName}`}
        />
      </Button>
      {isOpen && (
        <FlexBox
          direction={FLEX_DIRECTION.column}
          className={`${styles.body} ${bodyClassName}`}
        >
          {children}
        </FlexBox>
      )}
    </div>
  );
};

export default forwardRef(Accordion);
