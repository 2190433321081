import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const GamesSearch = lazy(() => import('./GamesSearch'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <GamesSearch {...props} />
  </Suspense>
);
