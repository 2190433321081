import React from 'react';
import {
  buildCountryData,
  defaultCountries,
  parseCountry
} from 'react-international-phone';
import {
  CountryData,
  ParsedCountry
} from 'react-international-phone/dist/types';

import { useGeoIP } from '@monorepo/cms';
import { useCountries } from '@monorepo/common';
import { ComponentWithConfig } from '@monorepo/type';
import { FLEX_ALIGN, FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import Form from './Form';

import styles from './index.module.css';

type TRegistrationProps = {
  defaultCountry: string;
  defaultCurrency: string;
  header: string;
  snippets: Record<string, number>;
  className?: string;
};

const Registration: ComponentWithConfig<TRegistrationProps> = ({ config }) => {
  const { defaultCountry, defaultCurrency, snippets } = config;
  const { data: dataCountry } = useGeoIP();
  const countriesFromJSON = useCountries();
  const code = dataCountry?.countryCode.toLowerCase();
  const country =
    countriesFromJSON.map((item) => item.value).find((i) => i === code) ||
    defaultCountry;
  const countries = countriesFromJSON.reduce(
    (acc: CountryData[], { value, label, phoneCode }) => {
      const countryData = defaultCountries.find((item) => {
        const { iso2 } = parseCountry(item);
        return value === iso2;
      });
      if (!countryData) {
        const newCountryData = buildCountryData({
          name: label,
          iso2: value,
          dialCode: phoneCode
        } as ParsedCountry);
        return [...acc, newCountryData];
      }

      return [...acc, countryData];
    },
    []
  );

  return (
    <FlexBox
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
      className={styles.root}
    >
      {!!countriesFromJSON.length && (
        <Form
          countries={countries}
          countriesFromJSON={countriesFromJSON}
          defaultCountry={country}
          defaultCurrency={defaultCurrency}
          snippets={snippets}
          className={styles.form}
        />
      )}
    </FlexBox>
  );
};

export default Registration;
