import React, { Children, cloneElement, isValidElement } from 'react';

import { Component } from '@monorepo/type';

import styles from './index.module.css';

type Props = {
  isOnlyDesktop?: boolean;
  isOnlyMobile?: boolean;
};

const Sticky: Component<Props> = ({
  children,
  className = '',
  isOnlyDesktop = false,
  isOnlyMobile = false
}) => {
  const classes = `${isOnlyMobile ? '' : styles.desktop}
    ${isOnlyDesktop ? '' : styles.mobile}
    ${className}`;
  const props = { className: classes };

  return (
    <>
      {Children.map(children, (child) =>
        isValidElement(child) ? cloneElement(child, props) : null
      )}
    </>
  );
};

export default Sticky;
