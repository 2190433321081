import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { TRegisterCurasaoRequest } from '@monorepo/auth';
import { TPartnerConfig } from '@monorepo/auth/src/types';
import { SelectField } from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component, UseCountry } from '@monorepo/type';
import { FIELD_TONE } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TProp = {
  countries: UseCountry[];
  currencies: { value: string; label: string }[];
  desktopTone?: FIELD_TONE;
  partnerConfig: TPartnerConfig;
  watchField: string;
};

const CitizenshipCurrency: Component<TProp> = ({
  countries,
  currencies,
  desktopTone,
  partnerConfig,
  watchField
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { watch, setValue } = useFormContext();
  const citizenshipField = watch(watchField);

  useEffect(() => {
    const countryItem = countries.find((el) => el.value === citizenshipField);

    if (countryItem && countryItem.currency !== '') {
      const availableCurrency = currencies.find(
        (el) => el.value === countryItem.currency
      );
      setValue(
        'currency',
        availableCurrency ? countryItem.currency : partnerConfig.currency
      );
    }
  }, [citizenshipField]);

  return (
    <SelectField<TRegisterCurasaoRequest>
      id="currency"
      name="currency"
      disabled={partnerConfig?.supportedCurrencies.length === 1}
      // @ts-ignore
      options={currencies}
      label={t('registration.form.currency.label')}
      toneType={desktopTone}
      inputClassName={styles.select}
      labelClassName={styles.label}
      className={`${styles.inputRegistration} ${styles.currency}`}
      errorClassName={styles.error}
    />
  );
};
export default CitizenshipCurrency;
