import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const Favorites = lazy(() => import('./Favorites'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Favorites {...props} />
  </Suspense>
);
