import React from 'react';

import { CustomFC } from '@monorepo/type';

import { FLEX_ALIGN } from '../../../constants';
import { TNavigationProps } from '../../../types';
import FlexBox from '../../FlexBox';
import BaseNavigation from '../BaseNavigation';

import styles from './index.module.css';

const TabsNavigation: CustomFC<Omit<TNavigationProps, 'active'>> = ({
  items,
  replace = false,
  listClassName = '',
  wrapperClassName = '',
  linkClassName = '',
  itemClassName = '',
  activeClassName = '',
  isExact = false,
  theme = '',
  dataCustom = 'tabs-navigation',
  children
}) => (
  <FlexBox
    className={`${styles.wrap} ${wrapperClassName}`}
    align={FLEX_ALIGN.center}
    data-custom={dataCustom}
  >
    {children}
    <BaseNavigation
      items={items}
      replace={replace}
      isExact={isExact}
      linkClassName={`${styles.link} ${linkClassName}`}
      activeClassName={`${styles.active} ${activeClassName}`}
      itemClassName={`${styles.item} ${itemClassName}`}
      listClassName={`${styles.list} ${listClassName} ${theme}`}
    />
  </FlexBox>
);

export default TabsNavigation;
