import React from 'react';

import { ComponentsConfigProvider } from '@monorepo/page';

import RegionsMenu from '../../../../common/RegionsMenu';
import { POPULAR_ALIAS } from '../../../../constants';
import ExtraItems from '../ExtraItems';
import Wrapper from '../Wrapper';

type TProps = {
  sport: string;
  region?: string;
  rootPath: string;
};

const SelectedSportMenu: React.FC<TProps> = ({ sport, region, rootPath }) => (
  <Wrapper>
    <ComponentsConfigProvider params={{ withIcons: true }}>
      {sport === POPULAR_ALIAS || sport === 'favorite' ? (
        <ExtraItems sport={sport} />
      ) : (
        <RegionsMenu
          basePath={`${rootPath}/${sport}`}
          activeRegion={region || ''}
          activeSport={sport}
          withScrollbar={false}
        />
      )}
    </ComponentsConfigProvider>
  </Wrapper>
);

export default SelectedSportMenu;
