import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const NeedToVerifyEmail = lazy(() => import('./NeedToVerifyEmail'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <NeedToVerifyEmail config={config} />
  </Suspense>
);
