import React, { useEffect, useRef, useState } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import {
  Box,
  Button,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  Toggle
} from '@monorepo/ui-kit';

import { useSelectCookiesSettings } from '../../../../store/selectors';
import { TCookiesSettings } from '../../../../types';

import styles from './index.module.css';

type TDetailsAccordion = {
  isWithSwitcher?: boolean;
  title: string;
  name: string;
  description: string;
  setCookiesSettings: React.Dispatch<React.SetStateAction<TCookiesSettings>>;
  cookiesSettings: TCookiesSettings;
};

const DetailsAccordion = ({
  isWithSwitcher = false,
  title,
  cookiesSettings,
  setCookiesSettings,
  description,
  name
}: TDetailsAccordion) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const [isShow, setIsShow] = useState(false);
  const { [name]: storedCookie } = useSelectCookiesSettings() || {};
  const [isChecked, setIsChecked] = useState(cookiesSettings[name]);
  const innerRef = useRef(null);

  const toggleIsChecked = () => setIsChecked((prev: boolean) => !prev);
  const toggleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsShow((prev: boolean) => !prev);
  };

  useEffect(() => {
    setCookiesSettings({ ...cookiesSettings, [name]: isChecked });
  }, [isChecked]);

  useEffect(() => {
    if (storedCookie !== cookiesSettings[name]) setIsChecked(storedCookie);
  }, []);

  return (
    <div ref={innerRef} className={styles.wrapper}>
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.between}
        className={styles.accordion}
      >
        <Button className={styles.button} onClick={toggleOpen}>
          <Icon
            name={`arrow_${isShow ? 'up-1' : 'down-1'}`}
            className={styles.icon}
          />
          {title}
        </Button>
        {isWithSwitcher ? (
          <Box className={styles.switch}>
            <Toggle
              onChange={toggleIsChecked}
              checked={isChecked}
              dataCustom="cookies-settings-toggle"
            />
          </Box>
        ) : (
          <span className={styles.alwaysActive}>
            {t('cookie.details.always_active')}
          </span>
        )}
      </FlexBox>
      {isShow && <span className={styles.description}>{description}</span>}
    </div>
  );
};
export default DetailsAccordion;
