import React, { FC, ReactElement } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n/src';
import { Box } from '@monorepo/ui-kit/src';

import { useMenuSportsData } from '../../../../hooks/useGetMenuSports';
import { selectSportsWithOrder } from '../../../../store/api/selectors';
import SportItem from '../SportItem';
import MenuSkeleton from '../SportItemsListSkeleton';

type TSportsMenu = {
  activeSport: string;
  setSport: (sportAlias: string) => void;
  itemClassName?: string;
  noResultClassName: string;
  region?: string;
  rootPath: string;
};

const SportsMenu: FC<TSportsMenu> = ({
  setSport,
  activeSport,
  itemClassName,
  noResultClassName,
  region,
  rootPath
}): ReactElement => {
  const { data: sports, isLoading } = useMenuSportsData((result) =>
    selectSportsWithOrder(result)
  );

  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  if (isLoading) {
    return <MenuSkeleton length={20} />;
  }

  if (!isLoading && !sports.length)
    return (
      <Box className={noResultClassName}>
        {t('sportsbook.sportsmenu.noresults')}
      </Box>
    );

  return (
    <>
      {sports.map((sportId: number) => (
        <SportItem
          key={sportId}
          activeSport={activeSport}
          sportId={sportId}
          setSport={setSport}
          className={itemClassName}
          region={region}
          rootPath={rootPath}
        />
      ))}
    </>
  );
};

export default SportsMenu;
