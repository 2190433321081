import React from 'react';

import { Component } from '@monorepo/type';

import SportsSlider from '../../../../common/SportsSlider';
import { SportsSliderItemType } from '../../../../common/SportsSlider/types';
import { useLineGamesData } from '../../../../hooks/useGetLineGames';
import { selectSuggestedOrderedGames } from '../../../../store/api/selectors';
import { TGame, TSportsRecommendedConfig } from '../../../../types';

const PopularSlider: Component<TSportsRecommendedConfig> = ({
  showMoreButton,
  isWithButtons,
  isViewAll,
  title
}) => {
  const {
    data: games,
    isFetching,
    isUninitialized
  } = useLineGamesData<TGame[]>((result) =>
    selectSuggestedOrderedGames(result)
  );

  return (
    <SportsSlider
      title={title}
      showMoreButton={showMoreButton}
      isWithButtons={isWithButtons}
      isViewAll={isViewAll}
      itemType={SportsSliderItemType.Suggested}
      games={games}
      isFetching={isFetching}
      isUninitialized={isUninitialized}
    />
  );
};

export default PopularSlider;
