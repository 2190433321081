import React from 'react';

import { useComponentsConfig } from '@monorepo/page';
import { Component } from '@monorepo/type';

import { useMenuRegionsData } from '../../../../hooks/useGetMenuRegions';
import { selectCompetitionsByRegionId } from '../../../../store/api/selectors';
import MenuSkeleton from '../../../MenuSkeleton';
import Competition from '../Competition';

type Props = {
  regionId: number;
  basePath: string;
  activeSport?: string;
  region?: string;
};

const CompetitionsList: Component<Props> = ({
  regionId,
  basePath,
  region,
  activeSport
}) => {
  const { data: competitions, isLoading } = useMenuRegionsData(
    (result) => selectCompetitionsByRegionId(result, regionId),
    { sportAlias: activeSport }
  );

  const { withIcons } = useComponentsConfig<{
    withIcons?: boolean;
  }>();

  if (isLoading) {
    return <MenuSkeleton length={10} />;
  }

  if (!competitions) {
    return null;
  }

  return (
    <>
      {competitions.map((competition: number) => (
        <Competition
          key={competition}
          competitionId={competition}
          regionId={regionId}
          withIcons={Boolean(withIcons)}
          basePath={basePath}
          region={region}
          activeSport={activeSport}
        />
      ))}
    </>
  );
};

export default CompetitionsList;
