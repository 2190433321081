import {
  ar,
  cs,
  da,
  de,
  enUS,
  es,
  faIR,
  fr,
  hi,
  hy,
  ka,
  nl,
  pt,
  ptBR,
  ru,
  tr,
  uk
} from 'date-fns/locale';

export const LANGUAGE_CODES_MAP = {
  EN: 'en',
  CS: 'cs',
  DE: 'de',
  RU: 'ru',
  UK: 'uk',
  TR: 'tr',
  PT: 'pt',
  PB: 'pb', // pt-Br
  HY: 'hy',
  KA: 'ka',
  ES: 'es',
  FA: 'fa',
  AR: 'ar',
  FR: 'fr',
  DA: 'da',
  NL: 'nl',
  HI: 'hi',
  AM: 'am'
};

export const LANGUAGE_LONG_CODES_MAP = {
  [LANGUAGE_CODES_MAP.AR]: 'arb',
  [LANGUAGE_CODES_MAP.CS]: 'cze',
  [LANGUAGE_CODES_MAP.DA]: 'dan',
  [LANGUAGE_CODES_MAP.DE]: 'ger',
  [LANGUAGE_CODES_MAP.EN]: 'eng',
  [LANGUAGE_CODES_MAP.ES]: 'spa',
  [LANGUAGE_CODES_MAP.FA]: 'fas',
  [LANGUAGE_CODES_MAP.FR]: 'fra',
  [LANGUAGE_CODES_MAP.HI]: 'hin',
  [LANGUAGE_CODES_MAP.HY]: 'arm',
  [LANGUAGE_CODES_MAP.KA]: 'geo',
  [LANGUAGE_CODES_MAP.NL]: 'dut',
  [LANGUAGE_CODES_MAP.PB]: 'pt-br',
  [LANGUAGE_CODES_MAP.PT]: 'por',
  [LANGUAGE_CODES_MAP.RU]: 'rus',
  [LANGUAGE_CODES_MAP.TR]: 'tur',
  [LANGUAGE_CODES_MAP.UK]: 'ukr',
  [LANGUAGE_CODES_MAP.AM]: 'amh'
};

export const LANGUAGE_SWARM_CODES_MAP = {
  [LANGUAGE_CODES_MAP.AR]: 'arb',
  [LANGUAGE_CODES_MAP.CS]: 'cze',
  [LANGUAGE_CODES_MAP.DA]: 'dan',
  [LANGUAGE_CODES_MAP.DE]: 'ger',
  [LANGUAGE_CODES_MAP.EN]: 'eng',
  [LANGUAGE_CODES_MAP.ES]: 'spa',
  [LANGUAGE_CODES_MAP.FA]: 'fas',
  [LANGUAGE_CODES_MAP.FR]: 'fra',
  [LANGUAGE_CODES_MAP.HI]: 'hin',
  [LANGUAGE_CODES_MAP.HY]: 'arm',
  [LANGUAGE_CODES_MAP.KA]: 'geo',
  [LANGUAGE_CODES_MAP.NL]: 'dut',
  [LANGUAGE_CODES_MAP.PB]: 'por_2',
  [LANGUAGE_CODES_MAP.PT]: 'por',
  [LANGUAGE_CODES_MAP.RU]: 'rus',
  [LANGUAGE_CODES_MAP.TR]: 'tur',
  [LANGUAGE_CODES_MAP.UK]: 'ukr',
  [LANGUAGE_CODES_MAP.AM]: 'amh'
};

export enum NAMESPACES {
  ACCOUNT = 'account',
  CASINO = 'casino',
  CURRENCIES = 'currencies',
  COMMON = 'common',
  DYNAMIC = 'dynamic',
  FORMS = 'forms',
  SPORTSBOOK = 'sportsbook',
  COUNTRIES = 'countries',
  PAGES = 'pages',
  SOCIAL = 'social',
  MESSENGER = 'messenger',
  FAVORITES = 'favorites',
  TOURNAMENTS = 'tournaments'
}

export const DATE_LOCALE_MAP = {
  [LANGUAGE_CODES_MAP.CS]: cs,
  [LANGUAGE_CODES_MAP.EN]: enUS,
  [LANGUAGE_CODES_MAP.DA]: da,
  [LANGUAGE_CODES_MAP.DE]: de,
  [LANGUAGE_CODES_MAP.RU]: ru,
  [LANGUAGE_CODES_MAP.UK]: uk,
  [LANGUAGE_CODES_MAP.TR]: tr,
  [LANGUAGE_CODES_MAP.PT]: pt,
  [LANGUAGE_CODES_MAP.PB]: ptBR,
  [LANGUAGE_CODES_MAP.HY]: hy,
  [LANGUAGE_CODES_MAP.KA]: ka,
  [LANGUAGE_CODES_MAP.NL]: nl,
  [LANGUAGE_CODES_MAP.ES]: es,
  [LANGUAGE_CODES_MAP.FA]: faIR,
  [LANGUAGE_CODES_MAP.AR]: ar,
  [LANGUAGE_CODES_MAP.FR]: fr,
  [LANGUAGE_CODES_MAP.HI]: hi,
  [LANGUAGE_CODES_MAP.AM]: enUS
};
