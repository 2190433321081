import Cookies, { CookieAttributes } from 'js-cookie';

/**
 * Set cookie
 * @param {string} name
 * @param value
 * @param {{
 *   path: string|undefined,
 *   expires: number|undefined
 * }} settings
 */
export const setCookie = (
  name: string,
  value: any,
  settings?: CookieAttributes
) => {
  try {
    Cookies.set(name, value, settings);
  } catch (e) {
    console.warn(e, 'cookies error');
  }
};

export const getCookie = (name: string, defaultValue: any = '') => {
  try {
    return Cookies.get(name) || defaultValue;
  } catch (e) {
    console.warn(e, 'cookies error');
    return null;
  }
};

export const removeCookie = (name: string) => {
  try {
    return Cookies.remove(name);
  } catch (e) {
    console.warn(e, 'cookies error');
    return null;
  }
};

function blank(str: string) {
  return /^\s*$/.test(str);
}
export const isValidJson = (str: string) => {
  if (blank(str)) return false;
  const replacedStr = str
    .replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@')
    .replace(
      /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
      ']'
    )
    .replace(/(?:^|:|,)(?:\s*\[)+/g, '');
  return /^[\],:{}\s]*$/.test(replacedStr);
};
