import React, { useRef } from 'react';

import { Component } from '@monorepo/type';
import {
  Accordion,
  Box,
  BUTTON_INTENT,
  BUTTON_SIZE,
  EllipsisText
} from '@monorepo/ui-kit';

import SportColor from '../../../SportColor';
import SportIcon from '../../../SportIcon';

import styles from './index.module.css';

type Props = {
  isActive?: boolean;
  onClick?: () => void;
  iconName: string;
  name: string;
  count?: string | number;
  alias: string;
};

const SportMenuItemAccordion: Component<Props> = ({
  isActive = false,
  iconName,
  name,
  count,
  alias,
  children
}) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <Accordion
      className={styles.root}
      size={BUTTON_SIZE.h42}
      activeClassName={styles.active}
      headerIconClassName={styles.accordionIcon}
      headerClassName={styles.accordionHeader}
      isDefaultOpen={isActive}
      innerRef={ref}
      intent={BUTTON_INTENT.transparent}
      label={
        <>
          <SportColor alias={alias}>
            <Box className={styles.iconWrap}>
              <SportIcon name={iconName} className={styles.icon} />
            </Box>
          </SportColor>
          <EllipsisText className={styles.name}>{name}</EllipsisText>
          {Boolean(count) && <span className={styles.count}>{count}</span>}
        </>
      }
    >
      {children}
    </Accordion>
  );
};

export default SportMenuItemAccordion;
