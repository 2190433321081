import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const EventHeaderWrapper = lazy(() => import('./EventHeaderWrapper'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <EventHeaderWrapper {...props} />
  </Suspense>
);
