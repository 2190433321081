import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';

import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetGamesRequest } from '../../../types';
import { calcTimeFilter, createIdsQuery, createSearchQuery } from '../../utils';
import {
  normalizeGamesResponse,
  normalizeGamesResponseForSearch
} from '../adapters/gamesAdapter';

const getGames = (api) => ({
  query: ({
    gameType,
    sportAlias,
    regionAlias,
    competitionId,
    time,
    isPopular,
    limit,
    search,
    gameIds,
    nodeLimits,
    languages
  }: TGetGamesRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          sport: ['id', 'alias', 'name', 'order'],
          region: ['id', 'alias', 'name', 'order'],
          competition: ['id', 'name', 'order'],
          game: [
            'id',
            'markets_count',
            'is_blocked',
            'is_stat_available',
            'show_type',
            'sport_alias',
            'region_alias',
            'team1_name',
            'team2_name',
            'team1_id',
            'team2_id',
            'start_ts',
            'textInfo',
            'is_live',
            'info',
            'stats'
          ]
        },
        where: {
          game: {
            ...((gameType || isNumber(gameType)) && {
              type: gameType
            }),
            ...calcTimeFilter(time),
            ...(isPopular && { promoted: isPopular }),
            ...(limit && { '@limit': limit }),
            ...createSearchQuery(search, languages),
            ...(gameIds?.length && { id: createIdsQuery(gameIds) }),
            ...(nodeLimits && { '@node_limit': nodeLimits })
          },
          ...(sportAlias && {
            sport: {
              alias: sportAlias
            }
          }),
          ...(regionAlias && {
            region: {
              alias: regionAlias
            }
          }),
          ...(competitionId && {
            competition: {
              id: competitionId
            }
          })
        },
        subscribe: !search
      }
    }
  }),
  keepUnusedDataFor: 5,
  providesTags: [SPORTS_QUERY_KEYS.GAMES],
  transformResponse: (rawResponse: Record<string, any>, _meta, arg) =>
    arg.search
      ? normalizeGamesResponseForSearch(rawResponse)
      : normalizeGamesResponse(rawResponse),
  async onCacheEntryAdded(
    args: any,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => merge(draft, newData));
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.GAMES]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      console.error('getGames', e);
    }
  }
});
export default getGames;
