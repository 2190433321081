import React from 'react';

import { Box, UI_SIZES } from '@monorepo/ui-kit';

import PopularCompetitionsList from '../../../../common/PopularCompetitionsList';
import { POPULAR_ALIAS } from '../../../../constants';

type TProps = {
  sport?: string;
};

const ExtraItems: React.FC<TProps> = ({ sport }) => (
  <Box pl={UI_SIZES.m} pr={UI_SIZES.m}>
    {sport === POPULAR_ALIAS && <PopularCompetitionsList />}
  </Box>
);

export default ExtraItems;
