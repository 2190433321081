import React, { useMemo } from 'react';

import { useGetFavoriteIdsData } from '@monorepo/cms';
import {
  FAVORITE_TYPE,
  LikesProvider,
  RemoveAllButton,
  useScrollToTop,
  useUpdateFavoritesIds
} from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import { Box, EmptyState, PaddingWrapper } from '@monorepo/ui-kit';

import PromotionCardVideoList from '../../common/PromotionCardVideoList';
import useGetSlots from '../../hooks/useGetSlots';
import { TPromotionsListConfig } from '../../types';

import styles from './index.module.css';

const PromotionFavorite: ComponentWithConfig<TPromotionsListConfig> = ({
  config
}) => {
  const { t } = useI18n(NAMESPACES.COMMON);

  const { withShare, withFavorite, withLike, withComment } = config;
  const favoritePromotion = useGetFavoriteIdsData(FAVORITE_TYPE.WALL);

  const { data: allPromotions, isFetching } = useGetSlots();

  const favoritePromotionList =
    allPromotions?.list?.filter(({ id }) => favoritePromotion?.includes(id)) ||
    [];

  const likeIds = useMemo(
    () => favoritePromotionList?.map(({ id }) => `${id}`) || [],
    [favoritePromotionList]
  );

  const { onRemoveAllFavIds } = useUpdateFavoritesIds();

  const handleClick = () => onRemoveAllFavIds(FAVORITE_TYPE.WALL);
  useScrollToTop();

  return (
    <LikesProvider list={likeIds as string[]}>
      <PaddingWrapper isOnlyMobile className={styles.wrapper}>
        <Box>
          {favoritePromotionList?.length ? (
            <>
              <RemoveAllButton onClick={handleClick} icon="bin_0" />
              <Box className={styles.grid}>
                <PromotionCardVideoList
                  list={favoritePromotionList}
                  withLike={withLike}
                  withComment={withComment}
                  withFavorite={withFavorite}
                  withShare={withShare}
                  listClassName={styles.list}
                />
              </Box>
            </>
          ) : (
            <EmptyState
              icon="empty_favorites-0"
              title={t('no.items')}
              subtitle={t('add.promotion')}
              iconClassName={styles.emptyIcon}
              titleClassName={styles.emptyTitle}
              subtitleClassName={styles.emptySubtitle}
              className={styles.noGames}
            />
          )}
        </Box>
      </PaddingWrapper>
    </LikesProvider>
  );
};

export default PromotionFavorite;
