import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDeviceDetector } from '@bc-betkit/device-detector';
import isEmpty from 'lodash/isEmpty';

import { Component, TActionsConfig } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { MARKET_TYPES, MARKETS_ORDER_MAP } from '../../constants';
import useCreateGameLink from '../../hooks/useCreateGameLink';
import { useLineGamesData } from '../../hooks/useGetLineGames';
import { useLineMarketsData } from '../../hooks/useGetLineMarkets';
import useLineParameters from '../../hooks/useLineParameters';
import {
  selectGameBySportIdRegionIdCompetitionIdGameId,
  selectGameOrderedMarkets
} from '../../store/api/selectors';
import LineEmptyMarketsList from '../LineMarketsList/LineEmptyMarketsList';
import ShowMoreButton from '../ShowMoreButton';

import GeneralInfoBlock from './components/GeneralInfoBlock';
import MarketEventTitles from './components/MarketEventTitles';

import styles from './index.module.css';

type Props = {
  gameId: number;
  competitionId: number;
  sportId: number;
  regionId: number;
  showMoreButton?: boolean;
} & TActionsConfig;

const LineRecommendedCard: Component<Props> = ({
  gameId,
  sportId,
  regionId,
  competitionId,
  className = '',
  showMoreButton = true,
  children
}) => {
  const { isDesktop } = useDeviceDetector();
  const { marketType = MARKET_TYPES.SINGLE } = useLineParameters();
  const displayKeys = useMemo(
    () => MARKETS_ORDER_MAP[marketType],
    [marketType]
  );
  const { data: game } = useLineGamesData((result) =>
    selectGameBySportIdRegionIdCompetitionIdGameId(
      result,
      sportId,
      regionId,
      competitionId,
      gameId,
      [
        'id',
        'is_live',
        'sport_alias',
        'region_alias',
        'markets_count',
        'is_blocked'
      ]
    )
  );

  const { data: markets = [], isLoading: isLoadingMarkets } =
    useLineMarketsData((result) =>
      selectGameOrderedMarkets(result, gameId, displayKeys)
    );

  const gameLink = useCreateGameLink(competitionId, game);

  if (isEmpty(game)) {
    return null;
  }

  const { marketsCount, isBlocked, sportAlias } = game;
  const isEmptyChoosenMarkets = !markets.length;
  const isShowButtonWithOutMarkets = !marketsCount && showMoreButton;
  const isShowButtonWithMarkets =
    marketsCount && showMoreButton && isEmptyChoosenMarkets;

  return (
    <FlexBox
      align={FLEX_ALIGN.stretch}
      direction={FLEX_DIRECTION.column}
      justify={FLEX_JUSTIFY.between}
      className={`${styles.wrap} ${className}`}
    >
      <Link to={gameLink} className={styles.info}>
        <GeneralInfoBlock
          gameLink={gameLink}
          sportId={sportId}
          regionId={regionId}
          competitionId={competitionId}
          gameId={gameId}
        />
        <MarketEventTitles
          gameId={gameId}
          sportId={sportId}
          regionId={regionId}
          competitionId={competitionId}
          className={styles.teams}
        />
      </Link>
      {Boolean(marketsCount) && (
        <Box className={styles.markets} data-custom="recommended-markets">
          {isBlocked ? (
            <LineEmptyMarketsList
              sportAlias={sportAlias}
              isSuspended={Boolean(isBlocked)}
              listClassName={styles.emptyMarkets}
              className={styles.emptyMarkets}
            />
          ) : (
            children
          )}
        </Box>
      )}

      {(isShowButtonWithOutMarkets || isShowButtonWithMarkets) &&
        !isLoadingMarkets && (
          <ShowMoreButton
            link={gameLink}
            marketsCount={marketsCount}
            className={`${styles.showMore} ${
              isDesktop || !marketsCount ? styles.visible : ''
            }`}
          />
        )}
    </FlexBox>
  );
};

export default LineRecommendedCard;
