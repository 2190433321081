import { memoize } from 'proxy-memoize';

import { useSiteSettings } from '@monorepo/site-settings';

import { useGetSlotsQuery } from '../../store/casinoWallApiStore';

const useGetSlots = (options = {}) => {
  const { siteId } = useSiteSettings();
  return useGetSlotsQuery(
    {
      tags: options.tags,
      providers: options.providers,
      limit: 16,
      offset: options.offset,
      filter: options.filter,
      order_by: options.order_by,
      sort: options.sort,
      siteId
    },
    {
      ...options,
      selectFromResult: memoize(({ data, isFetching }) => ({
        isFetching,
        data: data || []
      }))
    }
  );
};

export default useGetSlots;
