import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';

import {
  TAuthLoginRequest,
  TAuthLoginResponse,
  TAuthLogoutRequest
} from '../types';

import requestAuthLogin from './api/requestAuthLogin';
import requestLogout from './api/requestLogout';

const staggeredQuery = retry(
  fetchBaseQuery({
    baseUrl: `/api/v1/auth`
  }),
  { maxRetries: 3 }
);

export const authLoginApi = createApi({
  reducerPath: 'authLoginApi',
  baseQuery: staggeredQuery,
  endpoints: (builder) => ({
    requestAuthLogin: builder.mutation<TAuthLoginResponse, TAuthLoginRequest>(
      requestAuthLogin()
    ),
    requestLogout: builder.mutation<unknown, TAuthLogoutRequest>(
      requestLogout()
    )
  })
});

export const { useRequestAuthLoginMutation, useRequestLogoutMutation } =
  authLoginApi;
