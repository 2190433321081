import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const Promo = lazy(() => import('./Promo'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Promo {...props} />
  </Suspense>
);
