import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const DefaultTooltip = lazy(() => import('./DefaultTooltip'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <DefaultTooltip {...props} />
  </Suspense>
);
