import { TGameStatsRequest } from '../../../types';
import { normalizeGameStatsResponse } from '../adapters/gameStatsAdapter';

const statsApiId = import.meta.env.VITE_APP_STATS_API_ID;
const key = import.meta.env.VITE_APP_STATS_API_KEY;

const getGameStats = () => ({
  query: ({ gameId, language }: TGameStatsRequest) =>
    `${language}/${key}/${statsApiId}/Entity/GetGeneralStatsInfo?matchId=${gameId}`,
  transformResponse: normalizeGameStatsResponse
});
export default getGameStats;
