import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const Bonuses = lazy(() => import('./Bonuses'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Bonuses config={config} />
  </Suspense>
);
