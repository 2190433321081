import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';

import { getSocketSWARM } from '@monorepo/websocket';

import { MARKET_TYPES, SPORTS_QUERY_KEYS } from '../../../constants';
import { TGetMarketsRequest } from '../../../types';
import { calcTimeFilter, createIdsQuery } from '../../utils';
import { normalizeMarketsResponse } from '../adapters/lineMarketsAdapter';

const SINGLE_MARKET = {
  display_key: 'WINNER',
  display_sub_key: 'MATCH'
};

const THREE_MARKETS = {
  '@or': [
    {
      display_key: createIdsQuery(['TOTALS', 'HANDICAP']),
      display_sub_key: 'MATCH',
      main_order: 1
    },
    SINGLE_MARKET
  ]
};

const getMarkets = (api) => ({
  query: ({
    gameType,
    competitionId,
    marketType = MARKET_TYPES.SINGLE,
    time,
    isPopular,
    gameIds
  }: TGetMarketsRequest) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          game: ['id'],
          market: [
            'type',
            'name',
            'base',
            'id',
            'express_id',
            'order',
            'display_key'
          ],
          event: ['id', 'price', 'type_1', 'name', 'base', 'order']
        },
        where: {
          game: {
            ...((gameType || isNumber(gameType)) && {
              type: gameType
            }),
            ...calcTimeFilter(time),
            ...(isPopular && { promoted: isPopular }),
            ...(gameIds?.length && { id: createIdsQuery(gameIds) })
          },
          market:
            marketType === MARKET_TYPES.SINGLE ? SINGLE_MARKET : THREE_MARKETS,
          ...(competitionId && {
            competition: {
              id: Number(competitionId)
            }
          })
        },
        subscribe: true
      }
    }
  }),
  keepUnusedDataFor: 5,
  providesTags: [SPORTS_QUERY_KEYS.MARKETS],
  transformResponse: normalizeMarketsResponse,
  async onCacheEntryAdded(
    args: any,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => merge(draft, newData));
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.MARKETS]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      console.error(e);
    }
  }
});

export default getMarkets;
