import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import concat from 'lodash/concat';

import { useGetUser } from '@monorepo/auth';
import { getLocalStorageValue, setLocalStorageValue } from '@monorepo/helpers';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { ModalWrapper, TModalHandler } from '@monorepo/ui-kit';

import CMSPopup from '../../common/CMSPopup';

import styles from './index.module.css';

type TModalNotification = {
  classOverlayModal?: string;
  classContentModal?: string;
  popup: Record<string, any>;
};

const Popup: Component<TModalNotification> = ({
  popup,
  classOverlayModal = '',
  classContentModal = ''
}) => {
  const ref = useRef<TModalHandler>(null);
  const { generalRoutes } = useSiteSettings();
  const stylesOverlay = `${styles.overlay} ${classOverlayModal}`;
  const { id, title } = popup;

  const { pathname } = useLocation();
  const { data: profile } = useGetUser();
  const { id: userId } = profile || {};

  useEffect(() => {
    const blockedPopups = getLocalStorageValue(`blockedPopups_${userId}`, []);
    if (
      pathname === generalRoutes.home &&
      userId &&
      !blockedPopups.includes(id)
    ) {
      ref.current?.setIsShow(true);
    }
  }, [pathname, userId, id]);

  const handleClose = () => {
    if (ref.current) {
      ref.current.setIsShow(false);
      const blockedPopups = getLocalStorageValue(`blockedPopups_${userId}`, []);
      const idsList = concat(blockedPopups, id);
      setLocalStorageValue(`blockedPopups_${userId}`, idsList);
    }
  };

  return (
    <ModalWrapper
      ref={ref}
      classOverlayModal={stylesOverlay}
      classContentModal={classContentModal}
      handleClose={handleClose}
      iconClass={styles.iconPopup}
      header={title}
      alwaysCenter
    >
      <CMSPopup
        popup={popup}
        handleClose={handleClose}
        isWithCheckbox={false}
      />
    </ModalWrapper>
  );
};
export default Popup;
