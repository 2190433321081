import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const BetsHistory = lazy(() => import('./BetsHistory'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BetsHistory {...props} />
  </Suspense>
);
