import React, { forwardRef } from 'react';

import { InputProps } from '../../types';
import Input from '../forms/Input';

const SearchInput = forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', ...props }, ref) => (
    // @ts-ignore
    <Input className={className} iconName="search_1" ref={ref} {...props} />
  )
);

export default SearchInput;
