import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const Limits = lazy(() => import('./Limits'));

export default () => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Limits />
  </Suspense>
);
