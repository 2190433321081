import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const messengerApiV2 = createApi({
  reducerPath: 'messengerApiV2',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v2'
  }),
  tagTypes: [],
  endpoints: () => ({})
});

export default messengerApiV2;
