import React from 'react';

import { RegisterModal } from '@monorepo/auth';
import { useSiteSettings } from '@monorepo/site-settings/src';

import Registration from './component/Registration';

type TRegistrationProps = {
  defaultCountry: string;
  defaultCurrency: string;
  header: string;
  snippets: Record<string, number>;
  className?: string;
};

const RegistrationModal = () => {
  const { signupConfig } = useSiteSettings();
  const { banner, ...other } = signupConfig;

  return (
    <RegisterModal banner={banner}>
      <Registration config={other as TRegistrationProps} />
    </RegisterModal>
  );
};

export default RegistrationModal;
