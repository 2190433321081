import { UGC_QUERY_TAG } from '../../../constants';

const updateBlog = () => ({
  query: ({
    title,
    description,
    body,
    linkToVideo,
    linkToPicture,
    linkToEvent,
    startTime,
    endTime,
    tags,
    type,
    id,
    jwt
  }: any) => ({
    url: `/post/${id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    },
    body: {
      type,
      title,
      description,
      body,
      link_to_video: linkToVideo,
      link_to_picture: linkToPicture,
      link_to_event: linkToEvent,
      start_time: startTime,
      end_time: endTime,
      tags: [tags]
    }
  }),
  invalidatesTags: (data, error) =>
    error ? [] : [{ type: UGC_QUERY_TAG.BLOG_INFO, id: data.id }]
});

export default updateBlog;
