import {
  TGameIdRequest,
  TGetMatchInfo,
  TGetMatchScoresResponse
} from '../../../types';
import getMatchScoreAdapter from '../adapters/getMatchScoreAdapter';

const getMatchScores = () => ({
  query: ({ gameId }: TGameIdRequest) => ({
    body: {
      command: 'get_match_scores',
      params: {
        match_id_list: Array.isArray(gameId) ? gameId : [gameId]
      }
    }
  }),
  transformResponse(response: TGetMatchScoresResponse): TGetMatchInfo | {} {
    return !response ? {} : getMatchScoreAdapter(response);
  }
});
export default getMatchScores;
