import { useGetJWTQuery } from '../../store/user';
import useIsAuthenticated from '../useIsAuthenticated';

const useGetJWT = () => {
  const { isAuthenticated } = useIsAuthenticated();
  const { data } = useGetJWTQuery(undefined, {
    skip: !isAuthenticated
  });
  return { data };
};

export default useGetJWT;
