import { memoize } from 'proxy-memoize';

import { TQueryResult } from '@monorepo/type';

type TReturnedData<ReturnType> = {
  data: ReturnType;
  isLoading?: boolean;
  isFetching?: boolean;
  isSuccess?: boolean;
  isUninitialized?: boolean;
};

const useGetData = <
  ReturnType = any,
  QueryResultType = any,
  RestReturnedData extends Record<string, any> = {},
  QueryArgs extends Record<string, any> = {}
>(
  queryFunction: (args: QueryArgs, options?: Record<string, any>) => any,
  dataSelector?: (result: TQueryResult<QueryResultType>) => ReturnType,
  queryArgs: QueryArgs = {} as QueryArgs,
  queryOptions: Record<string, any> = {},
  restSelectors?: (result: TQueryResult<QueryResultType>) => Record<string, any>
): TReturnedData<ReturnType> & RestReturnedData =>
  queryFunction(queryArgs, {
    ...queryOptions,
    selectFromResult: memoize((result: TQueryResult<QueryResultType>) => ({
      isLoading: result?.isLoading,
      isFetching: result?.isFetching,
      isSuccess: result?.isSuccess,
      isUninitialized: result?.isUninitialized,
      data: dataSelector ? dataSelector(result) : result?.data,
      ...(restSelectors ? restSelectors(result) : {})
    }))
  });

export default useGetData;
