import React from 'react';

import { Icon } from '@monorepo/icons';
import { CustomFC } from '@monorepo/type';

import { FLEX_ALIGN, FLEX_DIRECTION, FLEX_JUSTIFY } from '../../constants';
import { EmptyStateProps } from '../../types';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

const EmptyState: CustomFC<EmptyStateProps> = ({
  icon = '',
  title,
  subtitle,
  defaultIcon = '',
  className = '',
  iconClassName = '',
  titleClassName = '',
  subtitleClassName = '',
  children
}) => (
  <FlexBox
    align={FLEX_ALIGN.center}
    justify={FLEX_JUSTIFY.center}
    direction={FLEX_DIRECTION.column}
    className={`${styles.empty} ${className}`}
  >
    {Boolean(icon) && (
      <Icon
        name={icon}
        className={`${styles.icon} ${iconClassName}`}
        categoryIcon={defaultIcon}
      />
    )}
    {Boolean(title) && (
      <div className={`${titleClassName} ${styles.title}`}>{title}</div>
    )}
    {Boolean(subtitle) && (
      <div className={` ${subtitleClassName} ${styles.subtitle}`}>
        {subtitle}
      </div>
    )}

    {children}
  </FlexBox>
);

export default EmptyState;
