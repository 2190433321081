import { useEffect, useState } from 'react';

const useGetPayments = (): [] => {
  const [payments, setData] = useState([]);

  useEffect(() => {
    const url = '/payments/payments.json';

    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const paymentsJson = await response.json();

        setData(paymentsJson);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return payments;
};

export default useGetPayments;
