import React from 'react';

import { Component } from '@monorepo/type';

import { useCompetitionsWithSportsData } from '../../hooks/useGetCompetitionsWithSports';
import { selectPopularCompetitions } from '../../store/api/selectors';
import MenuSkeleton from '../MenuSkeleton';

import Competition from './components/Competition';

import styles from './index.module.css';

type Props = {};

const PopularCompetitionsList: Component<Props> = () => {
  const { data: competitions, isLoading } = useCompetitionsWithSportsData(
    (result) => selectPopularCompetitions(result),
    { favorite: true }
  );

  if (isLoading) {
    return <MenuSkeleton length={10} />;
  }

  if (!competitions) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {competitions.map(({ id, sportId, regionId }: Record<string, number>) => (
        <Competition
          key={id}
          competitionId={id}
          sportId={sportId}
          regionId={regionId}
        />
      ))}
    </div>
  );
};

export default PopularCompetitionsList;
