import React from 'react';
import { Link } from 'react-router-dom';

import { AVATAR_SIZE } from '@monorepo/files';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  FIELD_TONE,
  NotificationLayout,
  NotificationText,
  NotificationTitle,
  TEXT_SIZE
} from '@monorepo/ui-kit';
import { getIdFromJID } from '@monorepo/xmpp';

import useChatUser from '../../hooks/useChatUser';
import useGetRoomDiscoInfo from '../../hooks/useGetRoomDiscoInfo';
import { TNotificationProps } from '../../types';
import FriendAvatar from '../FriendAvatar';

import styles from './index.module.css';

type Props = TNotificationProps & { toneType: FIELD_TONE; textSize: TEXT_SIZE };

const DeclineCommunityInviteNotification: Component<Props> = ({
  roomJid,
  userJid,
  className = '',
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small
}) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { community, profile } = generalRoutes;
  const userId = getIdFromJID(userJid);
  const roomId = getIdFromJID(roomJid);
  const { data } = useGetRoomDiscoInfo(roomId);
  const { name = '' } = data || {};
  const { displayName } = useChatUser(userId);

  const communityLink = `${community}/${roomId}`;
  const friendLink = `${profile}/${userId}`;

  return (
    <NotificationLayout
      className={`${styles.wrap} ${className}`}
      image={
        <Link to={friendLink}>
          <FriendAvatar
            id={userId}
            className={styles.avatar}
            avatarClass={styles.avatarImg}
            isWithStatus={false}
            size={AVATAR_SIZE.S}
          />
        </Link>
      }
    >
      <Link to={communityLink}>
        <NotificationTitle textSize={textSize} toneType={toneType}>
          {name}
        </NotificationTitle>
      </Link>
      <NotificationText
        textSize={textSize}
        toneType={toneType}
        className={styles.text}
      >
        {t('notification.room_decline.text', {
          nickname: displayName,
          roomName: name
        })}
      </NotificationText>
    </NotificationLayout>
  );
};

export default DeclineCommunityInviteNotification;
