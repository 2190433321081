import { googleAnalytics } from '@monorepo/helpers';

import { TAuthLogoutRequest } from '../../../types';
import { actions } from '../../index';

const requestLogout = () => ({
  query: ({ jwt }: TAuthLogoutRequest) => ({
    method: 'DELETE',
    url: '/player',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    }
  }),
  async onQueryStarted(arg: TAuthLogoutRequest, { dispatch, queryFulfilled }) {
    const { setJwt, setJwtAuthToken, setMessengerAuthToken } = actions;
    try {
      await queryFulfilled;
      dispatch(setJwt(null));
      dispatch(setJwtAuthToken(null));
      dispatch(setMessengerAuthToken(null));
    } catch (e) {
      console.error(e);
      // ga  js-error
      const ga = googleAnalytics();
      ga.dispatch({
        event: ga.event.jsError,
        eventParam: {
          event_category: 'js'
        },
        event_options: {
          message: (e as Error)?.message,
          data: e
        }
      });
    }
  }
});

export default requestLogout;
