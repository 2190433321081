import { TUpdatePlayerInformation } from '../../../../types';
import { customApi } from '../../index';

const updateFavorites = () => ({
  query: (args: TUpdatePlayerInformation) => ({
    url: '/player',
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${args.jwt}`
    },
    body: {
      favorites: args.favorites
    }
  }),
  async onQueryStarted(
    data: TUpdatePlayerInformation,
    { dispatch, queryFulfilled }
  ) {
    const { favorites: bodyParams, ...args } = data;
    const patchResult = dispatch(
      customApi.util.updateQueryData('getPlayerInfo', args, (draft) =>
        Object.assign(draft, { favorites: bodyParams })
      )
    );
    try {
      await queryFulfilled;
    } catch {
      patchResult.undo();
    }
  }
});

export default updateFavorites;
