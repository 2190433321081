import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TGamesResponse,
  TGetGamesRequest,
  TNormalizedFetchGamesResponse
} from '../../../types';
import normalizeGame from '../../../utils/normalizeGame';
import getGamesRequest from '../query';

const cmsHost = import.meta.env.VITE_APP_CMS_HOST;
const gamesApi = createApi({
  reducerPath: 'games',
  baseQuery: fetchBaseQuery({
    baseUrl: `${cmsHost}/casino/`,
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
      return headers;
    },
    validateStatus: (response, result) =>
      response.status === 200 && result?.status === 'ok'
  }),
  endpoints: (build) => ({
    fetchGames: build.query({
      keepUnusedDataFor: 600, // Keep unused for longer,
      query: (params) => getGamesRequest(params),
      transformResponse: (
        response: TGamesResponse,
        meta: any,
        { currentPage = 1 }: TGetGamesRequest
      ): TNormalizedFetchGamesResponse => {
        const {
          games = [],
          total_count: totalCount = 0,
          items = []
        } = response;
        const responseGames = games.length
          ? games.map(normalizeGame)
          : items.map(normalizeGame);

        return {
          games: responseGames,
          totalCount: Number(totalCount),
          currentPage
        };
      },
      async onQueryStarted(args, { queryFulfilled, dispatch }) {
        const { data = {} } = await queryFulfilled;
        const { games, items } = data;
        const { currentPage = 1 } = args;
        if (games || items) {
          // Add Games On Current Request To Page 1
          dispatch(
            gamesApi.util.updateQueryData(
              'fetchGames',
              { ...args, currentPage: 1 },
              (draft) => {
                if (currentPage > 1) {
                  draft.games.push(...(games || items));
                  draft.currentPage = currentPage;
                }
              }
            )
          );

          if (currentPage > 1) {
            // Remove Cached Data From State Since We Already Added It To Page 1
            dispatch(
              gamesApi.util.updateQueryData('fetchGames', args, (draft) => {
                draft.games = [];
              })
            );
          }
        }
      }
    })
  })
});

export const { useLazyFetchGamesQuery } = gamesApi;

export default gamesApi;
