import React, { useCallback, useRef } from 'react';

import { classNameProp, CustomFC } from '@monorepo/type';

import LazyImage from '../LazyLoadImage';

import styles from './index.module.css';

type Props = {
  src: string;
  alt: string;
  imgClassName?: string;
  visibleByDefault?: boolean;
} & classNameProp;

const PreloadImage: CustomFC<Props> = ({
  children,
  src,
  alt,
  className = '',
  imgClassName = '',
  visibleByDefault = false
}) => {
  const mainRef = useRef<HTMLDivElement>(null);

  const handleOnLoad = useCallback(() => {
    mainRef.current?.classList.add(styles.loaded);
  }, []);

  const handleOnError = useCallback(() => {
    mainRef.current?.classList.remove(styles.loaded);
  }, []);

  const rootStyles = `${styles.wrapper} ${
    src ? styles.loaded : ''
  } ${className}`;

  return (
    <div className={rootStyles} ref={mainRef}>
      {src && (
        <LazyImage
          className={`${styles.img} ${imgClassName}`}
          src={src}
          alt={alt}
          onLoad={handleOnLoad}
          onError={handleOnError}
          visibleByDefault={visibleByDefault}
        />
      )}
      <div className={styles.placeholder}>{children}</div>
    </div>
  );
};

export default PreloadImage;
