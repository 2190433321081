import React from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { useScrollToTop } from '@monorepo/common';
import { CustomFC } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Logo
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  header?: string;
  subHeader?: string;
  logoSrc?: string;
  headerClassName?: string;
  className?: string;
};

const AuthWrapper: CustomFC<Props> = ({
  children,
  headerClassName = '',
  header = '',
  subHeader = '',
  logoSrc = '',
  className = ''
}) => {
  const { isDesktop } = useDeviceDetector();
  const rootClassName = `${isDesktop ? styles.desktop : ''} ${className}`;

  useScrollToTop();

  return (
    <FlexBox justify={FLEX_JUSTIFY.center} className={rootClassName}>
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
        <FlexBox
          direction={FLEX_DIRECTION.column}
          align={FLEX_ALIGN.center}
          className={styles.content}
        >
          {Boolean(logoSrc) && <Logo src={logoSrc} className={styles.logo} />}
          {Boolean(header) && (
            <Box className={`${styles.header} ${headerClassName}`}>
              {header}
            </Box>
          )}
          {Boolean(subHeader) && (
            <Box className={styles.subheader}>{subHeader}</Box>
          )}
          {children}
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default AuthWrapper;
