import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { DeviceDetectorProvider } from '@bc-betkit/device-detector';

import { NotificationProvider, Toasts } from '@monorepo/notification';
import { SiteSettingsProvider } from '@monorepo/site-settings';
import { ThemeProvider } from '@monorepo/theme';
import { CustomFC } from '@monorepo/type';
import { DefaultTooltip, ModalProvider } from '@monorepo/ui-kit';
import { XMPPProvider } from '@monorepo/xmpp';

type Props = {
  siteSettings: any;
};

const App: CustomFC<Props> = ({ siteSettings, children }) => (
  <DeviceDetectorProvider>
    <SiteSettingsProvider siteSettings={siteSettings}>
      <ThemeProvider>
        <XMPPProvider>
          <ModalProvider>
            <NotificationProvider>
              <BrowserRouter>
                <Toasts />
                {children}
                <DefaultTooltip />
              </BrowserRouter>
            </NotificationProvider>
          </ModalProvider>
        </XMPPProvider>
      </ThemeProvider>
    </SiteSettingsProvider>
  </DeviceDetectorProvider>
);

export default App;
