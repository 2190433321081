import React, { memo } from 'react';
import {
  useDeviceDetector,
  usePageTypeToBody
} from '@bc-betkit/device-detector';
import isEqual from 'lodash/isEqual';

import { useSelectChatOpen } from '@monorepo/auth';
import { TPage } from '@monorepo/page';
import { useTheme } from '@monorepo/theme';
import { Component as TComponent } from '@monorepo/type';
import { FLEX_DIRECTION, FlexBox } from '@monorepo/ui-kit';

import Footer from '../Footer';
import MetaTags from '../MetaTags';

import BottomNavigation from './components/BottomNavigation';
import HeaderNavigation from './components/HeaderNavigation';
import layoutsMap from './constants';

import styles from './index.module.css';

type TComponentProps = {
  type: string;
};

const LayoutComponent: TComponent<TComponentProps> = ({
  type,
  className = ''
}) => {
  if (layoutsMap[type]) {
    const Component: TComponent<any> = layoutsMap[type];
    return <Component className={className} />;
  }
  return null;
};

type TLayoutProps = {
  page: TPage;
};

const Layout: TComponent<TLayoutProps> = ({ page }) => {
  const { pageConfig = {}, layout } = page;
  const {
    isWithHeader = true,
    isWithFooter = false,
    isWithBottomNav = false,
    title = '',
    description = '',
    background,
    bottomNavigationComponent = '',
    headerNavigationComponent = ''
  } = pageConfig;
  const { type } = layout;
  const { isDesktop } = useDeviceDetector();
  const theme = useTheme();
  usePageTypeToBody(theme);

  const isOpen = useSelectChatOpen();

  const bodyClasses = `${styles.body}
    ${isWithBottomNav && !isDesktop ? styles.isWithBottomNav : ''}
    ${isOpen ? styles.isChatOpen : ''}`;

  const layoutClasses = `${styles.layout}
    ${isWithHeader ? styles.withHeader : ''}`;

  return (
    <>
      <MetaTags title={title} description={description} />
      <FlexBox
        direction={FLEX_DIRECTION.column}
        className={bodyClasses}
        style={background ? { background } : undefined}
      >
        {isWithHeader && (
          <HeaderNavigation componentId={headerNavigationComponent} />
        )}
        <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
          <LayoutComponent type={type} className={layoutClasses} />
        </FlexBox>
        {isWithFooter && <Footer />}
        {isWithBottomNav && !isDesktop && !isOpen && (
          <BottomNavigation componentId={bottomNavigationComponent} />
        )}
      </FlexBox>
    </>
  );
};

export default memo(Layout, isEqual);
