import { ReactComponent as BankTransfer } from '../../icons/bank_transfer.svg';
import { ReactComponent as BBBetslip } from '../../icons/bb_betslip.svg';
import { ReactComponent as BTBetslip } from '../../icons/bt_betslip.svg';
import { ReactComponent as ChatArrow } from '../../icons/chat_arrow.svg';
import { ReactComponent as AllCurr } from '../../icons/currencies/all-0.svg';
import { ReactComponent as BankTransfer1 } from '../../icons/currencies/bank-transfer-0.svg';
import { ReactComponent as Br } from '../../icons/currencies/br-0.svg';
import { ReactComponent as Crypto } from '../../icons/currencies/crypto-0.svg';
import { ReactComponent as Eur } from '../../icons/currencies/EUR.svg';
import { ReactComponent as Fr } from '../../icons/currencies/fr-0.svg';
import { ReactComponent as Ftn } from '../../icons/currencies/FTN.svg';
import { ReactComponent as Mobile } from '../../icons/currencies/mobile-0.svg';
import { ReactComponent as T } from '../../icons/currencies/t-0.svg';
import { ReactComponent as Usd } from '../../icons/currencies/USD.svg';
import { ReactComponent as FlagCs } from '../../icons/flag_cs.svg';
import { ReactComponent as FlagDe } from '../../icons/flag_de.svg';
import { ReactComponent as FlagEn } from '../../icons/flag_en.svg';
import { ReactComponent as FlagEs } from '../../icons/flag_es.svg';
import { ReactComponent as FlagPb } from '../../icons/flag_pb.svg';
import { ReactComponent as FlagRu } from '../../icons/flag_ru.svg';
import { ReactComponent as FlagTr } from '../../icons/flag_tr.svg';
import { ReactComponent as Frame9157 } from '../../icons/Frame_9157.svg';
import { ReactComponent as FreeBet0 } from '../../icons/free_bet-0.svg';
import { ReactComponent as FreeBet1 } from '../../icons/free_bet-1.svg';
import { ReactComponent as Layer1 } from '../../icons/layer_1.svg';
import { ReactComponent as Layer2 } from '../../icons/layer_2.svg';
import { ReactComponent as LayewWinnersLeft } from '../../icons/layer_winners_left.svg';
import { ReactComponent as LayewWinnersRight } from '../../icons/layer_winners_right.svg';
import { ReactComponent as Logo } from '../../icons/logo.svg';
import { ReactComponent as LogoP1 } from '../../icons/logo_p1.svg';
import { ReactComponent as LogoFullSize } from '../../icons/logo-full-size.svg';
import { ReactComponent as LogoSmallSize } from '../../icons/logo-small-size.svg';
import { ReactComponent as LogoSoftconstruct } from '../../icons/logo-softconstruct.svg';
import { ReactComponent as LogoVbet } from '../../icons/logo-vbet.svg';
import { ReactComponent as MainBanner } from '../../icons/main_banner.svg';
import { ReactComponent as Moderator } from '../../icons/moderator.svg';
import { ReactComponent as Plus } from '../../icons/plus.svg';
import { ReactComponent as Promo } from '../../icons/promo.svg';
import { ReactComponent as Rating } from '../../icons/rating.svg';
import { ReactComponent as Abkhazia } from '../../icons/regionicons/abkhazia.svg';
import { ReactComponent as Afghanistan } from '../../icons/regionicons/afghanistan.svg';
import { ReactComponent as Alandislands } from '../../icons/regionicons/aland_islands.svg';
import { ReactComponent as Albania } from '../../icons/regionicons/albania.svg';
import { ReactComponent as Algeria } from '../../icons/regionicons/algeria.svg';
import { ReactComponent as Americansamoa } from '../../icons/regionicons/american_samoa.svg';
import { ReactComponent as Andorra } from '../../icons/regionicons/andorra.svg';
import { ReactComponent as Angola } from '../../icons/regionicons/angola.svg';
import { ReactComponent as Anguilla } from '../../icons/regionicons/anguilla.svg';
import { ReactComponent as Antiguaandbarbuda } from '../../icons/regionicons/antigua_and_barbuda.svg';
import { ReactComponent as Argentina } from '../../icons/regionicons/argentina.svg';
import { ReactComponent as Armenia } from '../../icons/regionicons/armenia.svg';
import { ReactComponent as Aruba } from '../../icons/regionicons/aruba.svg';
import { ReactComponent as Australia } from '../../icons/regionicons/australia.svg';
import { ReactComponent as Austria } from '../../icons/regionicons/austria.svg';
import { ReactComponent as Azerbaijan } from '../../icons/regionicons/azerbaijan.svg';
import { ReactComponent as Azoresislands } from '../../icons/regionicons/azores_islands.svg';
import { ReactComponent as Bahamas } from '../../icons/regionicons/bahamas.svg';
import { ReactComponent as Bahrain } from '../../icons/regionicons/bahrain.svg';
import { ReactComponent as Balearicislands } from '../../icons/regionicons/balearic_islands.svg';
import { ReactComponent as Bangladesh } from '../../icons/regionicons/bangladesh.svg';
import { ReactComponent as Barbados } from '../../icons/regionicons/barbados.svg';
import { ReactComponent as Basquecountry } from '../../icons/regionicons/basque_country.svg';
import { ReactComponent as Belarus } from '../../icons/regionicons/belarus.svg';
import { ReactComponent as Belgium } from '../../icons/regionicons/belgium.svg';
import { ReactComponent as Belize } from '../../icons/regionicons/belize.svg';
import { ReactComponent as Benin } from '../../icons/regionicons/benin.svg';
import { ReactComponent as Bermuda } from '../../icons/regionicons/bermuda.svg';
import { ReactComponent as Bhutan } from '../../icons/regionicons/bhutan.svg';
import { ReactComponent as Bhutan2 } from '../../icons/regionicons/bhutan_2.svg';
import { ReactComponent as Bolivia } from '../../icons/regionicons/bolivia.svg';
import { ReactComponent as Bonaire } from '../../icons/regionicons/bonaire.svg';
import { ReactComponent as Bosniaandherzegovina } from '../../icons/regionicons/bosnia_and_herzegovina.svg';
import { ReactComponent as Botswana } from '../../icons/regionicons/botswana.svg';
import { ReactComponent as Brazil } from '../../icons/regionicons/brazil.svg';
import { ReactComponent as Britishcolumbia } from '../../icons/regionicons/british_columbia.svg';
import { ReactComponent as Britishindianoceanterritory } from '../../icons/regionicons/british_indian_ocean_territory.svg';
import { ReactComponent as Britishvirginislands } from '../../icons/regionicons/british_virgin_islands.svg';
import { ReactComponent as Bruneidarussalam } from '../../icons/regionicons/brunei_darussalam.svg';
import { ReactComponent as Bulgaria } from '../../icons/regionicons/bulgaria.svg';
import { ReactComponent as Burkinafaso } from '../../icons/regionicons/burkina_faso.svg';
import { ReactComponent as Burundi } from '../../icons/regionicons/burundi.svg';
import { ReactComponent as Cambodia } from '../../icons/regionicons/cambodia.svg';
import { ReactComponent as Cameroon } from '../../icons/regionicons/cameroon.svg';
import { ReactComponent as Canada } from '../../icons/regionicons/canada.svg';
import { ReactComponent as Canaryislands } from '../../icons/regionicons/canary_islands.svg';
import { ReactComponent as Capeverde } from '../../icons/regionicons/cape_verde.svg';
import { ReactComponent as Caymanislands } from '../../icons/regionicons/cayman_islands.svg';
import { ReactComponent as Centralafricanrepublic } from '../../icons/regionicons/central_african_republic.svg';
import { ReactComponent as Ceuta } from '../../icons/regionicons/ceuta.svg';
import { ReactComponent as Chad } from '../../icons/regionicons/chad.svg';
import { ReactComponent as Chile } from '../../icons/regionicons/chile.svg';
import { ReactComponent as China } from '../../icons/regionicons/china.svg';
import { ReactComponent as Christmasisland } from '../../icons/regionicons/christmas_island.svg';
import { ReactComponent as Cocosisland } from '../../icons/regionicons/cocos_island.svg';
import { ReactComponent as Colombia } from '../../icons/regionicons/colombia.svg';
import { ReactComponent as Comoros } from '../../icons/regionicons/comoros.svg';
import { ReactComponent as Cookislands } from '../../icons/regionicons/cook_islands.svg';
import { ReactComponent as Corsica } from '../../icons/regionicons/corsica.svg';
import { ReactComponent as Costarica } from '../../icons/regionicons/costa_rica.svg';
import { ReactComponent as Croatia } from '../../icons/regionicons/croatia.svg';
import { ReactComponent as Cuba } from '../../icons/regionicons/cuba.svg';
import { ReactComponent as Curacao } from '../../icons/regionicons/curacao.svg';
import { ReactComponent as Cyprus } from '../../icons/regionicons/cyprus.svg';
import { ReactComponent as Czechrepublic } from '../../icons/regionicons/czech_republic.svg';
import { ReactComponent as Democraticrepublicofcongo } from '../../icons/regionicons/democratic_republic_of_congo.svg';
import { ReactComponent as Denmark } from '../../icons/regionicons/denmark.svg';
import { ReactComponent as Djibouti } from '../../icons/regionicons/djibouti.svg';
import { ReactComponent as Dominica } from '../../icons/regionicons/dominica.svg';
import { ReactComponent as Dominicanrepublic } from '../../icons/regionicons/dominican_republic.svg';
import { ReactComponent as Easttimor } from '../../icons/regionicons/east_timor.svg';
import { ReactComponent as Ecuador } from '../../icons/regionicons/ecuador.svg';
import { ReactComponent as Egypt } from '../../icons/regionicons/egypt.svg';
import { ReactComponent as Elsalvador } from '../../icons/regionicons/el_salvador.svg';
import { ReactComponent as England } from '../../icons/regionicons/england.svg';
import { ReactComponent as Equatorialguinea } from '../../icons/regionicons/equatorial_guinea.svg';
import { ReactComponent as Eritrea } from '../../icons/regionicons/eritrea.svg';
import { ReactComponent as Estonia } from '../../icons/regionicons/estonia.svg';
import { ReactComponent as Ethiopia } from '../../icons/regionicons/ethiopia.svg';
import { ReactComponent as Europe } from '../../icons/regionicons/europe.svg';
import { ReactComponent as Europeanunion } from '../../icons/regionicons/european_union.svg';
import { ReactComponent as Falklandislands } from '../../icons/regionicons/falkland_islands.svg';
import { ReactComponent as Faroeislands } from '../../icons/regionicons/faroe_islands.svg';
import { ReactComponent as Fiji } from '../../icons/regionicons/fiji.svg';
import { ReactComponent as Finland } from '../../icons/regionicons/finland.svg';
import { ReactComponent as France } from '../../icons/regionicons/france.svg';
import { ReactComponent as Frenchpolynesia } from '../../icons/regionicons/french_polynesia.svg';
import { ReactComponent as Gabon } from '../../icons/regionicons/gabon.svg';
import { ReactComponent as Galapagosislands } from '../../icons/regionicons/galapagos_islands.svg';
import { ReactComponent as Gambia } from '../../icons/regionicons/gambia.svg';
import { ReactComponent as Georgia } from '../../icons/regionicons/georgia.svg';
import { ReactComponent as Germany } from '../../icons/regionicons/germany.svg';
import { ReactComponent as Ghana } from '../../icons/regionicons/ghana.svg';
import { ReactComponent as Gibraltar } from '../../icons/regionicons/gibraltar.svg';
import { ReactComponent as Greatbritain } from '../../icons/regionicons/great_britain.svg';
import { ReactComponent as Greece } from '../../icons/regionicons/greece.svg';
import { ReactComponent as Greenland } from '../../icons/regionicons/greenland.svg';
import { ReactComponent as Grenada } from '../../icons/regionicons/grenada.svg';
import { ReactComponent as Guam } from '../../icons/regionicons/guam.svg';
import { ReactComponent as Guatemala } from '../../icons/regionicons/guatemala.svg';
import { ReactComponent as Guernsey } from '../../icons/regionicons/guernsey.svg';
import { ReactComponent as Guinea } from '../../icons/regionicons/guinea.svg';
import { ReactComponent as Guineabissau } from '../../icons/regionicons/guinea_bissau.svg';
import { ReactComponent as Guyana } from '../../icons/regionicons/guyana.svg';
import { ReactComponent as Haiti } from '../../icons/regionicons/haiti.svg';
import { ReactComponent as Hawaii } from '../../icons/regionicons/hawaii.svg';
import { ReactComponent as Honduras } from '../../icons/regionicons/honduras.svg';
import { ReactComponent as Hongkong } from '../../icons/regionicons/hong_kong.svg';
import { ReactComponent as Hungary } from '../../icons/regionicons/hungary.svg';
import { ReactComponent as Iceland } from '../../icons/regionicons/iceland.svg';
import { ReactComponent as India } from '../../icons/regionicons/india.svg';
import { ReactComponent as Indonesia } from '../../icons/regionicons/indonesia.svg';
import { ReactComponent as Iran } from '../../icons/regionicons/iran.svg';
import { ReactComponent as Iraq } from '../../icons/regionicons/iraq.svg';
import { ReactComponent as Ireland } from '../../icons/regionicons/ireland.svg';
import { ReactComponent as Isleofman } from '../../icons/regionicons/isle_of_man.svg';
import { ReactComponent as Israel } from '../../icons/regionicons/israel.svg';
import { ReactComponent as Italy } from '../../icons/regionicons/italy.svg';
import { ReactComponent as Ivorycoast } from '../../icons/regionicons/ivory_coast.svg';
import { ReactComponent as Jamaica } from '../../icons/regionicons/jamaica.svg';
import { ReactComponent as Japan } from '../../icons/regionicons/japan.svg';
import { ReactComponent as Jersey } from '../../icons/regionicons/jersey.svg';
import { ReactComponent as Jordan } from '../../icons/regionicons/jordan.svg';
import { ReactComponent as Kazakhstan } from '../../icons/regionicons/kazakhstan.svg';
import { ReactComponent as Kenya } from '../../icons/regionicons/kenya.svg';
import { ReactComponent as Kiribati } from '../../icons/regionicons/kiribati.svg';
import { ReactComponent as Kosovo } from '../../icons/regionicons/kosovo.svg';
import { ReactComponent as Kuwait } from '../../icons/regionicons/kuwait.svg';
import { ReactComponent as Kyrgyzstan } from '../../icons/regionicons/kyrgyzstan.svg';
import { ReactComponent as Laos } from '../../icons/regionicons/laos.svg';
import { ReactComponent as Latvia } from '../../icons/regionicons/latvia.svg';
import { ReactComponent as Lebanon } from '../../icons/regionicons/lebanon.svg';
import { ReactComponent as Lesotho } from '../../icons/regionicons/lesotho.svg';
import { ReactComponent as Liberia } from '../../icons/regionicons/liberia.svg';
import { ReactComponent as Libya } from '../../icons/regionicons/libya.svg';
import { ReactComponent as Liechtenstein } from '../../icons/regionicons/liechtenstein.svg';
import { ReactComponent as Lithuania } from '../../icons/regionicons/lithuania.svg';
import { ReactComponent as Luxembourg } from '../../icons/regionicons/luxembourg.svg';
import { ReactComponent as Macao } from '../../icons/regionicons/macao.svg';
import { ReactComponent as Madagascar } from '../../icons/regionicons/madagascar.svg';
import { ReactComponent as Madeira } from '../../icons/regionicons/madeira.svg';
import { ReactComponent as Malawi } from '../../icons/regionicons/malawi.svg';
import { ReactComponent as Malaysia } from '../../icons/regionicons/malaysia.svg';
import { ReactComponent as Maldives } from '../../icons/regionicons/maldives.svg';
import { ReactComponent as Mali } from '../../icons/regionicons/mali.svg';
import { ReactComponent as Malta } from '../../icons/regionicons/malta.svg';
import { ReactComponent as Marshallisland } from '../../icons/regionicons/marshall_island.svg';
import { ReactComponent as Martinique } from '../../icons/regionicons/martinique.svg';
import { ReactComponent as Mauritania } from '../../icons/regionicons/mauritania.svg';
import { ReactComponent as Mauritius } from '../../icons/regionicons/mauritius.svg';
import { ReactComponent as Melilla } from '../../icons/regionicons/melilla.svg';
import { ReactComponent as Mexico } from '../../icons/regionicons/mexico.svg';
import { ReactComponent as Micronesia } from '../../icons/regionicons/micronesia.svg';
import { ReactComponent as Moldova } from '../../icons/regionicons/moldova.svg';
import { ReactComponent as Monaco } from '../../icons/regionicons/monaco.svg';
import { ReactComponent as Mongolia } from '../../icons/regionicons/mongolia.svg';
import { ReactComponent as Montenegro } from '../../icons/regionicons/montenegro.svg';
import { ReactComponent as Montserrat } from '../../icons/regionicons/montserrat.svg';
import { ReactComponent as Morocco } from '../../icons/regionicons/morocco.svg';
import { ReactComponent as Mozambique } from '../../icons/regionicons/mozambique.svg';
import { ReactComponent as Myanmar } from '../../icons/regionicons/myanmar.svg';
import { ReactComponent as Namibia } from '../../icons/regionicons/namibia.svg';
import { ReactComponent as Nato } from '../../icons/regionicons/nato.svg';
import { ReactComponent as Nauru } from '../../icons/regionicons/nauru.svg';
import { ReactComponent as Nepal } from '../../icons/regionicons/nepal.svg';
import { ReactComponent as Netherlands } from '../../icons/regionicons/netherlands.svg';
import { ReactComponent as Newzealand } from '../../icons/regionicons/new_zealand.svg';
import { ReactComponent as Nicaragua } from '../../icons/regionicons/nicaragua.svg';
import { ReactComponent as Niger } from '../../icons/regionicons/niger.svg';
import { ReactComponent as Nigeria } from '../../icons/regionicons/nigeria.svg';
import { ReactComponent as Niue } from '../../icons/regionicons/niue.svg';
import { ReactComponent as Norfolkisland } from '../../icons/regionicons/norfolk_island.svg';
import { ReactComponent as Northkorea } from '../../icons/regionicons/north_korea.svg';
import { ReactComponent as Northmacedonia } from '../../icons/regionicons/north_macedonia.svg';
import { ReactComponent as NorthAmerica } from '../../icons/regionicons/north-america.svg';
import { ReactComponent as Northerncyprus } from '../../icons/regionicons/northern_cyprus.svg';
import { ReactComponent as Northernireland } from '../../icons/regionicons/northern_ireland.svg';
import { ReactComponent as Northernmarianasislands } from '../../icons/regionicons/northern_marianas_islands.svg';
import { ReactComponent as Norway } from '../../icons/regionicons/norway.svg';
import { ReactComponent as Oman } from '../../icons/regionicons/oman.svg';
import { ReactComponent as Orkneyislands } from '../../icons/regionicons/orkney_islands.svg';
import { ReactComponent as Ossetia } from '../../icons/regionicons/ossetia.svg';
import { ReactComponent as Pakistan } from '../../icons/regionicons/pakistan.svg';
import { ReactComponent as Palau } from '../../icons/regionicons/palau.svg';
import { ReactComponent as Palestine } from '../../icons/regionicons/palestine.svg';
import { ReactComponent as Panama } from '../../icons/regionicons/panama.svg';
import { ReactComponent as Papuanewguinea } from '../../icons/regionicons/papua_new_guinea.svg';
import { ReactComponent as Paraguay } from '../../icons/regionicons/paraguay.svg';
import { ReactComponent as Peru } from '../../icons/regionicons/peru.svg';
import { ReactComponent as Philippines } from '../../icons/regionicons/philippines.svg';
import { ReactComponent as Pitcairnislands } from '../../icons/regionicons/pitcairn_islands.svg';
import { ReactComponent as Poland } from '../../icons/regionicons/poland.svg';
import { ReactComponent as Portugal } from '../../icons/regionicons/portugal.svg';
import { ReactComponent as Puertorico } from '../../icons/regionicons/puerto_rico.svg';
import { ReactComponent as Qatar } from '../../icons/regionicons/qatar.svg';
import { ReactComponent as Rapanui } from '../../icons/regionicons/rapa_nui.svg';
import { ReactComponent as Republicofthecongo } from '../../icons/regionicons/republic_of_the_congo.svg';
import { ReactComponent as Romania } from '../../icons/regionicons/romania.svg';
import { ReactComponent as Russia } from '../../icons/regionicons/russia.svg';
import { ReactComponent as Rwanda } from '../../icons/regionicons/rwanda.svg';
import { ReactComponent as Sabaisland } from '../../icons/regionicons/saba_island.svg';
import { ReactComponent as Sahrawiarabdemocraticrepublic } from '../../icons/regionicons/sahrawi_arab_democratic_republic.svg';
import { ReactComponent as Saintkittsandnevis } from '../../icons/regionicons/saint_kitts_and_nevis.svg';
import { ReactComponent as Samoa } from '../../icons/regionicons/samoa.svg';
import { ReactComponent as Sanmarino } from '../../icons/regionicons/san_marino.svg';
import { ReactComponent as Saotomeandprince } from '../../icons/regionicons/sao_tome_and_prince.svg';
import { ReactComponent as Sardinia } from '../../icons/regionicons/sardinia.svg';
import { ReactComponent as Saudiarabia } from '../../icons/regionicons/saudi_arabia.svg';
import { ReactComponent as Scotland } from '../../icons/regionicons/scotland.svg';
import { ReactComponent as Senegal } from '../../icons/regionicons/senegal.svg';
import { ReactComponent as Serbia } from '../../icons/regionicons/serbia.svg';
import { ReactComponent as Seychelles } from '../../icons/regionicons/seychelles.svg';
import { ReactComponent as Sierraleone } from '../../icons/regionicons/sierra_leone.svg';
import { ReactComponent as Singapore } from '../../icons/regionicons/singapore.svg';
import { ReactComponent as Sinteustatius } from '../../icons/regionicons/sint_eustatius.svg';
import { ReactComponent as Sintmaarten } from '../../icons/regionicons/sint_maarten.svg';
import { ReactComponent as Slovakia } from '../../icons/regionicons/slovakia.svg';
import { ReactComponent as Slovenia } from '../../icons/regionicons/slovenia.svg';
import { ReactComponent as Solomonislands } from '../../icons/regionicons/solomon_islands.svg';
import { ReactComponent as Somalia } from '../../icons/regionicons/somalia.svg';
import { ReactComponent as Somaliland } from '../../icons/regionicons/somaliland.svg';
import { ReactComponent as Southafrica } from '../../icons/regionicons/south_africa.svg';
import { ReactComponent as Southkorea } from '../../icons/regionicons/south_korea.svg';
import { ReactComponent as Southsudan } from '../../icons/regionicons/south_sudan.svg';
import { ReactComponent as Spain } from '../../icons/regionicons/spain.svg';
import { ReactComponent as Srilanka } from '../../icons/regionicons/sri_lanka.svg';
import { ReactComponent as Stbarts } from '../../icons/regionicons/st_barts.svg';
import { ReactComponent as Stlucia } from '../../icons/regionicons/st_lucia.svg';
import { ReactComponent as Stvincentandthegrenadines } from '../../icons/regionicons/st_vincent_and_the_grenadines.svg';
import { ReactComponent as Sudan } from '../../icons/regionicons/sudan.svg';
import { ReactComponent as Suriname } from '../../icons/regionicons/suriname.svg';
import { ReactComponent as Swaziland } from '../../icons/regionicons/swaziland.svg';
import { ReactComponent as Sweden } from '../../icons/regionicons/sweden.svg';
import { ReactComponent as Switzerland } from '../../icons/regionicons/switzerland.svg';
import { ReactComponent as Syria } from '../../icons/regionicons/syria.svg';
import { ReactComponent as Taiwan } from '../../icons/regionicons/taiwan.svg';
import { ReactComponent as Tajikistan } from '../../icons/regionicons/tajikistan.svg';
import { ReactComponent as Thailand } from '../../icons/regionicons/thailand.svg';
import { ReactComponent as Tibet } from '../../icons/regionicons/tibet.svg';
import { ReactComponent as Togo } from '../../icons/regionicons/togo.svg';
import { ReactComponent as Tokelau } from '../../icons/regionicons/tokelau.svg';
import { ReactComponent as Tonga } from '../../icons/regionicons/tonga.svg';
import { ReactComponent as Transnistria } from '../../icons/regionicons/transnistria.svg';
import { ReactComponent as Trinidadandtobago } from '../../icons/regionicons/trinidad_and_tobago.svg';
import { ReactComponent as Tunisia } from '../../icons/regionicons/tunisia.svg';
import { ReactComponent as Turkey } from '../../icons/regionicons/turkey.svg';
import { ReactComponent as Turkmenistan } from '../../icons/regionicons/turkmenistan.svg';
import { ReactComponent as Turksandcaicos } from '../../icons/regionicons/turks_and_caicos.svg';
import { ReactComponent as Tuvalu } from '../../icons/regionicons/tuvalu.svg';
import { ReactComponent as Uae } from '../../icons/regionicons/uae.svg';
import { ReactComponent as Uganda } from '../../icons/regionicons/uganda.svg';
import { ReactComponent as Ukraine } from '../../icons/regionicons/ukraine.svg';
import { ReactComponent as Unitedkingdom } from '../../icons/regionicons/united_kingdom.svg';
import { ReactComponent as Unitednations } from '../../icons/regionicons/united_nations.svg';
import { ReactComponent as Unitedrepublicoftanzania } from '../../icons/regionicons/united_republic_of_tanzania.svg';
import { ReactComponent as Uruguay } from '../../icons/regionicons/uruguay.svg';
import { ReactComponent as Usa } from '../../icons/regionicons/usa.svg';
import { ReactComponent as Uzbekistan } from '../../icons/regionicons/uzbekistan.svg';
import { ReactComponent as Vanuatu } from '../../icons/regionicons/vanuatu.svg';
import { ReactComponent as Vaticancity } from '../../icons/regionicons/vatican_city.svg';
import { ReactComponent as Venezuela } from '../../icons/regionicons/venezuela.svg';
import { ReactComponent as Vietnam } from '../../icons/regionicons/vietnam.svg';
import { ReactComponent as Virginislands } from '../../icons/regionicons/virgin_islands.svg';
import { ReactComponent as Wales } from '../../icons/regionicons/wales.svg';
import { ReactComponent as World } from '../../icons/regionicons/world.svg';
import { ReactComponent as WorldColor } from '../../icons/regionicons/world-color.svg';
import { ReactComponent as Yemen } from '../../icons/regionicons/yemen.svg';
import { ReactComponent as Zambia } from '../../icons/regionicons/zambia.svg';
import { ReactComponent as Zanzibar } from '../../icons/regionicons/zanzibar.svg';
import { ReactComponent as Zimbabwe } from '../../icons/regionicons/zimbabwe.svg';
import { ReactComponent as Spinner } from '../../icons/spinner.svg';
import { ReactComponent as SpinnerDefault } from '../../icons/spinner-default.svg';
import { ReactComponent as ChatImage1 } from '../../icons/undraw_chatting_re_j55r_1.svg';
import { ReactComponent as ChatImage2 } from '../../icons/undraw_work_chat_re_qes4_1.svg';

const svgIcons = {
  flag_en: FlagEn,
  flag_es: FlagEs,
  flag_pb: FlagPb,
  flag_cs: FlagCs,
  flag_de: FlagDe,
  flag_ru: FlagRu,
  flag_tr: FlagTr,
  logo: Logo,
  layer_1: Layer1,
  layer_2: Layer2,
  main_banner: MainBanner,
  layer_winners_left: LayewWinnersLeft,
  layer_winners_right: LayewWinnersRight,
  bank_transfer: BankTransfer,
  abkhazia: Abkhazia,
  afghanistan: Afghanistan,
  'aland-islands': Alandislands,
  albania: Albania,
  algeria: Algeria,
  'american-samoa': Americansamoa,
  andorra: Andorra,
  angola: Angola,
  anguilla: Anguilla,
  'antigua-and-barbuda': Antiguaandbarbuda,
  argentina: Argentina,
  armenia: Armenia,
  aruba: Aruba,
  australia: Australia,
  austria: Austria,
  azerbaijan: Azerbaijan,
  'azores-islands': Azoresislands,
  bahamas: Bahamas,
  bahrain: Bahrain,
  'balearic-islands': Balearicislands,
  bangladesh: Bangladesh,
  barbados: Barbados,
  'basque-country': Basquecountry,
  belarus: Belarus,
  belgium: Belgium,
  belize: Belize,
  benin: Benin,
  bermuda: Bermuda,
  'bhutan-2': Bhutan2,
  bhutan: Bhutan,
  bolivia: Bolivia,
  bonaire: Bonaire,
  bosniaherzegovina: Bosniaandherzegovina,
  botswana: Botswana,
  brazil: Brazil,
  'british-columbia': Britishcolumbia,
  'british-indian-ocean-territory': Britishindianoceanterritory,
  'british-virgin-islands': Britishvirginislands,
  'brunei-darussalam': Bruneidarussalam,
  bulgaria: Bulgaria,
  'burkina-faso': Burkinafaso,
  burundi: Burundi,
  cambodia: Cambodia,
  cameroon: Cameroon,
  canada: Canada,
  'canary-islands': Canaryislands,
  'cape-verde': Capeverde,
  'cayman-islands': Caymanislands,
  'central-african-republic': Centralafricanrepublic,
  ceuta: Ceuta,
  chad: Chad,
  chile: Chile,
  china: China,
  'christmas-island': Christmasisland,
  'cocos-island': Cocosisland,
  colombia: Colombia,
  comoros: Comoros,
  'cook-islands': Cookislands,
  corsica: Corsica,
  'costa-rica': Costarica,
  croatia: Croatia,
  cuba: Cuba,
  curacao: Curacao,
  cyprus: Cyprus,
  'czech-republic': Czechrepublic,
  'democratic-republic-of-congo': Democraticrepublicofcongo,
  denmark: Denmark,
  djibouti: Djibouti,
  dominica: Dominica,
  'dominican-republic': Dominicanrepublic,
  'east-timor': Easttimor,
  ecuador: Ecuador,
  egypt: Egypt,
  'el-salvador': Elsalvador,
  england: England,
  'equatorial-guinea': Equatorialguinea,
  eritrea: Eritrea,
  estonia: Estonia,
  ethiopia: Ethiopia,
  'european-union': Europeanunion,
  europe: Europe,
  'falkland-islands': Falklandislands,
  'faroe-islands': Faroeislands,
  fiji: Fiji,
  finland: Finland,
  france: France,
  'french-polynesia': Frenchpolynesia,
  gabon: Gabon,
  'galapagos-islands': Galapagosislands,
  gambia: Gambia,
  georgia: Georgia,
  germany: Germany,
  ghana: Ghana,
  gibraltar: Gibraltar,
  greece: Greece,
  greenland: Greenland,
  grenada: Grenada,
  'great-britain': Greatbritain,
  guam: Guam,
  guatemala: Guatemala,
  guernsey: Guernsey,
  'guinea-bissau': Guineabissau,
  guinea: Guinea,
  guyana: Guyana,
  haiti: Haiti,
  hawaii: Hawaii,
  honduras: Honduras,
  'hong-kong': Hongkong,
  hungary: Hungary,
  iceland: Iceland,
  india: India,
  indonesia: Indonesia,
  iran: Iran,
  iraq: Iraq,
  ireland: Ireland,
  'isle-of-man': Isleofman,
  israel: Israel,
  italy: Italy,
  'ivory-coast': Ivorycoast,
  jamaica: Jamaica,
  japan: Japan,
  jersey: Jersey,
  jordan: Jordan,
  kazakhstan: Kazakhstan,
  kenya: Kenya,
  kiribati: Kiribati,
  kosovo: Kosovo,
  kuwait: Kuwait,
  kyrgyzstan: Kyrgyzstan,
  laos: Laos,
  latvia: Latvia,
  lebanon: Lebanon,
  lesotho: Lesotho,
  liberia: Liberia,
  libya: Libya,
  liechtenstein: Liechtenstein,
  lithuania: Lithuania,
  luxembourg: Luxembourg,
  macao: Macao,
  madagascar: Madagascar,
  madeira: Madeira,
  malawi: Malawi,
  malaysia: Malaysia,
  maldives: Maldives,
  mali: Mali,
  malta: Malta,
  'marshall-island': Marshallisland,
  martinique: Martinique,
  mauritania: Mauritania,
  mauritius: Mauritius,
  melilla: Melilla,
  mexico: Mexico,
  micronesia: Micronesia,
  moldova: Moldova,
  monaco: Monaco,
  mongolia: Mongolia,
  montenegro: Montenegro,
  montserrat: Montserrat,
  morocco: Morocco,
  mozambique: Mozambique,
  myanmar: Myanmar,
  namibia: Namibia,
  nato: Nato,
  nauru: Nauru,
  nepal: Nepal,
  netherlands: Netherlands,
  'new-zealand': Newzealand,
  nicaragua: Nicaragua,
  niger: Niger,
  nigeria: Nigeria,
  niue: Niue,
  'norfolk-island': Norfolkisland,
  'north-korea': Northkorea,
  'north-america': NorthAmerica,
  'northern-cyprus': Northerncyprus,
  'northern-marianas-islands': Northernmarianasislands,
  'northern-ireland': Northernireland,
  norway: Norway,
  oman: Oman,
  'orkney-islands': Orkneyislands,
  ossetia: Ossetia,
  pakistan: Pakistan,
  palau: Palau,
  palestine: Palestine,
  panama: Panama,
  'papua-new-guinea': Papuanewguinea,
  paraguay: Paraguay,
  peru: Peru,
  philippines: Philippines,
  'pitcairn-islands': Pitcairnislands,
  poland: Poland,
  portugal: Portugal,
  'puerto-rico': Puertorico,
  qatar: Qatar,
  'rapa-nui': Rapanui,
  'north-macedonia': Northmacedonia,
  'republic-of-the-congo': Republicofthecongo,
  romania: Romania,
  russia: Russia,
  rwanda: Rwanda,
  'saba-island': Sabaisland,
  'sahrawi-arab-democratic-republic': Sahrawiarabdemocraticrepublic,
  'saint-kitts-and-nevis': Saintkittsandnevis,
  samoa: Samoa,
  'san-marino': Sanmarino,
  'sao-tome-and-prince': Saotomeandprince,
  sardinia: Sardinia,
  'saudi-arabia': Saudiarabia,
  scotland: Scotland,
  senegal: Senegal,
  serbia: Serbia,
  seychelles: Seychelles,
  'sierra-leone': Sierraleone,
  singapore: Singapore,
  spinner: Spinner,
  'sint-eustatius': Sinteustatius,
  'sint-maarten': Sintmaarten,
  slovakia: Slovakia,
  slovenia: Slovenia,
  'solomon-islands': Solomonislands,
  somalia: Somalia,
  somaliland: Somaliland,
  'south-africa': Southafrica,
  'south-korea': Southkorea,
  'south-sudan': Southsudan,
  spain: Spain,
  'sri-lanka': Srilanka,
  'st-barts': Stbarts,
  'st-lucia': Stlucia,
  'st-vincent-and-the-grenadines': Stvincentandthegrenadines,
  sudan: Sudan,
  suriname: Suriname,
  swaziland: Swaziland,
  sweden: Sweden,
  switzerland: Switzerland,
  syria: Syria,
  taiwan: Taiwan,
  tajikistan: Tajikistan,
  'united-republic-of-tanzania': Unitedrepublicoftanzania,
  thailand: Thailand,
  tibet: Tibet,
  togo: Togo,
  tokelau: Tokelau,
  tonga: Tonga,
  transnistria: Transnistria,
  trinidadtobago: Trinidadandtobago,
  tunisia: Tunisia,
  turkey: Turkey,
  turkmenistan: Turkmenistan,
  'turks-and-caicos': Turksandcaicos,
  tuvalu: Tuvalu,
  uganda: Uganda,
  ukraine: Ukraine,
  uae: Uae,
  'united-kingdom': Unitedkingdom,
  'united-nations': Unitednations,
  usa: Usa,
  uruguay: Uruguay,
  uzbekistan: Uzbekistan,
  vanuatu: Vanuatu,
  'vatican-city': Vaticancity,
  venezuela: Venezuela,
  vietnam: Vietnam,
  'virgin-islands': Virginislands,
  wales: Wales,
  yemen: Yemen,
  zambia: Zambia,
  zimbabwe: Zimbabwe,
  zanzibar: Zanzibar,
  world: World,
  'world-color': WorldColor,
  undraw_chatting_re_j55r_1: ChatImage1,
  undraw_work_chat_re_qes4_1: ChatImage2,
  'spinner-default': SpinnerDefault,
  frame9157: Frame9157,
  chat_arrow: ChatArrow,
  bb_betslip: BBBetslip,
  bt_betslip: BTBetslip,
  logo_p1: LogoP1,
  'logo-full-size': LogoFullSize,
  'logo-small-size': LogoSmallSize,
  plus: Plus,
  moderator: Moderator,
  'logo-vbet': LogoVbet,
  'logo-softconstruct': LogoSoftconstruct,
  rating: Rating,
  'free-bet-0': FreeBet0,
  'free-bet-1': FreeBet1,
  'all-0': AllCurr,
  'bank-transfer-0': BankTransfer1,
  'br-0': Br,
  'crypto-0': Crypto,
  EUR: Eur,
  'fr-0': Fr,
  'mobile-0': Mobile,
  't-0': T,
  USD: Usd,
  FTN: Ftn,
  promo: Promo
};

export default svgIcons;
