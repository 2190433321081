import React, { useRef } from 'react';

import { SvgIcon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import {
  Accordion,
  Box,
  BUTTON_INTENT,
  BUTTON_SIZE,
  EllipsisText
} from '@monorepo/ui-kit';

import { useMenuRegionsData } from '../../../../hooks/useGetMenuRegions';
import { selectRegionById } from '../../../../store/api/selectors';
import { formatIconNameFromAlias } from '../../../../utils';
import MenuCount from '../../../MenuCount';

import styles from './index.module.css';

type TRegionProps = {
  regionId: number;
  isActive?: boolean;
  activeSport?: string;
};

const Region: Component<TRegionProps> = ({
  regionId,
  children,
  isActive = false,
  activeSport
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const { data: region } = useMenuRegionsData(
    (result) => selectRegionById(result, regionId),
    { sportAlias: activeSport }
  );

  const { alias = '', name, game } = region;

  return (
    <Accordion
      className={styles.root}
      size={BUTTON_SIZE.h42}
      activeClassName={styles.active}
      bodyClassName={styles.body}
      headerIconClassName={styles.accordionIcon}
      isDefaultOpen={isActive}
      innerRef={ref}
      intent={BUTTON_INTENT.transparent}
      label={
        <>
          <Box className={styles.iconContainer}>
            <SvgIcon
              name={formatIconNameFromAlias(alias)}
              className={styles.icon}
              defaultIcon="world"
            />
          </Box>
          <EllipsisText className={styles.title}>{name}</EllipsisText>
          <MenuCount count={game} className={styles.count} />
        </>
      }
    >
      {children}
    </Accordion>
  );
};
export default Region;
