import React from 'react';

import { TMenuItem } from '../../../types';
import Menu from '../Menu';

const NavMenu = ({
  items,
  linkClassName,
  children
}: {
  items: Array<TMenuItem>;
  linkClassName?: string;
  children: React.ReactNode;
}) => (
  <Menu items={items} linkClassName={linkClassName}>
    {children}
  </Menu>
);

export default NavMenu;
