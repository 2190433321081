/* eslint-disable no-param-reassign */
import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { TPayload } from '@monorepo/type';

type TState = {
  version: string;
  errorsCount: number;
  sessionSwarmId: string;
};

const initialState = {
  version: '',
  errorsCount: 0,
  sessionSwarmId: ''
};

const appSlice = createSlice<TState, SliceCaseReducers<TState>>({
  name: 'app',
  initialState,
  reducers: {
    setAppVersion: (state, { payload }: TPayload<string>) => {
      state.version = payload;
      return state;
    },
    setSessionSwarmId: (state, { payload }: TPayload<string>) => {
      state.sessionSwarmId = payload;
      return state;
    },
    resetErrorCount: (state) => {
      state.errorsCount = initialState.errorsCount;
      return state;
    },
    incrementErrorCount: (state) => {
      state.errorsCount += 1;
      return state;
    }
  }
});

export default appSlice;
