import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getShare from './api/getShare';

const shareApiHost = import.meta.env.VITE_APP_CMS_SHARE_BET_HOST;

export const shareBetApi = createApi({
  reducerPath: 'shareBetApi',
  baseQuery: fetchBaseQuery({ baseUrl: shareApiHost }),
  endpoints: (builder) => ({
    getShareBet: builder.query(getShare())
  })
});

export const { useGetShareBetQuery } = shareBetApi;
