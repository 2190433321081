import { useNavigate } from 'react-router-dom';

import { useSelectJwtAuth } from '@monorepo/auth';
import { useDeleteBlogMutation } from '@monorepo/common/src/store/userGeneratedContentApi';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useNotifications } from '@monorepo/notification';
import { useSiteSettings } from '@monorepo/site-settings';

const useGetBlogDelete = () => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { successNotification, errorNotification } = useNotifications();
  const { generalRoutes } = useSiteSettings();
  const { blogs } = generalRoutes;
  const navigate = useNavigate();
  const jwt = useSelectJwtAuth();
  const [deleteBlog] = useDeleteBlogMutation();
  const handleDeleteBlog = async (id: any) => {
    const { error } = await deleteBlog({ id, jwt });
    if (error) {
      errorNotification(`${error.data.message}: ${error.data.key}`);
    } else {
      successNotification(t('blogs.removed', { id }));
      navigate(`${blogs}/category/my`);
    }
  };
  return { handleDeleteBlog };
};

export default useGetBlogDelete;
