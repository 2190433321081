import AnimationView from '../components/AnimationView';
import BackHeaderResults from '../components/BackHeaderResults';
import BetDetails from '../components/BetDetails';
import BetsFilters from '../components/BetsFilters';
import BetsHistory from '../components/BetsHistory';
import BetslipHome from '../components/BetslipHome';
import BetslipInfoShutter from '../components/BetslipInfoShutter';
import CompetitionHeader from '../components/CompetitionHeader';
import CompetitionSwitcher from '../components/CompetitionSwitcher';
import DesktopPreMatchSportTabs from '../components/DesktopPreMatchSportTabs';
import EventHeaderWrapper from '../components/EventHeaderWrapper';
import EventStatistics from '../components/EventStatistics';
import EventTabs from '../components/EventTabs';
import EventTitleWrapper from '../components/EventTitleWrapper';
import Favorites from '../components/Favorites';
import FavoriteSports from '../components/FavoriteSports';
import FavoriteSportsCompetitions from '../components/FavoriteSportsCompetitions';
import FavoriteSportsCompetitionsMain from '../components/FavoriteSportsCompetitionsMain';
import FavoriteSportsLine from '../components/FavoriteSportsLine';
import FavoriteTeam from '../components/FavoriteTeam';
import GameAnimation from '../components/GameAnimation';
import LiveStreamFrame from '../components/LiveStreamFrame';
import MobilePopularCompetitions from '../components/MobilePopularCompetitions';
import MobileRegionsMenu from '../components/MobileRegionsMenu';
import PreMatchSportTabs from '../components/PreMatchSportTabs';
import QuickBet from '../components/QuickBet';
import SearchSportsCompetitions from '../components/SearchSportsCompetitions';
import SportFilters from '../components/SportFilters';
import SportsBookRedirect from '../components/SportsBookRedirect';
import SportsCoupon from '../components/SportsCoupon';
import SportsEvents from '../components/SportsEvents';
import SportsLine from '../components/SportsLine';
import SportsLineSearch from '../components/SportsLineSearch';
import SportsNavigation from '../components/SportsNavigation';
import SportsPopularSlider from '../components/SportsPopularSlider';
import SportsRecommendedLine from '../components/SportsRecommendedLine';
import SportsRecommendedNavigation from '../components/SportsRecommendedNavigation';
import SportsSuggestedSlider from '../components/SportsSuggestedSlider';
import TimeFilter from '../components/TimeFilter';
import SportsTournament from '../components/Tournament';
import SportsTournaments from '../components/Tournaments';

import COMPONENT_TYPES from './componentTypes';

const COMPONENTS_MAP = {
  [COMPONENT_TYPES.MOBILE_REGIONS_MENU]: MobileRegionsMenu,
  [COMPONENT_TYPES.SPORTS_LINE]: SportsLine,
  [COMPONENT_TYPES.SPORTS_RECOMMENDED_LINE]: SportsRecommendedLine,
  [COMPONENT_TYPES.SPORTS_LINE_SEARCH]: SportsLineSearch,
  [COMPONENT_TYPES.PRE_MATCH_SPORTS_TABS]: PreMatchSportTabs,
  [COMPONENT_TYPES.DESKTOP_PRE_MATCH_SPORTS_TABS]: DesktopPreMatchSportTabs,
  [COMPONENT_TYPES.EVENT_HEADER]: EventHeaderWrapper,
  [COMPONENT_TYPES.EVENT_TITLE]: EventTitleWrapper,
  [COMPONENT_TYPES.SPORTS_COUPON]: SportsCoupon,
  [COMPONENT_TYPES.EVENT_STATISTICS]: EventStatistics,
  [COMPONENT_TYPES.EVENT_TABS]: EventTabs,
  [COMPONENT_TYPES.TIME_FILTER]: TimeFilter,
  [COMPONENT_TYPES.COMPETITION_SWITCHER]: CompetitionSwitcher,
  [COMPONENT_TYPES.COMPETITION_HEADER]: CompetitionHeader,
  [COMPONENT_TYPES.SPORTS_NAVIGATION]: SportsNavigation,
  [COMPONENT_TYPES.SPORTS_RECOMMENDED_NAVIGATION]: SportsRecommendedNavigation,
  [COMPONENT_TYPES.GAME_ANIMATION]: GameAnimation,
  [COMPONENT_TYPES.LIVE_STREAM_FRAME]: LiveStreamFrame,
  [COMPONENT_TYPES.ANIMATION_VIEW]: AnimationView,
  [COMPONENT_TYPES.SPORTS_POPULAR]: SportsPopularSlider,
  [COMPONENT_TYPES.SPORTS_SUGGESTED]: SportsSuggestedSlider,
  [COMPONENT_TYPES.MOBILE_POPULAR_COMPETITIONS]: MobilePopularCompetitions,
  [COMPONENT_TYPES.BETSLIP_INFO_SHUTTER]: BetslipInfoShutter,
  [COMPONENT_TYPES.BETSLIP_HOME]: BetslipHome,
  [COMPONENT_TYPES.SPORTS_BOOK_REDIRECT]: SportsBookRedirect,
  [COMPONENT_TYPES.SPORT_RESULTS_FILTERS]: SportFilters,
  [COMPONENT_TYPES.SPORT_EVENTS]: SportsEvents,
  [COMPONENT_TYPES.SPORT_BACK_HEADER_RESULTS]: BackHeaderResults,
  [COMPONENT_TYPES.QUICKBET]: QuickBet,
  [COMPONENT_TYPES.FAVORITES]: Favorites,
  [COMPONENT_TYPES.FAVORITE_TEAM]: FavoriteTeam,
  [COMPONENT_TYPES.FAVORITESPORTS]: FavoriteSports,
  [COMPONENT_TYPES.FAVORITE_SPORTS_LINE]: FavoriteSportsLine,
  [COMPONENT_TYPES.FAVORITE_SPORTS_COMPETITIONS]: FavoriteSportsCompetitions,
  [COMPONENT_TYPES.FAVORITE_SPORTS_COMPETITIONS_MAIN]:
    FavoriteSportsCompetitionsMain,
  [COMPONENT_TYPES.BETS_HISTORY_ALL]: BetsHistory,
  [COMPONENT_TYPES.BETS_FILTERS]: BetsFilters,
  [COMPONENT_TYPES.SEARCH_SPORTS_COMPETITIONS]: SearchSportsCompetitions,
  [COMPONENT_TYPES.SPORTS_TOURNAMENTS]: SportsTournaments,
  [COMPONENT_TYPES.SPORTS_TOURNAMENT]: SportsTournament,
  [COMPONENT_TYPES.BET_DETAILS]: BetDetails
};

export default COMPONENTS_MAP;
