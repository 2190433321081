import { TUpdatePublicProfileInformation } from '../../../../types';
import { CUSTOM_API_QUERY_KEYS } from '../../constants';
import { customApi } from '../../index';

const updatePlayerProfileSettings = () => ({
  query: (args: TUpdatePublicProfileInformation) => {
    const url = `/public_profile/${args.userId}`;
    return {
      url,
      method: 'PUT',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${args.jwt}`
      },
      body: {
        rules: args.rules
      }
    };
  },
  invalidatesTags: [CUSTOM_API_QUERY_KEYS.PLAYER_INFORMATION],
  async onQueryStarted(data: any, { dispatch, queryFulfilled }) {
    const { data: bodyParams, ...args } = data;
    const patchResult = dispatch(
      customApi.util.updateQueryData('getPlayerInfo', args, (draft: any) => {
        Object.assign(draft, bodyParams);
      })
    );
    try {
      await queryFulfilled;
    } catch {
      patchResult.undo();
    }
  }
});

export default updatePlayerProfileSettings;
