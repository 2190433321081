import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const BetslipHome = lazy(() => import('./BetslipHome'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BetslipHome {...props} />
  </Suspense>
);
