import get from 'lodash/get';

import { TNormalizedCompetition } from '../../../types';

export const normalizeCompetitionResponse = (
  rawResponse: Record<string, any>
): TNormalizedCompetition => {
  const sport = get(rawResponse, 'data.sport', {});
  const subid = get(rawResponse, 'subid', '');
  return {
    sport,
    subid
  };
};
