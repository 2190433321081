import { useEffect } from 'react';

import { useSiteSettings } from '@monorepo/site-settings';

import { useRequestAuthLoginMutation } from '../../store/authLoginV2';

const useAuthLogin = (jwt?: string) => {
  const { siteId } = useSiteSettings();
  const [authLogin] = useRequestAuthLoginMutation();

  useEffect(() => {
    if (jwt) {
      authLogin({ jwt, partnerId: siteId });
    }
  }, [jwt]);
};

export default useAuthLogin;
