import React from 'react';

import { Logo } from '@monorepo/common';
import { HeaderAuth as UiHeaderAuth } from '@monorepo/layout';
import { useSiteSettings } from '@monorepo/site-settings';
import { Box } from '@monorepo/ui-kit';

import styles from './index.module.css';

const HeaderAuth = () => {
  const { logoSmall } = useSiteSettings();
  return (
    <UiHeaderAuth wrapClassName={styles.wrap}>
      <Box className={styles.logo}>
        <Logo
          config={{
            link: '/',
            title: '',
            logoIcon: logoSmall,
            src: ''
          }}
        />
      </Box>
    </UiHeaderAuth>
  );
};
export default HeaderAuth;
