/* eslint-disable prefer-object-spread,no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type UserState = {
  isAuthenticated: boolean;
  isAuthFetched: boolean;
  isAccountFrozen: boolean;
  jwt: string | null;
  jwtAuthToken: string | null;
  messengerAuthToken: string | null;
};

const initialState: UserState = {
  isAuthenticated: false,
  isAuthFetched: false,
  isAccountFrozen: false,
  jwt: null,
  jwtAuthToken: null,
  messengerAuthToken: null
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsAuthenticated: (state, { payload }) => {
      const { isAuthenticated, isAuthFetched } = payload;
      state.isAuthenticated = Boolean(isAuthenticated);
      state.isAuthFetched = Boolean(isAuthFetched);
      return state;
    },
    setJwt: (state, { payload }: PayloadAction<UserState['jwt']>) => {
      state.jwt = payload;
      return state;
    },
    setJwtAuthToken: (
      state,
      { payload }: PayloadAction<UserState['jwtAuthToken']>
    ) => {
      state.jwtAuthToken = payload;
      return state;
    },
    setMessengerAuthToken: (
      state,
      { payload }: PayloadAction<UserState['messengerAuthToken']>
    ) => {
      state.messengerAuthToken = payload;
      return state;
    }
  }
});

export const { actions } = userSlice;
