/*
 * https://xmpp.org/rfcs/rfc6121.html#roster-syntax-items-subscription
 */
export enum SUBSCRIPTION_TYPE {
  NONE = 'none',
  FROM = 'from',
  TO = 'to',
  BOTH = 'both',
  REMOVE = 'remove'
}

/*
 * https://xmpp.org/rfcs/rfc6121.html#message-syntax-type
 */
export enum MESSAGES_TYPES {
  SINGLE = 'chat',
  GROUP = 'groupchat',
  ERROR = 'error',
  NORMAL = 'normal',
  HEADLINE = 'headline'
}

/*
https://xmpp.org/rfcs/rfc6121.html#presence-syntax-type
 */
export enum PRESENCE_TYPE {
  ERROR = 'error',
  PROBE = 'probe',
  SUBSCRIBE = 'subscribe',
  SUBSCRIBED = 'subscribed',
  UNAVAILABLE = 'unavailable',
  UNSUBSCRIBE = 'unsubscribe',
  UNSUBSCRIBED = 'unsubscribed'
}

/*
 * https://xmpp.org/rfcs/rfc6121.html#presence-syntax-children-show
 */
export enum SHOW_VALUE {
  AWAY = 'away',
  CHAT = 'chat',
  DO_NOT_DISTURB = 'dnd',
  EXTENDED_AWAY = 'xa'
}

export enum IQ_TYPE {
  ERROR = 'error',
  SET = 'set',
  GET = 'get',
  RESULT = 'result'
}

export enum MUCLIGHT_AFFILIATION {
  NONE = 'none',
  OWNER = 'owner',
  MEMBER = 'member'
}

export enum ACTION_TYPES {
  DENY = 'deny',
  ALLOW = 'allow'
}

export enum ACTION_TAGS {
  USER = 'user',
  ROOM = 'room'
}

export enum MUC_AFFILIATION {
  NONE = 'none',
  OUTCAST = 'outcast',
  MEMBER = 'member',
  ADMIN = 'admin',
  OWNER = 'owner'
}

export enum MUC_ROLE {
  NONE = 'none',
  VISITOR = 'visitor',
  PARTICIPANT = 'participant',
  MODERATOR = 'moderator'
}

export enum X_DATA_TYPE {
  CANCEL = 'cancel',
  SUBMIT = 'submit',
  FORM = 'form'
}

export enum MUC_ROOM_CONFIG {
  // Room title - text-single
  ROOM_NAME = 'muc#roomconfig_roomname',
  // Room description - text-single
  ROOM_DESCRIPTION = 'muc#roomconfig_roomdesc',
  // Make room persistent {`0`|`1`} - boolean
  PERSISTENT_ROOM = 'muc#roomconfig_persistentroom',
  // Make room public searchable (`0`|`1`) - boolean
  PUBLIC_ROOM = 'muc#roomconfig_publicroom',
  // Make participants list public (`0`|`1`) - boolean
  PUBLIC_LIST = 'public_list',
  // Make room password protected (`0`|`1`) - boolean
  PASSWORD_PROTECTED = 'muc#roomconfig_passwordprotectedroom',
  // Password - text-private
  PASSWORD = 'muc#roomconfig_roomsecret',
  // Roles and affiliations that may retrieve member list (`moderator`,`participant`,`visitor`) - list-multi
  GET_MEMBERS_LIST = 'muc#roomconfig_getmemberlist',
  // Maximum Number of Occupants (`5`|`10`|`20`|`30`|`50`|`100`|`200`|`500`|`1000`|`1024`) - list-single
  MAX_USERS = 'muc#roomconfig_maxusers',
  // Present real Jabber IDs to (`moderators`|`anyone`) - list-single
  WHO_IS = 'muc#roomconfig_whois',
  // Make room members-only (`0`|`1`) - boolean
  MEMBERS_ONLY = 'muc#roomconfig_membersonly',
  // Make room moderated (`0`|`1`) - boolean
  MODERATED_ROOM = 'muc#roomconfig_moderatedroom',
  // Default users as participants (`0`|`1`) - boolean
  MEMBERS_BY_DEFAULT = 'members_by_default',
  // Allow users to change the subject (`0`|`1`) - boolean
  CHANGE_SUBJECT = 'muc#roomconfig_changesubject',
  // Allow users to send private messages (`0`|`1`) - boolean
  ALLOW_PRIVATE_MESSAGES = 'allow_private_messages',
  // Allow users to query other users (`0`|`1`) - boolean
  ALLOW_QUERY_USERS = 'allow_query_users',
  // Allow users to send invites (`0`|`1`) - boolean
  ALLOW_INVITES = 'muc#roomconfig_allowinvites',
  // Allow users to enter room with multiple sessions (`0`|`1`) - boolean
  ALLOW_MULTI_SESSIONS = 'muc#roomconfig_allowmultisessions',
  // Allow visitors to send status text in presence updates (`0`|`1`) - boolean
  ALLOW_VISITOR_STATUS = 'muc#roomconfig_allowvisitorstatus',
  // Allow visitors to change nickname (`0`|`1`) - boolean
  ALLOW_VISITOR_NICK_CHANGE = 'muc#roomconfig_allowvisitornickchange'
}

export enum BOOLEAN_FIELD {
  FALSE = '0',
  TRUE = '1'
}

export const ANYONE_VALUE = 'anyone';

export enum ERROR_TYPE {
  CANCEL = 'cancel',
  CONTINUE = 'continue',
  MODIFY = 'modify',
  AUTH = 'auth',
  WAIT = 'wait'
}

export enum ERROR_DEFINED_CONDITION {
  BAD_REQUEST = 'bad-request',
  CONFLICT = 'conflict',
  FEATURE_NOT_IMPLEMENTED = 'feature-not-implemented',
  FORBIDDEN = 'forbidden',
  GONE = 'gone',
  INTERNAL_SERVER_ERROR = 'internal-server-error',
  ITEM_NOT_FOUND = 'item-not-found',
  JID_MALFORMED = 'jid-malformed',
  NOT_ACCEPTABLE = 'not-acceptable',
  NOT_ALLOWED = 'not-allowed',
  NOT_AUTHORIZED = 'not-authorized',
  PAYMENT_REQUIRED = 'payment-required',
  RECIPIENT_UNAVAILABLE = 'recipient-unavailable',
  REDIRECT = 'redirect',
  REGISTRATION_REQUIRED = 'registration-required',
  REMOTE_SERVER_NOT_FOUND = 'remote-server-not-found',
  REMOTE_SERVER_TIMEOUT = 'remote-server-timeout',
  RESOURCE_CONSTRAINT = 'resource-constraint',
  SERVICE_UNAVAILABLE = 'service-unavailable',
  SUBSCRIPTION_REQUIRED = 'subscription-required',
  UNDEFINED_CONDITION = 'undefined-condition',
  UNEXPECTED_REQUEST = 'unexpected-request'
}

export const MUCLIGHT_SERVER = `muclight.${import.meta.env.VITE_APP_XMPP_DOMAIN}`;

export const MUC_SERVER = `muc.${import.meta.env.VITE_APP_XMPP_DOMAIN}`;

/**
 * https://xmpp.org/extensions/xep-0045.html#registrar-statuscodes
 */
export enum MUC_PRESENCE_STATUS {
  NON_ANONYMOUS = '100',
  SELF_PRESENCE = '110',
  LOGGING_ENABLED = '170',
  ROOM_CREATED = '201',
  ASSIGN_MODIFY_NICK = '210',
  BANNED = '301',
  EXIT = '303',
  KICKED = '307',
  REMOVED_AFFILIATION_CHANGED = '321',
  REMOVED_CONFIGURATION_CHANGED = '322',
  REMOVED_SERVICE_SHUT_DOWN = '332',
  REMOVED_TECHNICAL_ISSUE = '333'
}

export enum MUC_MESSAGE_STATUS {
  NON_ANONYMOUS = '100',
  AFFILIATION_CHANGE = '101',
  CONFIG_CHANGE_SHOW_UNAVAILABLE = '102',
  CONFIG_CHANGE_NOT_SHOW_UNAVAILABLE = '103',
  CONFIG_CHANGE_NON_PRIVACY_RELATED = '104',
  CONFIG_CHANGE_LOGGING_ENABLED = '170',
  CONFIG_CHANGE_LOGGING_DISABLED = '171',
  CONFIG_CHANGE_NON_ANONYMOUS = '172',
  CONFIG_CHANGE_SEMI_ANONYMOUS = '173'
}

export enum INBOX_ORDER {
  ACS = 'asc',
  DESC = 'desc'
}

export enum INBOX_BOX {
  ALL = 'all',
  BOX = 'box',
  ARCHIVE = 'archive',
  BIN = 'bin'
}
