/** Constants: XMPP Namespace Constants
 *  Common namespace constants from the XMPP RFCs and XEPs.
 *
 *  NS.HTTPBIND - HTTP BIND namespace from XEP 124.
 *  NS.BOSH - BOSH namespace from XEP 206.
 *  NS.CLIENT - Main XMPP client namespace.
 *  NS.AUTH - Legacy authentication namespace.
 *  NS.ROSTER - Roster operations namespace.
 *  NS.PROFILE - Profile namespace.
 *  NS.DISCO_INFO - Service discovery info namespace from XEP 30.
 *  NS.DISCO_ITEMS - Service discovery items namespace from XEP 30.
 *  NS.MUC - Multi-User Chat namespace from XEP 45.
 *  NS.SASL - XMPP SASL namespace from RFC 3920.
 *  NS.STREAM - XMPP Streams namespace from RFC 3920.
 *  NS.BIND - XMPP Binding namespace from RFC 3920 and RFC 6120.
 *  NS.SESSION - XMPP Session namespace from RFC 3920.
 *  NS.XHTML_IM - XHTML-IM namespace from XEP 71.
 *  NS.XHTML - XHTML body namespace from XEP 71.
 */
export const XML_NAMESPACES = {
  HTTPBIND: 'http://jabber.org/protocol/httpbind',
  BOSH: 'urn:xmpp:xbosh',
  CLIENT: 'jabber:client',
  AUTH: 'jabber:iq:auth',
  ROSTER: 'jabber:iq:roster',
  PROFILE: 'jabber:iq:profile',
  DISCO_INFO: 'http://jabber.org/protocol/disco#info',
  DISCO_ITEMS: 'http://jabber.org/protocol/disco#items',
  MUC: 'http://jabber.org/protocol/muc',
  MUC_USER: 'http://jabber.org/protocol/muc#user',
  MUC_REQUEST: 'http://jabber.org/protocol/muc#request',
  MUC_ADMIN: 'http://jabber.org/protocol/muc#admin',
  MUC_OWNER: 'http://jabber.org/protocol/muc#owner',
  MUC_ROOM_CONFIG: 'http://jabber.org/protocol/muc#roomconfig',
  MUC_ROOM_INFO: 'http://jabber.org/protocol/muc#roominfo',
  MUC_UNIQUE: 'http://jabber.org/protocol/muc#unique',
  MUC_ROOMS: 'http://jabber.org/protocol/muc#rooms',
  MUC_TRAFFIC: 'http://jabber.org/protocol/muc#traffic',
  SASL: 'urn:ietf:params:xml:ns:xmpp-sasl',
  STREAM: 'http://etherx.jabber.org/streams',
  FRAMING: 'urn:ietf:params:xml:ns:xmpp-framing',
  BIND: 'urn:ietf:params:xml:ns:xmpp-bind',
  SESSION: 'urn:ietf:params:xml:ns:xmpp-session',
  VERSION: 'jabber:iq:version',
  STANZAS: 'urn:ietf:params:xml:ns:xmpp-stanzas',
  XHTML_IM: 'http://jabber.org/protocol/xhtml-im',
  XHTML: 'http://www.w3.org/1999/xhtml',
  CARBONS: 'urn:xmpp:carbons', // 2
  REGISTER: 'jabber:iq:register',
  // additional namespaces
  FORWARD: 'urn:xmpp:forward:0', // 0
  RECEIPTS: 'urn:xmpp:receipts',
  DELAY: 'urn:xmpp:delay',
  PING: 'urn:xmpp:ping',
  MAM: 'urn:xmpp:mam:2', // 0 | 1 | 2
  X_DATA: 'jabber:x:data',
  X_CONFERENCE: 'jabber:x:conference',
  RSM: 'http://jabber.org/protocol/rsm',
  STANZA_ID: 'urn:xmpp:sid:0',
  ADDRESS: 'http://jabber.org/protocol/address',
  MUCLIGHT: 'urn:xmpp:muclight:0',
  MUCLIGHT_CREATE: 'urn:xmpp:muclight:0#create',
  MUCLIGHT_INFO: 'urn:xmpp:muclight:0#info',
  MUCLIGHT_CONFIGURATION: 'urn:xmpp:muclight:0#configuration',
  MUCLIGHT_AFFILIATIONS: 'urn:xmpp:muclight:0#affiliations',
  MUCLIGHT_BLOCKING: 'urn:xmpp:muclight:0#blocking',
  MUCLIGHT_DESTROY: 'urn:xmpp:muclight:0#destroy',
  INBOX: 'erlang-solutions.com:xmpp:inbox:0',
  INBOX_CONVERSATION: 'erlang-solutions.com:xmpp:inbox:0#conversation'
};
