import React from 'react';
import { NavLink } from 'react-router-dom';

import { Icon } from '@monorepo/icons';

import { TMenuItem } from '../../../types';
import Count from '../../Count';
import FlexBox from '../../FlexBox';

import styles from './index.module.css';

const NavigationItem = ({
  item,
  linkClassName = '',
  itemClassName = '',
  activeClassName = '',
  isExact = false,
  replace = false,
  active = () => true
}: {
  item: TMenuItem;
  linkClassName?: string;
  itemClassName?: string;
  activeClassName?: string;
  isExact?: boolean;
  replace?: boolean;
  active?: (link: string) => boolean;
}) => {
  const { title, link, iconName, onlyIcon = false, count = 0 } = item;

  return (
    <FlexBox key={title} className={`${styles.item} ${itemClassName}`}>
      <NavLink
        end={isExact}
        to={link}
        replace={replace}
        className={({ isActive }) =>
          `${linkClassName} ${
            isActive && active(link)
              ? `${styles.active} ${activeClassName}`
              : ''
          }`
        }
      >
        {iconName && <Icon name={iconName} className={styles.icon} />}
        {!onlyIcon && title}
        {Boolean(count) && <Count count={count} className={styles.count} />}
      </NavLink>
    </FlexBox>
  );
};

export default NavigationItem;
