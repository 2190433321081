import React from 'react';
import { Link } from 'react-router-dom';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { useSelectChatOpen } from '@monorepo/auth';
import { InnerHTMLSnippet } from '@monorepo/cms';
import { FooterNavigationMenu } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import PaymentsList from './components/PaymentsList';

import styles from './index.module.css';

const LICENSE_LINK =
  'https://cert.gcb.cw/certificate?id=ZXlKcGRpSTZJbEozY2trclYybElRM1p1UmsxU05YQm1ReXRETVdjOVBTSXNJblpoYkhWbElqb2ljakJYUm5oa05YWlJWV0ZKYW5GcVpqbFVVVXRsWnowOUlpd2liV0ZqSWpvaVlqRmxaV1E0WXpnNE5EQmlNVGMxWVRNNU5ERTVZVGMxTVdRelpUWTFOVGd4T1Rnek5HSmxaRGs1WlRneU9XTTNZMkl5WkdVME5qWmhOR1UwWW1FM1pDSXNJblJoWnlJNklpSjk=';

type FooterProps = {
  menuId: number;
};
const Footer: ComponentWithConfig<FooterProps> = ({ config }) => {
  const { t } = useI18n(NAMESPACES.COMMON);
  const { isDesktop } = useDeviceDetector();
  const showPaymentBlock = true;
  const { menuId } = config;

  const isOpen = useSelectChatOpen();
  return !isOpen || isDesktop ? (
    <Box className={styles.wrap}>
      <PaddingWrapper>
        <Box>
          <FooterNavigationMenu menuId={menuId} />
          <Box className={styles.regulation}>
            <Box className={styles.regulationTxt}>
              {t('footer.regulation-and-partners')}
            </Box>
            <Box className={styles.regulationImg}>
              <a href={LICENSE_LINK} target="_blank" rel="noreferrer">
                <img
                  src="/images/image-395.png"
                  alt="cert.gcb.cw"
                  className={styles.licenseLogo}
                />
              </a>
            </Box>
          </Box>
          <Box className={styles.bottom}>
            <InnerHTMLSnippet className={styles.main} html={t('footer.main')} />
            <FlexBox
              direction={FLEX_DIRECTION.row}
              align={FLEX_ALIGN.center}
              justify={FLEX_JUSTIFY.center}
              className={styles.address}
            >
              <Link to={`tel:${t('footer.phone')}`} className={styles.link}>
                {t('footer.phone')}
              </Link>
              &nbsp;
              <Link to={`mailto:${t('footer.email')}`} className={styles.link}>
                {t('footer.email')}
              </Link>
            </FlexBox>
            <InnerHTMLSnippet
              className={styles.license}
              html={t('footer.license')}
            />
            <FlexBox
              align={FLEX_ALIGN.center}
              justify={FLEX_JUSTIFY.center}
              className={styles.age}
            >
              <Icon name="age-18" className={styles.icon} />
              <InnerHTMLSnippet html={t('footer.18plus')} />
            </FlexBox>
            <InnerHTMLSnippet
              className={styles.limits}
              html={t('footer.limits')}
            />
          </Box>
        </Box>
      </PaddingWrapper>
      {showPaymentBlock && (
        <PaddingWrapper className={styles.listWrap}>
          <FlexBox
            justify={isDesktop ? FLEX_JUSTIFY.center : FLEX_JUSTIFY.start}
          >
            <PaymentsList className={styles.list} />
          </FlexBox>
        </PaddingWrapper>
      )}
      <PaddingWrapper className={styles.copyright}>
        <FlexBox direction={FLEX_DIRECTION.column} align={FLEX_ALIGN.center}>
          <InnerHTMLSnippet html={t('footer.copyright')} />
        </FlexBox>
      </PaddingWrapper>
    </Box>
  ) : null;
};

export default Footer;
