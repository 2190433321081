import { VALIDATION_ERROR_CODES } from '@monorepo/auth';

export const FIELD_TO_ERROR_MAP = {
  [VALIDATION_ERROR_CODES.DUPLICATE_EMAIL]: 'email',
  [VALIDATION_ERROR_CODES.DUPLICATE_PHONE]: 'phone',
  [VALIDATION_ERROR_CODES.DUPLICATE_LOGIN]: 'username',
  [VALIDATION_ERROR_CODES.PASSWORD_SAME_AS_LOGIN]: 'password',
  [VALIDATION_ERROR_CODES.NICKNAME_EXIST]: 'nickName',
  [VALIDATION_ERROR_CODES.INCORRECT_OLD_PASSWORD]: 'password',
  [VALIDATION_ERROR_CODES.INCORRECT_OLD_PASSWORD_2]: 'password',
  [VALIDATION_ERROR_CODES.INCORRECT_PASSWORD]: 'password'
};
