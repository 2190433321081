import React from 'react';

import { Component } from '@monorepo/type';

import { useLineGamesData } from '../../hooks/useGetLineGames';
import { selectGameBySportIdRegionIdCompetitionIdGameId } from '../../store/api/selectors';
import GameLiveInfo from '../GameLiveInfo';

type Props = {
  sportId: number;
  regionId: number;
  competitionId: number;
  gameId: number;
  date: number;
  team2Name?: string;
  showType?: string;
  withFullScoreFormat?: boolean;
};

const LineGameLiveInfo: Component<Props> = ({
  className = '',
  sportId,
  regionId,
  competitionId,
  gameId,
  date,
  team2Name,
  showType,
  withFullScoreFormat
}) => {
  const { data: gameLiveInfo } = useLineGamesData((result) =>
    selectGameBySportIdRegionIdCompetitionIdGameId(
      result,
      sportId,
      regionId,
      competitionId,
      gameId,
      ['info', 'stats', 'sport_alias']
    )
  );
  const { info: gameInfo, stats = {}, sportAlias } = gameLiveInfo || {};

  return (
    <GameLiveInfo
      team2Name={team2Name}
      date={date}
      withFullScoreFormat={withFullScoreFormat}
      gameInfo={gameInfo}
      stats={stats}
      showType={showType}
      sportAlias={sportAlias}
      className={className}
    />
  );
};

export default LineGameLiveInfo;
