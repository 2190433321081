import { SPORTS_QUERY_KEYS } from '../../../constants';
import { TBetInfoRequest } from '../../../types';
import { normalizeBetInfoResponse } from '../adapters/betInfoAdapter';

const getBetInfo = () => ({
  query: ({ id }: TBetInfoRequest) => ({
    body: {
      command: 'check_bet_status',
      params: {
        bet_id: id
      }
    }
  }),
  providesTags: [SPORTS_QUERY_KEYS.BET_INFO],
  transformResponse: normalizeBetInfoResponse
});

export default getBetInfo;
