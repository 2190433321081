import { camelizeKeys } from 'humps';

import { getSearchString } from '@monorepo/helpers';

const getBlogs = () => ({
  query: ({
    tag,
    offset,
    limit,
    orderBy,
    sort,
    siteId
  }: {
    tag: string;
    limit: number;
    offset: number;
    orderBy: string;
    sort: string;
    siteId: string | number;
  }) => {
    const searchParams = getSearchString({
      offset,
      limit,
      order_by: orderBy,
      sort,
      tag,
      partner_id: siteId
    });
    return {
      url: `/posts${searchParams.length > 0 ? searchParams : ''}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
  },
  keepUnusedDataFor: 0,
  transformResponse: (response: any): string[] => {
    const list = response.list.map((item: any) => camelizeKeys(item));
    return { list, total_count: response.total_count };
  }
});

export default getBlogs;
