import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { useGetChildSession } from '@monorepo/auth';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Box,
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FLEX_DIRECTION,
  FlexBox,
  Modal,
  ModalWrap,
  Portal,
  TShutterHandler
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TSwitchProposal = {
  currencyId?: string;
  onClose: () => void;
};

const SwitchProposal: ForwardRefRenderFunction<
  TShutterHandler,
  TSwitchProposal
> = ({ currencyId, onClose }, ref) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { onGetChildSession } = useGetChildSession();

  const handleClose = () => {
    onClose();
  };
  const handleAcceptTemp = async () => {
    await onGetChildSession(currencyId);
    handleClose();
  };

  return (
    <Portal>
      <Modal ref={ref} isHasOverlay>
        <ModalWrap handleClose={handleClose} header={t('wallet.switch.info')}>
          <Box>
            <div className={styles.text}>
              {t('wallet.new.wallet', { wallet: currencyId })}
            </div>
            <FlexBox
              direction={FLEX_DIRECTION.column}
              className={styles.control}
            >
              <Button
                size={BUTTON_SIZE.h30}
                className={styles.button}
                onClick={() => handleAcceptTemp()}
              >
                {t('wallet.confirm.button')}
              </Button>
              <Button
                size={BUTTON_SIZE.h30}
                className={styles.button}
                intent={BUTTON_INTENT.secondary}
                onClick={handleClose}
              >
                {t('wallet.switch.cancel')}
              </Button>
            </FlexBox>
          </Box>
        </ModalWrap>
      </Modal>
    </Portal>
  );
};

export default forwardRef(SwitchProposal);
