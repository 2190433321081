import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const BankIdVerification = lazy(() => import('./BankIdVerification'));

export default () => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BankIdVerification />
  </Suspense>
);
