import React, { FC } from 'react';

import {
  FAVORITE_TYPE,
  FavoriteButton,
  LIKE_TYPE,
  LikeButton
} from '@monorepo/common';
import { formatUnixTime } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';
import { useComponentsConfig } from '@monorepo/page';
import {
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import { useLineGamesData } from '../../../../hooks/useGetLineGames';
import { useStreamsData } from '../../../../hooks/useGetStreams';
import { selectGameBySportIdRegionIdCompetitionIdGameId } from '../../../../store/api/selectors';
import { getStreamById } from '../../../../store/api/selectors/streamsByIdSelector';
import { TGameEvent } from '../../../../types';
import CommentButton from '../../../CommentButton';
import LineGameLiveInfo from '../../../LineGameLiveInfo';

import styles from './index.module.css';

type TGeneralInfoBlock = {
  gameLink: string;
  sportId: number;
  regionId: number;
  competitionId: number;
  gameId: number;
};

const GeneralInfoBlock: FC<TGeneralInfoBlock> = ({
  gameLink,
  sportId,
  regionId,
  competitionId,
  gameId
}) => {
  const { withStream, withComment, withFavorite, withLike } =
    useComponentsConfig<{
      withStream?: boolean;
      withComment?: boolean;
      withFavorite?: boolean;
      withLike?: boolean;
    }>();

  const { data: game } = useLineGamesData((result) =>
    selectGameBySportIdRegionIdCompetitionIdGameId(
      result,
      sportId,
      regionId,
      competitionId,
      gameId,
      ['id', 'markets_count', 'start_ts', 'is_live', 'show_type', 'team2_name']
    )
  );
  const {
    id,
    marketsCount,
    startTs,
    isLive,
    isStatAvailable,
    showType = '',
    team2Name = ''
  } = game;

  const { data: streams } = useStreamsData<TGameEvent[] | undefined>((result) =>
    getStreamById(result, id)
  );

  return (
    <FlexBox
      className={styles.generalInfo}
      direction={FLEX_DIRECTION.column}
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
    >
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        className={styles.timeWrap}
      >
        <span className={styles.gameTime}>
          {isLive
            ? formatUnixTime(startTs, 'HH:mm')
            : formatUnixTime(startTs, 'dd.MM.yyyy | HH:mm')}
        </span>
        {withStream && !!streams && (
          <Icon name="videocam" className={styles.streamIcon} />
        )}
        {Boolean(marketsCount) && (
          <span className={styles.count}>{`+${marketsCount}`}</span>
        )}
        {withLike && (
          <LikeButton
            id={id}
            type={LIKE_TYPE.SPORTSBOOK}
            size="medium"
            className={styles.action}
          />
        )}
        {withComment && (
          <CommentButton
            id={id}
            size="medium"
            className={styles.action}
            link={gameLink}
            hasStatistics={isStatAvailable}
          />
        )}
        {withFavorite && (
          <FavoriteButton
            id={id}
            type={FAVORITE_TYPE.GAME}
            size="medium"
            className={styles.action}
            dataCustom="event-favorite"
          />
        )}
      </FlexBox>
      {Boolean(isLive) && (
        <FlexBox justify={FLEX_JUSTIFY.center} className={styles.liveWrap}>
          <LineGameLiveInfo
            team2Name={team2Name}
            sportId={sportId}
            regionId={regionId}
            competitionId={competitionId}
            gameId={gameId}
            date={startTs}
            showType={showType}
            withFullScoreFormat
          />
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default GeneralInfoBlock;
