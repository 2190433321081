import React from 'react';

import { CustomFC } from '@monorepo/type';

import { FIELD_TONE } from '../../constants';
import { FlexBoxProps } from '../../types';
import FlexBox from '../FlexBox';

import styles from './index.module.css';

type Props = {
  toneType: FIELD_TONE;
  isWithNext?: boolean;
  isWithPrev?: boolean;
  isWithButtons?: boolean;
} & FlexBoxProps;

const SliderShadows: CustomFC<Props> = ({
  children,
  className = '',
  isWithNext = false,
  isWithPrev = false,
  isWithButtons = false,
  toneType,
  ...props
}) => {
  const classes = `${styles.wrapper}
   ${isWithButtons && isWithPrev ? styles.withPrev : ''}
   ${isWithButtons && isWithNext ? styles.withNext : ''}
   ${styles[toneType]}
   ${className}`;

  return (
    <FlexBox className={classes} {...props}>
      {children}
    </FlexBox>
  );
};

export default SliderShadows;
