import React from 'react';
import { NavLink } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { SvgIcon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { EllipsisText } from '@monorepo/ui-kit';

import styles from './index.module.css';

const HeaderPromo = ({ className = '' }) => {
  const { generalRoutes } = useSiteSettings();
  const { promoAll } = generalRoutes || {};
  const { t } = useI18n(NAMESPACES.COMMON);
  return (
    <NavLink className={`${styles.link} ${className}`} to={promoAll}>
      <SvgIcon name="promo" className={styles.icon} />
      <EllipsisText className={styles.title}>
        {t('navigation.promo')}
      </EllipsisText>
    </NavLink>
  );
};
export default HeaderPromo;
