import { createApi } from '@reduxjs/toolkit/query/react';

import { wsBaseQuery } from '@monorepo/websocket';

import { TOURNAMENTS_QUERY_KEYS } from '../constants';
import {
  TBaseTournament,
  TGetTournamentsListRequest,
  TJoinTournament,
  TTournamentItem,
  TWinnersList,
  TWinnersRequest
} from '../types';

import getLastWinners from './api/getLastWinners';
import getTopWinners from './api/getTopWinners';
import getTournament from './api/getTournament';
import getTournaments from './api/getTournaments';
import joinToTournament from './api/joinToTournament';

export const tournamentsApi = createApi({
  reducerPath: 'tournamentsApi',
  baseQuery: wsBaseQuery(),
  tagTypes: [
    TOURNAMENTS_QUERY_KEYS.TOURNAMENT,
    TOURNAMENTS_QUERY_KEYS.TOURNAMENT_LIST
  ],
  endpoints: (builder) => ({
    getTournaments: builder.query<
      TBaseTournament[],
      TGetTournamentsListRequest
    >(getTournaments()),
    getTournament: builder.query<TBaseTournament | {}, TTournamentItem>(
      getTournament()
    ),
    joinToTournament: builder.mutation<TJoinTournament | {}, TTournamentItem>(
      joinToTournament()
    ),
    getTopWinners: builder.query<TWinnersList, TWinnersRequest>(
      getTopWinners()
    ),
    getLastWinners: builder.query<TWinnersList, TWinnersRequest>(
      getLastWinners()
    )
  })
});

export const {
  useJoinToTournamentMutation,
  useGetLastWinnersQuery,
  useGetTopWinnersQuery,
  useGetTournamentsQuery,
  useGetTournamentQuery
} = tournamentsApi;
