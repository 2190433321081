const COMPONENT_TYPES = {
  FOOTER_USER_MENU: 'footer-navigation',
  FAVORITE_WALL: 'wall-favorite',
  FAVORITE_VIDEO: 'video-favorite',
  CONTENT_PAGE: 'content-page',
  PROMOTIONS_LIST: 'promotions-list',
  PROMOTIONS_HEADER: 'promotions-header',
  PROMOTIONS_SLOTS_LIST: 'promotions-slots-list',
  PROMOTION: 'promotion',
  PROMO: 'promo',
  PROMOTION_SLOTS: 'promotion-slots',
  PROMOTIONS_CATEGORIES_FILTER: 'promotions-categories-filter',
  PROMO_CATEGORIES_FILTER: 'promo-categories-filter'
};

export default COMPONENT_TYPES;
