import { useSelectJwtAuth } from '@monorepo/auth';

import { useGetPlayerInfoQuery } from '../../../store/customApiStore';

// For purposes when we need access to player information from the new API
const useGetPlayerInformation = (options = {}) => {
  const { skip, ...rest } = options;
  const jwt = useSelectJwtAuth();

  return useGetPlayerInfoQuery(
    { jwt },
    {
      skip: !jwt || skip,
      ...rest
    }
  );
};

export default useGetPlayerInformation;
