import React from 'react';

import { InnerHTMLSnippet } from '@monorepo/cms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { CustomFC, UseCountry } from '@monorepo/type';
import {
  Box,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  PaddingWrapper
} from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../constants';
import usePaymentService from '../../../hooks/usePaymentService';
import DepositForm from '../DepositForm';
import NoPaymentSystem from '../NoPaymentSystem';
import PaymentImage from '../PaymentImage';
import PaymentInfo from '../PaymentInfo';

import styles from './index.module.css';

type Props = {
  id: string;
  imageFromCms?: boolean;
  isWithProfileCheck?: boolean;
  countriesFromJSON: UseCountry[];
  defaultCountry: string;
  quickDeposit?: boolean;
};

const MobileDepositMenu: CustomFC<Props> = ({
  id,
  imageFromCms = false,
  isWithProfileCheck = false,
  countriesFromJSON,
  defaultCountry,
  quickDeposit = false
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const { system } = usePaymentService(SERVICE_TYPES.DEPOSIT, id);

  if (!system) {
    return <NoPaymentSystem />;
  }

  const { displayName, PaymentSystemIconUrl: imgSrc } = system;

  return (
    <PaddingWrapper>
      <Box>
        <FlexBox
          className={styles.paymentWrapper}
          direction={FLEX_DIRECTION.column}
        >
          <FlexBox
            direction={FLEX_DIRECTION.column}
            align={FLEX_ALIGN.center}
            justify={FLEX_JUSTIFY.around}
            className={styles.itemWrapper}
          >
            <PaymentImage
              imgSrc={imgSrc}
              displayName={displayName}
              imageFromCms={imageFromCms}
              serviceId={id}
            />
            <p className={styles.itemWrapperText}>{displayName}</p>
          </FlexBox>
          <Box className={styles.description}>
            {t('wallet.deposit_message')}
          </Box>

          <PaymentInfo
            serviceId={id}
            serviceType={SERVICE_TYPES.DEPOSIT}
            className={styles.limitsWrapper}
          />
        </FlexBox>
        <Box className={styles.subscription}>
          <InnerHTMLSnippet html={t(`wallet.deposit.description.${id}`)} />
        </Box>
        <DepositForm
          serviceId={id}
          isWithProfileCheck={isWithProfileCheck}
          countriesFromJSON={countriesFromJSON}
          defaultCountry={defaultCountry}
          quickDeposit={quickDeposit}
        />
      </Box>
    </PaddingWrapper>
  );
};

export default MobileDepositMenu;
