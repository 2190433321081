import { useTypedSelector } from '@monorepo/redux';

import messengerSlice from '../../../store/V1/messengerSlice';

export const getActiveChat = (state: any) =>
  state[messengerSlice.name].activeChat;

const useSelectActiveChat = (): null | string =>
  useTypedSelector(getActiveChat);

export default useSelectActiveChat;
