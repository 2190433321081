import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const BellNotification = lazy(() => import('./BellNotification'));

export default () => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BellNotification />
  </Suspense>
);
