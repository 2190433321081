/* eslint-disable prefer-object-spread,no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const recaptchaSlice = createSlice({
  name: 'recaptcha',
  initialState: {
    isRecaptchaValidated: false
  },
  reducers: {
    setValidationRecaptcha: (state, action) => {
      state.isRecaptchaValidated = action.payload;

      return state;
    }
  }
});

export const { actions } = recaptchaSlice;
export default recaptchaSlice;
