import React from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { useComponentsConfig } from '@monorepo/page';
import { Component } from '@monorepo/type';
import { FLEX_DIRECTION, FlexBox, ScrollBarCustom } from '@monorepo/ui-kit';

import FavoriteItem from './components/FavoriteItem';
import FavoriteTeam from './components/FavoriteTeam';
import PopularItem from './components/PopularItem';
import SportItemsList from './components/SportItemsList';

import styles from './index.module.css';

type TSportsMenu = {
  activeSport: string;
  setSport: (sportAlias: string) => void;
  region?: string;
  rootPath: string;
};

const SportsMenu: Component<TSportsMenu> = ({
  setSport,
  activeSport,
  region,
  rootPath
}) => {
  const { isAuthenticated } = useIsAuthenticated();

  const { isWithFavoriteTeam, isWithPopular, isWithFavorite } =
    useComponentsConfig<{
      isWithFavoriteTeam?: boolean;
      isWithPopular?: boolean;
      isWithFavorite?: boolean;
    }>();

  return (
    <FlexBox className={styles.root} direction={FLEX_DIRECTION.column}>
      <ScrollBarCustom isOnlyDesktop props={{ noScrollX: true }}>
        {isWithFavorite && isAuthenticated && (
          <FavoriteItem
            activeSport={activeSport}
            setSport={setSport}
            className={styles.item}
          />
        )}
        {isWithPopular && (
          <PopularItem
            activeSport={activeSport}
            className={styles.item}
            region={region}
            rootPath={rootPath}
          />
        )}
        {isWithFavoriteTeam && isAuthenticated && <FavoriteTeam />}
        <SportItemsList
          setSport={setSport}
          activeSport={activeSport}
          itemClassName={styles.item}
          noResultClassName={styles.noResults}
          region={region}
          rootPath={rootPath}
        />
      </ScrollBarCustom>
    </FlexBox>
  );
};

export default SportsMenu;
