import { AUTH_QUERY_KEYS } from '@monorepo/auth/src/constants';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import placeBetAdapter from '../adapters/placeBetAdapter';

const placeBet = () => ({
  query: ({ type, mode, amount, bets, bonusId, gRecaptchaResponse }: any) => ({
    body: {
      command: 'do_bet',
      params: {
        type,
        mode,
        amount: Number(amount),
        bets,
        g_recaptcha_response: gRecaptchaResponse,
        ...(bonusId && { bonus_id: bonusId })
      }
    },
    checkError: (response: any) =>
      `${response?.data?.result}`.toLowerCase() !== 'ok' ? response : false
  }),
  transformResponse: (response: any) => placeBetAdapter(response),
  invalidatesTags: [
    AUTH_QUERY_KEYS.USER,
    SPORTS_QUERY_KEYS.BETS_COUNT,
    SPORTS_QUERY_KEYS.BETS
  ]
});
export default placeBet;
