import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';
import { Swiper } from 'swiper';

import { useDebounce } from '@monorepo/helpers';
import { Icon } from '@monorepo/icons';

import { BUTTON_INTENT } from '../../../constants';
import Box from '../../Box';
import Button from '../../Button';
import Carousel from '../../Carousel';
import FlexBox from '../../FlexBox';

import styles from './index.module.css';

type TCarouselNavigation = {
  slides: any[];
  params: Record<string, any>;
  wrapClassName?: string;
  slideClassName?: string;
  useSwiper?: boolean;
  isWithButtons?: boolean;
  activeIndex?: number;
  buttonClassName?: string;
  className?: string;
};
const CarouselNavigation = ({
  slides = [],
  params = {},
  className = '',
  wrapClassName = '',
  slideClassName = '',
  buttonClassName = '',
  useSwiper = true,
  isWithButtons = true,
  activeIndex = 0
}: TCarouselNavigation) => {
  const { isDesktop } = useDeviceDetector();
  const sliderRef = useRef<Swiper | null>(null);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const onNext = useCallback(() => {
    if (sliderRef) {
      sliderRef?.current?.slideNext();
    }
  }, [sliderRef.current]);

  const onPrev = useCallback(() => {
    if (sliderRef?.current) {
      sliderRef?.current?.slidePrev();
    }
  }, [sliderRef.current]);

  const onSlideChange = useDebounce((swiper: Swiper) => {
    setIsStart(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, 500);

  useEffect(() => {
    if (sliderRef.current) {
      const { isBeginning, isEnd: isLastSlide } = sliderRef.current;
      if (isLastSlide && isBeginning) {
        setIsStart(true);
        setIsEnd(true);
      }
    }
  }, [sliderRef.current]);

  const allParams = useMemo(
    () => ({
      onSwiper: (ref: Swiper) => {
        sliderRef.current = ref;
        if (activeIndex) {
          ref.slideTo(activeIndex, 0);
        }
      },
      onSlideChange,
      onReachEnd: () => {
        setIsStart(false);
        setIsEnd(true);
      },
      onReachBeginning: () => {
        setIsStart(true);
        setIsEnd(false);
      },
      ...params,
      // allowTouchMove: !isDesktop,
      simulateTouch: !isDesktop,
      freeMode: true
    }),
    []
  );
  return (
    <Box className={`${styles.root} ${className}`}>
      <Carousel
        slides={slides}
        className={wrapClassName}
        slideClassName={slideClassName}
        useSwiper={useSwiper}
        params={allParams}
      />
      {isWithButtons ? (
        <FlexBox className={styles.buttons}>
          <Button
            onClick={onPrev}
            isOnlyIcon
            intent={BUTTON_INTENT.tertiary}
            disabled={isStart}
            className={`${styles.button} ${styles.prev} ${buttonClassName}`}
          >
            <Icon name="close_1" />
          </Button>
          <Button
            onClick={onNext}
            isOnlyIcon
            intent={BUTTON_INTENT.tertiary}
            disabled={isEnd}
            className={`${styles.button} ${styles.next} ${buttonClassName}`}
          >
            <Icon name="close_1" />
          </Button>
        </FlexBox>
      ) : null}
    </Box>
  );
};

export default CarouselNavigation;
