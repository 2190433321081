import React from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import useChangeSeparator from '../../hooks/useChangeSeparator';
import { TFormField } from '../../types';
import MaskedField from '../MaskedField';

type AmountProps<TFormValues> = {
  errorClassName?: string;
  inputClassName?: string;
  maxLength?: number;
  dataCustom?: string;
} & TFormField<TFormValues>;

const AmountField = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  errorClassName = '',
  inputClassName = '',
  maxLength,
  dataCustom = '',
  ...props
}: AmountProps<TFormValues>) => {
  const { amountMaskOptions } = useChangeSeparator();
  const mask = createNumberMask(amountMaskOptions);
  const { isAndroid } = useDeviceDetector();
  return (
    <MaskedField<TFormValues>
      mask={mask}
      step="0.01"
      inputMode={isAndroid ? 'text' : 'decimal'}
      maxLength={maxLength}
      inputClassName={inputClassName}
      errorClassName={errorClassName}
      dataCustom={dataCustom}
      {...props}
    />
  );
};

export default AmountField;
