import { TGetSearchPlayerArgs } from '../../../../types';

const getSearchPlayers = () => ({
  query: ({ search, jwt }: TGetSearchPlayerArgs) => ({
    url: '/search/player',
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    },
    body: {
      nickname: search
    }
  }),
  transformResponse: (response: any) => {
    if (!response || typeof response === 'string') {
      return [];
    }
    return Array.isArray(response) ? response : [response];
  }
});

export default getSearchPlayers;
