import { useEffect } from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

const useDisableZoom = () => {
  const { isIOS } = useDeviceDetector();

  useEffect(() => {
    const listener = (e) => {
      if (isIOS) e.preventDefault();
    };
    document.addEventListener('gesturestart', listener);
    return () => {
      document.removeEventListener('gesturestart', listener);
    };
  }, [isIOS]);
};

export default useDisableZoom;
