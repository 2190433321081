import { useTypedSelector } from '@monorepo/redux';

import { userSlice } from '../../../store';

const getJwtAuthToken = (state: any) => state[userSlice.name].jwtAuthToken;

const useSelectJwtAuthToken = (): string | null =>
  useTypedSelector(getJwtAuthToken);

export default useSelectJwtAuthToken;
