import { useSiteSettings } from '@monorepo/site-settings';
import { TQueryResult } from '@monorepo/type';

import { TGetSportsRequest, TNormalizedSports } from '../../types';
import useGetData from '../useGetData';
import useGetSports from '../useGetSports';
import useSportsParams from '../useSportsParams';

const useGetMenuSports = (
  args: Partial<TGetSportsRequest> = {},
  options = {}
) => {
  const { languages } = useSiteSettings();
  // custom hook for future purposes of reading parameters both from url and from the LineProvider context
  const { gameType, time, search, competitionIds, gameIds } = useSportsParams();
  return useGetSports(
    {
      gameType,
      time,
      search,
      competitions: competitionIds,
      gameIds,
      languages,
      ...args
    },
    options
  );
};

export const useMenuSportsData = <
  ReturnType = any,
  QueryResultType = TNormalizedSports
>(
  dataSelector: (result: TQueryResult<QueryResultType>) => any,
  params: Partial<TGetSportsRequest> = {}
) =>
  useGetData<ReturnType, QueryResultType>(
    useGetMenuSports,
    dataSelector,
    params
  );

export default useGetMenuSports;
