import get from 'lodash/get';

import { TNormalizedPopularCompetitions } from '../../../types';

export const normalizePopularCompetitionsResponse = (
  rawResponse: Record<string, any>
): TNormalizedPopularCompetitions => {
  const sport = get(rawResponse, 'data.sport', {});
  const subid = get(rawResponse, 'subid', '');
  return {
    sport,
    subid
  };
};
