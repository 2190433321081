import { TOOLTIP_ID } from '../../constants';
import { TTooltipData } from '../../types';

const defaultArgs = {
  id: TOOLTIP_ID.DEFAULT,
  place: 'bottom',
  variant: 'light',
  delayShow: 1000
};

const convertKeysToDataAttributes = (key: keyof TTooltipData) => {
  if (key === 'delayShow') {
    return 'data-tooltip-delay-show';
  }
  if (key === 'delayHide') {
    return 'data-tooltip-delay-hide';
  }
  if (key === 'positionStrategy') {
    return 'data-tooltip-position-strategy';
  }
  if (key === 'className') {
    return 'data-tooltip-class-name';
  }

  return `data-tooltip-${key}`;
};

const getTooltipDataAttributes = (args: TTooltipData = {}) =>
  Object.entries({ ...defaultArgs, ...args }).reduce(
    (acc: TTooltipData, [key, value]) => ({
      ...acc,
      [convertKeysToDataAttributes(key as keyof TTooltipData)]: value
    }),
    {}
  );

export default getTooltipDataAttributes;
