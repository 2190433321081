import { TQueryResult } from '@monorepo/type';

import { TGetMarketsRequest, TNormalizedLineMarkets } from '../../types';
import useGetData from '../useGetData';
import useGetMarkets from '../useGetMarkets';
import useLineParameters from '../useLineParameters';

const useGetLineMarkets = (
  args: Partial<TGetMarketsRequest> = {},
  options = {}
) => {
  const { gameType, competitionId, time, isPopular, marketType, gameIds } =
    useLineParameters();

  return useGetMarkets(
    { gameType, competitionId, time, isPopular, marketType, gameIds, ...args },
    options
  );
};

export const useLineMarketsData = <
  ReturnType = any,
  QueryResultType = TNormalizedLineMarkets
>(
  dataSelector: (result: TQueryResult<QueryResultType>) => any,
  queryArgs: Partial<TGetMarketsRequest> = {},
  queryOptions: Record<string, any> = {}
) =>
  useGetData<ReturnType, QueryResultType>(
    useGetLineMarkets,
    dataSelector,
    queryArgs,
    queryOptions
  );

export default useGetLineMarkets;
