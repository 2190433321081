import entries from 'lodash/entries';
import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import values from 'lodash/values';

import { useIsAuthenticated } from '@monorepo/auth';
import { useSiteSettings } from '@monorepo/site-settings';

import {
  PAYMENT_GROUP_ALL,
  PAYMENT_GROUP_OTHER,
  SERVICE_TYPES
} from '../../constants';
import {
  useGetPaymentServicesListQuery,
  useGetPaymentServicesQuery
} from '../../store/wallet';
import { TGetPaymentServicesList, TPaymentServices } from '../../types';

const getPaymentServicesList = (
  data: TGetPaymentServicesList | undefined,
  serviceType: SERVICE_TYPES,
  availablePaymentSystems?: string[]
): string[] => {
  const services = (data?.[serviceType] || []).map(String);

  if (availablePaymentSystems?.length) {
    return services.filter((service) =>
      availablePaymentSystems.includes(service)
    );
  }

  return services;
};

const getPaymentsGroup = (
  data: TPaymentServices | undefined,
  serviceType: SERVICE_TYPES,
  list: string[]
) => {
  if (!data) {
    return {
      [PAYMENT_GROUP_ALL]: list,
      groupKeys: [PAYMENT_GROUP_ALL]
    };
  }
  const services = data[serviceType];
  const groups = reduce(
    entries(
      groupBy(
        filter(
          map(list, (id) =>
            find(
              values(services),
              (item) => get(item, 'PaymentSystem_id') === id
            )
          ),
          Boolean
        ),
        'paymentGroup'
      )
    ),
    (acc, [key, items]) => {
      const groupKey =
        !key || key === '[object Object]' ? PAYMENT_GROUP_OTHER : key;

      return {
        ...acc,
        [groupKey]: map(items, (item) => get(item, 'PaymentSystem_id'))
      };
    },
    {}
  );

  const groupKeys = keys(groups);
  return {
    [PAYMENT_GROUP_ALL]: list,
    groupKeys: [PAYMENT_GROUP_ALL, ...groupKeys],
    ...groups
  };
};

const usePaymentServicesGroups = (serviceType: SERVICE_TYPES) => {
  const { isAuthenticated } = useIsAuthenticated();
  const { availablePaymentSystems } = useSiteSettings();
  const { isFetching, list } = useGetPaymentServicesListQuery(undefined, {
    skip: !isAuthenticated,
    selectFromResult: (result) => ({
      isFetching: result?.isFetching,
      list: getPaymentServicesList(
        result?.data,
        serviceType,
        availablePaymentSystems
      )
    })
  });
  const { data, isFetching: isFetchingServices } = useGetPaymentServicesQuery(
    undefined,
    {
      skip: !list?.length,
      selectFromResult: (result) => ({
        isFetching: result?.isFetching,
        data: getPaymentsGroup(result?.data, serviceType, list)
      })
    }
  );

  return {
    isFetching: isFetching || isFetchingServices,
    isFetchingServices,
    data
  };
};

export default usePaymentServicesGroups;
