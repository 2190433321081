import { TQueryResult } from '@monorepo/type';

import { useGetRegionsQuery } from '../../store/sportsbook';
import { TGetRegionsRequest, TNormalizedRegions } from '../../types';
import useGetData from '../useGetData';

const useGetRegions = (args: TGetRegionsRequest, options = {}) =>
  useGetRegionsQuery(args, options);

export const useRegionsData = <
  ReturnType = any,
  QueryResultType = TNormalizedRegions,
  RestReturnedData = {}
>(
  dataSelector?: (result: TQueryResult<QueryResultType>) => any,
  queryArgs?: TGetRegionsRequest,
  queryOptions: Record<string, any> = {},
  restSelectors?: (result: TQueryResult<QueryResultType>) => Record<string, any>
) =>
  useGetData<ReturnType, QueryResultType, RestReturnedData, TGetRegionsRequest>(
    useGetRegions,
    dataSelector,
    queryArgs,
    queryOptions,
    restSelectors
  );

export default useGetRegions;
