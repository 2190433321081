import React, {
  forwardRef,
  ForwardRefRenderFunction,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { CSSTransition } from 'react-transition-group';

import { FIELD_TONE } from '../../constants';
import { TModalHandler, toneTypeProp } from '../../types';
import { ModalContext } from '../ModalProvider';

import styles from './index.module.css';

type Props = {
  id?: string;
  classOverlay?: string;
  classContent?: string;
  isFullWidth?: boolean;
  isWithHeader?: boolean;
  isHasOverlay?: boolean;
  closeOutside?: () => void;
  alwaysCenter?: boolean;
  children: React.ReactNode;
} & toneTypeProp;

const Modal: ForwardRefRenderFunction<TModalHandler, Props> = (
  {
    id = 'modal',
    classOverlay = '',
    classContent = '',
    isFullWidth = false,
    isWithHeader = false,
    toneType = FIELD_TONE.light,
    isHasOverlay = false,
    closeOutside = () => {},
    alwaysCenter = false,
    children
  },
  ref
) => {
  const [isShow, setIsShow] = useState(false);
  const { enter, enterActive, enterDone, exit, exitActive, exitDone } = styles;
  const overlay = isHasOverlay
    ? `${styles.overlay} ${classOverlay}`
    : `${classOverlay}`;
  const shutterClasses = `${styles.shutter}
    ${isFullWidth ? styles.fullWidth : ''}
    ${isWithHeader ? styles.withHeader : ''}
    ${styles[toneType]}
    ${overlay}`;
  const { addLayer, removeLayer } = useContext(ModalContext);

  useImperativeHandle(ref, () => ({ setIsShow }));

  const closeModal = () => {
    closeOutside();
    setIsShow(false);
  };

  useEffect(() => {
    if (isShow) {
      addLayer(id);
    } else {
      removeLayer(id);
    }
  }, [isShow]);

  const refContent = useRef<HTMLDivElement>(null);
  const refWrap = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (refContent) {
      const contentHeight = refContent.current?.clientHeight || 0;
      const wrapHeight = refWrap.current?.clientHeight || 0;
      const topMargin = (wrapHeight - contentHeight) / 2;

      if (topMargin > 0) {
        setHeight(topMargin);
      }
    }
  }, [isShow, refContent]);

  return (
    <CSSTransition
      timeout={300}
      in={isShow}
      nodeRef={refWrap}
      unmountOnExit
      classNames={{
        enter,
        enterActive,
        enterDone,
        exit,
        exitActive,
        exitDone
      }}
    >
      <div className={shutterClasses} onClick={closeModal} ref={refWrap}>
        <div
          style={alwaysCenter ? {} : { marginTop: height }}
          ref={refContent}
          className={`${styles.content} ${classContent}`}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </CSSTransition>
  );
};

export default forwardRef(Modal);
