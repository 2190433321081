import AuthBanner from '../components/AuthBanner';
import BankIdVerification from '../components/BankIdVerification';
import BellNotifications from '../components/BellNotifications';
import Bonuses from '../components/Bonuses';
import BonusesExpendedCard from '../components/BonusExpendedCard';
import EditProfileChangePassword from '../components/EditProfileChangePassword';
import EditProfileContacts from '../components/EditProfileContacts';
import EditProfileMain from '../components/EditProfileMain';
import EditProfileMenu from '../components/EditProfileMenu';
import EditProfileNickname from '../components/EditProfileNickname';
import EditProfilePersonalData from '../components/EditProfilePersonalData';
import EditProfileVerifyAccount from '../components/EditProfileVerifyAccount';
import HeaderUserMenu from '../components/HeaderUserMenu';
import HeaderUserMenuUnAuthorized from '../components/HeaderUserMenuUnAuthorized';
import Limits from '../components/Limits';
import Login from '../components/Login';
import MessagePage from '../components/MessagePage';
import VerifyingEmail from '../components/NeedToVerifyEmail';
import ProfilePreferablesCustomList from '../components/PreferablesCustomList';
import Profile from '../components/Profile';
import ProfileDesktop from '../components/ProfileDesktop';
import ProfileVisibilityList from '../components/ProfileVisibilityList';
import Registration from '../components/Registration';
import RegistrationSuccess from '../components/RegistrationSuccess';
import RestorePassword from '../components/RestorePassword';
import RestorePasswordSuccess from '../components/RestorePasswordSuccess';
import RestorePasswordSetNew from '../components/SetNewPassword';
import TwoFactor from '../components/TwoFactor';
import VerifyEmailSuccess from '../components/VerifyEmail';

import COMPONENT_TYPES from './componentTypes';

const COMPONENTS_MAP = {
  [COMPONENT_TYPES.HEADER_USER_MENU]: HeaderUserMenu,
  [COMPONENT_TYPES.HEADER_USER_MENU_UNAUTHORIZED]: HeaderUserMenuUnAuthorized,
  [COMPONENT_TYPES.LOGIN]: Login,
  [COMPONENT_TYPES.RESTORE_PASSWORD_LOGIN]: RestorePassword,
  [COMPONENT_TYPES.RESTORE_PASSWORD_SET_NEW]: RestorePasswordSetNew,
  [COMPONENT_TYPES.RESTORE_PASSWORD_SUCCESS]: RestorePasswordSuccess,
  [COMPONENT_TYPES.REGISTRATION]: Registration,
  [COMPONENT_TYPES.REGISTRATION_SUCCESS]: RegistrationSuccess,
  [COMPONENT_TYPES.PROFILE]: Profile,
  [COMPONENT_TYPES.PROFILE_DESKTOP]: ProfileDesktop,
  [COMPONENT_TYPES.PROFILE_PREFERABLES_LIST]: ProfilePreferablesCustomList,
  [COMPONENT_TYPES.PROFILE_VISIBILITY_LIST]: ProfileVisibilityList,
  [COMPONENT_TYPES.EDIT_PROFILE_MENU]: EditProfileMenu,
  [COMPONENT_TYPES.EDIT_PROFILE_MAIN]: EditProfileMain,
  [COMPONENT_TYPES.EDIT_PROFILE_CONTACTS]: EditProfileContacts,
  [COMPONENT_TYPES.EDIT_PROFILE_PERSONAL_DATA]: EditProfilePersonalData,
  [COMPONENT_TYPES.EDIT_PROFILE_CHANGE_PASSWORD]: EditProfileChangePassword,
  [COMPONENT_TYPES.EDIT_PROFILE_VERIFY_ACCOUNT]: EditProfileVerifyAccount,
  [COMPONENT_TYPES.EDIT_PROFILE_NICKNAME]: EditProfileNickname,
  [COMPONENT_TYPES.EDIT_PROFILE_BANK_ID_VERIFICATION]: BankIdVerification,
  [COMPONENT_TYPES.LIMITS]: Limits,
  [COMPONENT_TYPES.AUTH_BANNER]: AuthBanner,
  [COMPONENT_TYPES.VERIFYING_EMAIL]: VerifyingEmail,
  [COMPONENT_TYPES.VERIFY_EMAIL_SUCCESS]: VerifyEmailSuccess,
  [COMPONENT_TYPES.USER_NOTIFICATIONS_MESSAGE]: MessagePage,
  [COMPONENT_TYPES.BELL_NOTIFICATION]: BellNotifications,
  [COMPONENT_TYPES.TWO_FACTOR]: TwoFactor,
  [COMPONENT_TYPES.BONUSES]: Bonuses,
  [COMPONENT_TYPES.BONUSES_EXPENDED]: BonusesExpendedCard
};

export default COMPONENTS_MAP;
