import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';

import { POPULAR_ALIAS } from '../../../../constants';
import { useCompetitionsWithSportsData } from '../../../../hooks/useGetCompetitionsWithSports';
import { selectAllGamesCount } from '../../../../store/api/selectors';
import SelectedSportMenu from '../../../SportNavigation/components/SelectedSportMenu';
import SportMenuItemAccordion from '../SportMenuItemAccordion';

type Props = {
  activeSport: string;
  region?: string;
  rootPath: string;
};

const PopularItem: Component<Props> = ({
  className = '',
  activeSport,
  region,
  rootPath
}) => {
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);

  const { data: allPopularGamesCount } = useCompetitionsWithSportsData<
    number | null
  >(
    (result) => selectAllGamesCount(result),
    { favorite: true },
    { refetchOnMountOrArgChange: false }
  );

  return (
    <SportMenuItemAccordion
      isActive={activeSport === POPULAR_ALIAS}
      iconName={POPULAR_ALIAS}
      name={t('sportsbook.popular')}
      count={allPopularGamesCount}
      className={className}
      alias=""
    >
      <SelectedSportMenu
        sport={POPULAR_ALIAS}
        region={region}
        rootPath={rootPath}
      />
    </SportMenuItemAccordion>
  );
};

export default PopularItem;
