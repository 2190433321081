import { useCallback } from 'react';

import { useToast } from '@monorepo/notification';

import { useRequestLogoutMutation } from '../../store/authLoginV2';
import { useLogoutMutation } from '../../store/user';
import { TLogoutRequest } from '../../types';
import useSelectAuthData from '../selectors/useSelectAuthData';
import useSelectJwtAuth from '../selectors/useSelectJwtAuth';

type TLogoutOptions = {
  onSuccess?: () => void;
};

const useLogout = (options: TLogoutOptions = {}) => {
  const { onSuccess } = options;
  const authData = useSelectAuthData();
  const jwt = useSelectJwtAuth();
  const [logoutMutation, { isLoading }] = useLogoutMutation();
  const [requestLogout] = useRequestLogoutMutation();
  const { hideAllToasts } = useToast();

  const handleSuccess = useCallback(() => {
    hideAllToasts();
    onSuccess?.();
    requestLogout({ jwt });
  }, [onSuccess, hideAllToasts, requestLogout, jwt]);

  const onLogout = useCallback(
    async (params?: TLogoutRequest) => {
      if (authData) {
        await logoutMutation(params);
        handleSuccess();
      } else {
        window.location.reload();
      }
    },
    [handleSuccess, logoutMutation, authData]
  );

  return { onLogout, isLoading };
};

export default useLogout;
