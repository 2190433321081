/* eslint-disable no-param-reassign */
import { TChangeLanguageRequest } from '../../../types';

const changeLanguage = (userApi) => ({
  query: ({ language }: TChangeLanguageRequest) => ({
    body: {
      command: 'set_preferred_language',
      params: {
        language
      }
    }
  }),
  async onQueryStarted(
    { language }: TChangeLanguageRequest,
    { dispatch, queryFulfilled }
  ) {
    await queryFulfilled;
    dispatch(
      userApi.util.updateQueryData('getUser', undefined, (draft) => {
        draft.language = language;
      })
    );
  }
});
export default changeLanguage;
