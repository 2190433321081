import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Icon } from '@monorepo/icons';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  EllipsisText,
  FIELD_TONE,
  FLEX_ALIGN,
  FlexBox
} from '@monorepo/ui-kit';

import { SERVICE_TYPES } from '../../../constants';
import usePaymentService from '../../../hooks/usePaymentService';

import styles from './index.module.css';

type TProps = {
  id: string;
  handleClick: () => void;
  serviceType: SERVICE_TYPES;
  toneType?: FIELD_TONE;
};
const CardBackHead: Component<TProps> = ({
  id,
  serviceType,
  handleClick,
  toneType = FIELD_TONE.light
}) => {
  const { system } = usePaymentService(serviceType, id);
  const { displayName } = system || {};
  const { t } = useI18n(NAMESPACES.COMMON);

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      className={`${styles.header} ${styles[toneType]}`}
    >
      <Button
        isOnlyIcon
        intent={BUTTON_INTENT.tertiary}
        onClick={handleClick}
        className={styles.button}
      >
        <Icon
          name="arrow_left-1"
          className={styles.icon}
          title={t('backheader.back')}
        />
      </Button>
      <EllipsisText className={styles.title}>{displayName}</EllipsisText>
    </FlexBox>
  );
};
export default CardBackHead;
