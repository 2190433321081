import { TOURNAMENTS_QUERY_KEYS } from '../../../constants';
import {
  TBaseTournament,
  TTournamentItem,
  TTournamentResponse
} from '../../../types';
import getTournamentAdapter from '../adapters/getTournamentAdapter';

const getTournament = () => ({
  query: ({ id }: TTournamentItem) => ({
    body: {
      command: 'get_tournament',
      params: {
        tournament_id: id
      }
    }
  }),
  providesTags: (result, error, arg) => [
    { type: TOURNAMENTS_QUERY_KEYS.TOURNAMENT, id: arg.id }
  ],
  transformResponse(response: TTournamentResponse): TBaseTournament | {} {
    return !response ? {} : getTournamentAdapter(response);
  }
});
export default getTournament;
