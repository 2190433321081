import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const ExternalChat = lazy(() => import('./ExternalChat'));

export default (props: ComponentProps<typeof ExternalChat>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ExternalChat {...props} />
  </Suspense>
);
