import { camelizeKeys } from 'humps';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

import { getSocketSWARM } from '@monorepo/websocket';

import { SPORTS_QUERY_KEYS } from '../../../constants';
import { actions } from '../../index';
import { normalizeBetResponse } from '../adapters/betAdapter';
import gameUpdater from '../updaters/gameUdapter';

const updateBetUtil = (data, gameId, eventId, marketId, dispatch) => {
  const game = get(data, `game.${gameId}`, undefined);
  const market = get(
    game,
    `market.${marketId}`,
    game?.market[Object.keys(game.market)[0]] || undefined
  );
  const event = get(market, `event.${eventId}`, undefined);
  const { updateBet } = actions;
  const isDeleted = !game;

  const dataForUpdate = isDeleted
    ? { isDeleted: true, eventId, gameId }
    : {
        gameId,
        marketId,
        eventId,
        isBlocked: game?.is_blocked,
        isLive: game?.is_live,
        sportAlias: game?.sport_alias,
        regionAlias: game?.region_alias,
        competitionId: game?._parent_id,
        startTs: game?.start_ts,
        info: camelizeKeys(game?.info),
        stats: camelizeKeys(game?.stats),
        showType: game?.show_type,
        price: event?.price,
        isDeleted: false,
        expressId: market?.express_id
      };
  const updateData = omitBy(dataForUpdate, isUndefined);

  dispatch(updateBet(updateData));
};

const getBet = (api) => ({
  query: ({ eventId, gameId, marketId }: any) => ({
    body: {
      command: 'get',
      params: {
        source: 'betting',
        what: {
          game: [
            'id',
            'is_blocked',
            'is_live',
            'team1_name',
            'team2_name',
            'start_ts',
            'sport_alias',
            'region_alias',
            '_parent_id',
            'info',
            'stats',
            'show_type'
          ],
          market: ['id', 'name', 'express_id', 'type', 'extra_info'],
          event: ['id', 'name', 'type', 'type_1', 'price', 'base']
        },
        where: {
          ...(gameId && {
            game: {
              id: gameId
            }
          }),
          ...(marketId && {
            market: {
              id: marketId
            }
          }),
          event: {
            id: eventId
          }
        },
        weight: false,
        subscribe: true
      }
    }
  }),
  keepUnusedDataFor: 0,
  providesTags: [SPORTS_QUERY_KEYS.BET],
  async onCacheEntryAdded(
    args,
    { dispatch, cacheEntryRemoved, cacheDataLoaded, updateCachedData }
  ) {
    const instance = getSocketSWARM();
    try {
      const cacheEntry = await cacheDataLoaded;
      const { data } = cacheEntry;
      const { gameId, marketId, eventId } = args;
      updateBetUtil(data, gameId, eventId, marketId, dispatch);

      instance.subscribe(
        data?.subid,
        (newData) => {
          updateCachedData((draft) => gameUpdater(draft, newData));
          updateBetUtil(newData, gameId, eventId, marketId, dispatch);
        },
        () => dispatch(api.util.invalidateTags([SPORTS_QUERY_KEYS.BET]))
      );

      await cacheEntryRemoved;
      if (data?.subid) {
        await dispatch(api.endpoints.unsubscribe.initiate(data?.subid)).reset();
        instance.unsubscribe(data?.subid);
      }
    } catch (e) {
      console.error(e);
    }
  },
  transformResponse: normalizeBetResponse
});
export default getBet;
