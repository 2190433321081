import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const BannersCmsPlain = lazy(() => import('./BannersCmsPlain'));

export default ({ categoryId }: { categoryId: number }) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <BannersCmsPlain categoryId={categoryId} />
  </Suspense>
);
