import { googleAnalytics } from '@monorepo/helpers';
import { createRoomJid } from '@monorepo/xmpp';

import { TEnterRoomRequest } from '../../../types';
import { socialsApi } from '../../socialsApi';
import { actions } from '../../socialsSlice';
import { enterQuery } from '../enterRoom';

const roomPresence = () => ({
  ...enterQuery,
  keepUnusedDataFor: 0.2,
  async onCacheEntryAdded(
    args: TEnterRoomRequest,
    { dispatch, cacheEntryRemoved, cacheDataLoaded }
  ) {
    const { roomName } = args;
    const { enterRoom } = actions;
    try {
      await cacheDataLoaded;
      dispatch(enterRoom(createRoomJid(roomName)));
      await cacheEntryRemoved;
      await dispatch(socialsApi.endpoints.exitRoom.initiate(args));
    } catch (e) {
      console.warn(e);
      /// ga  js-error
      const ga = googleAnalytics();
      ga.dispatch({
        event: ga.event.jsError,
        eventParam: {
          event_category: 'js'
        },
        event_options: {
          message: (e as any)?.message,
          data: e
        }
      });
    }
  }
});

export default roomPresence;
