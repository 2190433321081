import { TQueryResult } from '@monorepo/type';

import { useGetBoostedOddsQuery } from '../../store/sportsbook';
import { TBoostedOddsResponse } from '../../types';
import useGetData from '../useGetData';

const useGetBoostedOdds = (args?: any, options = {}) =>
  useGetBoostedOddsQuery(args, options);

export const useBoostedOddsData = <
  ReturnType = any,
  QueryResultType = TBoostedOddsResponse
>(
  dataSelector: (result: TQueryResult<QueryResultType>) => any,
  queryArgs: Partial<Record<string, any>> = {},
  queryOptions: Record<string, any> = {}
) =>
  useGetData<ReturnType, QueryResultType>(
    useGetBoostedOdds,
    dataSelector,
    queryArgs,
    queryOptions
  );

export default useGetBoostedOdds;
