import { TQueryResult } from '@monorepo/type';

import { useGetStreamsQuery } from '../../store/sportsbook';
import useGetData from '../useGetData';

const useGetAllStreams = (args?: any, options = {}) =>
  useGetStreamsQuery(args, {
    selectFromResult: (result) => ({
      isFetching: result?.isFetching,
      data: result?.data
    }),
    ...options
  });

export const useStreamsData = <
  ReturnType = any,
  QueryResultType = any,
  RestReturnedData = {}
>(
  dataSelector?: (result: TQueryResult<QueryResultType>) => any,
  queryArgs = {},
  queryOptions: Record<string, any> = {},
  restSelectors?: (result: TQueryResult<QueryResultType>) => Record<string, any>
) =>
  useGetData<ReturnType, QueryResultType, RestReturnedData>(
    useGetAllStreams,
    dataSelector,
    queryArgs,
    queryOptions,
    restSelectors
  );

export default useGetAllStreams;
