import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const SearchForm = lazy(() => import('./SearchForm'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <SearchForm {...props} />
  </Suspense>
);
