import { TGetCurrentPlayerArgs } from '../../../../types';
import { CUSTOM_API_QUERY_KEYS } from '../../constants';

const getPlayerInformation = () => ({
  query: ({ jwt }: TGetCurrentPlayerArgs) => ({
    url: '/player',
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    }
  }),
  providesTags: [CUSTOM_API_QUERY_KEYS.PLAYER_INFORMATION]
});

export default getPlayerInformation;
