/* eslint-disable camelcase */
import compact from 'lodash/compact';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';

import {
  TGenericMarketsWithEvents,
  TMarketLayout
} from '../../../common/MarketCard/types';
import { TMarket } from '../../../types';
import nameFormatter from '../../../utils/nameFormatter';

const getData = (response: Record<string, any>) => response?.data;
const getGame = (response: Record<string, any>, gameId: number) =>
  response?.game ? response?.game[gameId] : null;
const getMarkets = (response: Record<string, any>) => response?.market;

export const MARKET_LAYOUTS = Object.freeze({
  GENERIC: 'generic',
  COLUMNS: 'columns'
});

const emptyEvent = () => ({
  id: null,
  price: '-'
});

const getFirstColumn = (arr: []) =>
  arr.filter(
    ({ away_value: awayValue, home_value: homeValue }: any) =>
      homeValue > awayValue
  );

const getSecondColumn = (arr: []) =>
  arr.filter(
    ({ away_value: awayValue, home_value: homeValue }: any) =>
      homeValue === awayValue
  );

const getThirdColumn = (arr: []) =>
  arr.filter(
    ({ away_value: awayValue, home_value: homeValue }: any) =>
      homeValue < awayValue
  );

const format3Column = (events) => {
  const firstColumn = getFirstColumn(events);
  const secondColumn = getSecondColumn(events);
  const thirdColumn = getThirdColumn(events);

  const columnsLength = [
    firstColumn.length,
    secondColumn.length,
    thirdColumn.length
  ];

  const columns = [[], [], []];

  let i = Math.max(...columnsLength);

  while (i--) {
    firstColumn[i]
      ? columns[0].push(firstColumn[i] as never)
      : columns[0].push(emptyEvent() as never);

    secondColumn[i]
      ? columns[1].push(secondColumn[i] as never)
      : columns[1].push(emptyEvent() as never);

    thirdColumn[i]
      ? columns[2].push(thirdColumn[i] as never)
      : columns[2].push(emptyEvent() as never);
  }
  return columns;
};
const format2Column = (events) => {
  const secondsColumn = events;

  const columnsLength = [secondsColumn.length, secondsColumn.length];

  const columns = [[], []];

  let i = Math.max(...columnsLength);

  while (i--) {
    columns[0].push(emptyEvent() as never);

    secondsColumn[i]
      ? columns[1].push(secondsColumn[i] as never)
      : columns[1].push(emptyEvent() as never);
  }
  return columns;
};

const formatEvents = (events: [], layout: string, colCount = 2) => {
  if (layout === MARKET_LAYOUTS.COLUMNS) {
    const columns =
      colCount === 2 ? format2Column(events) : format3Column(events);
    return columns.map((column) => map(sortBy(column, 'order'), 'id'));
  }
  return map(sortBy(events, 'order'), 'id') as number[];
};

export const getMarketTitles = (events: any, team1: string, team2: string) => {
  const eventTitles = sortBy(events, 'order').reduce((acc, event: any) => {
    const title = event?.name || event?.type_1 || '';
    return [...acc, nameFormatter(title, team1, team2)];
  }, [] as any);

  return eventTitles.length > 3 ? null : eventTitles;
};

const getMarketsWithOrder = (game: any) => {
  if (game) {
    const s = orderBy(compact(values(game.market)), 'order');
    const markets = reduce(
      s,
      (acc, item) => {
        const key = `${item.type}_${item.name_template}_${item.sequence}_${item.point_sequence}`;
        return {
          ...acc,
          [key]: [...(acc[key] || []), item.id]
        };
      },
      {}
    );
    return Object.entries(markets);
  }

  return null;
};

const getMarketById = (
  markets: TMarket[],
  marketId: number | number[],
  keys: string[] = []
) => {
  if (isArray(marketId)) {
    const selectedMarkets = compact(
      orderBy(values(pick(markets, marketId)), 'order')
    );
    return map(selectedMarkets, (market: TMarket) => ({
      [market.id]: pick(market, keys)
    }));
  }
  return markets ? [{ [marketId]: pick(markets[marketId], keys) }] : null;
};

export const selectMarketsWithOrder = (response, gameId: number) => {
  const data = getData(response);
  if (!data) {
    return null;
  }
  const game = getGame(data, gameId);
  return data ? getMarketsWithOrder(game) : null;
};

export const selectMarketById = (
  response,
  marketId: number,
  gameId: number
) => {
  const data = getData(response);

  if (!data) {
    return null;
  }
  const game = getGame(data, gameId);
  if (!game) {
    return null;
  }
  const { is_blocked, team1_name, team2_name } = game;
  const markets = getMarkets(game);
  const [market] =
    getMarketById(markets, marketId, [
      'name',
      'cashout',
      'id',
      'col_count',
      'layout',
      'type',
      'group_id'
    ]) || [];
  if (!market) {
    return null;
  }

  return {
    ...values(market)[0],
    is_blocked,
    team1_name,
    team2_name
  };
};

export const selectMarketTitlesById = (
  response,
  marketId: number,
  gameId: number
) => {
  const data = getData(response);
  if (!data) {
    return null;
  }
  const game = getGame(data, gameId);
  if (!game) {
    return null;
  }
  const markets = getMarkets(game);
  const market = get(
    getMarketById(markets, marketId, ['event']),
    `[0].${marketId}`
  );
  const { team1_name: team1Name, team2_name: team2Name } = game;
  const { event } = market;
  const eventData = map(values(event), (item) =>
    pick(item, ['name', 'type_1', 'order'])
  );
  return getMarketTitles(eventData, team1Name, team2Name);
};

export const selectEventIdsByMarketIds = (
  response,
  marketId: number | number[],
  gameId: number,
  layout: TMarketLayout,
  colCount: number
): TGenericMarketsWithEvents | null => {
  const data = getData(response);
  if (!data) {
    return null;
  }
  const game = getGame(data, gameId);
  const markets = getMarkets(game);
  const selectedMarkets = getMarketById(markets, marketId, ['event']);

  return map(selectedMarkets, (market) => {
    const [[id, events]] = Object.entries(market);
    return {
      id: Number(id),
      eventIds: formatEvents(
        compact(values(events.event)),
        layout,
        colCount
      ) as number[]
    };
  });
};

export const selectEventById = (
  response,
  eventId: number,
  gameId: number,
  marketId: number
) => {
  if (!eventId) {
    return null;
  }
  const data = getData(response);
  if (!data) {
    return null;
  }
  const game = getGame(data, gameId);
  const markets = getMarkets(game);
  const [selectedMarket] = getMarketById(markets, marketId, ['event']) || [];
  const event = get(values(selectedMarket)[0], `event.${eventId}`);
  return { event, game };
};
