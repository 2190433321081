const getShare = () => ({
  query: (data: any) => ({
    method: 'POST',
    url: '/image-creator/share-bet/',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify(data)
  })
});
export default getShare;
