import get from 'lodash/get';

import { TNormalizedGameMarkets } from '../../../types';

export const normalizeMarketsResponse = (
  rawResponse: Record<string, any>
): TNormalizedGameMarkets => {
  const game = get(rawResponse, 'data.game', null);
  const subid = get(rawResponse, 'subid', '');

  return {
    game,
    subid
  };
};
