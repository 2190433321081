import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const Page404 = lazy(() => import('./Page404'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Page404 config={config} />
  </Suspense>
);
