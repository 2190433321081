import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const ChatsUserList = lazy(() => import('./ChatsUserList'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ChatsUserList config={config} />
  </Suspense>
);
