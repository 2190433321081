import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const CompetitionHeader = lazy(() => import('./CompetitionHeader'));

export default (props: ComponentProps<typeof CompetitionHeader>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <CompetitionHeader {...props} />
  </Suspense>
);
