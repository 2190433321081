import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Component } from '@monorepo/type';
import { FLEX_DIRECTION } from '@monorepo/ui-kit';

import { OUTCOME_VIEW_MODE } from '../../constants';
import { useLineMarketsData } from '../../hooks/useGetLineMarkets';
import { selectGameMarketOrderedOutcomes } from '../../store/api/selectors';
import LineEmptyOutcomeList from '../LineEmptyOutcomeList';
import LineOutcome from '../LineOutcome';

type Props = {
  gameId: number;
  marketId: number;
  selectionCount?: number;
  itemClassName?: string;
  direction?: FLEX_DIRECTION;
  viewMode?: OUTCOME_VIEW_MODE;
  competitionId: number;
  sportId: number;
  regionId: number;
};

const LineOutcomeList: Component<Props> = ({
  itemClassName = '',
  gameId,
  marketId,
  selectionCount,
  direction,
  viewMode,
  competitionId,
  sportId,
  regionId
}) => {
  const { data: outcomes } = useLineMarketsData((result) =>
    selectGameMarketOrderedOutcomes(result, gameId, marketId)
  );

  if (isEmpty(outcomes)) {
    return (
      <LineEmptyOutcomeList
        selectionCount={selectionCount}
        className={itemClassName}
      />
    );
  }

  return (
    <>
      {outcomes.map((outcomeId: number) => (
        <LineOutcome
          key={outcomeId}
          outcomeId={outcomeId}
          gameId={gameId}
          marketId={marketId}
          className={itemClassName}
          direction={direction}
          viewMode={viewMode}
          competitionId={competitionId}
          regionId={regionId}
          sportId={sportId}
        />
      ))}
    </>
  );
};

export default LineOutcomeList;
