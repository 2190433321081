import React from 'react';

import { CustomFC, TActionsConfig } from '@monorepo/type';

import PromotionCardVideo from '../PromotionCardVideo';

type Props = {
  list: any[];
  listClassName?: string;
} & TActionsConfig;
const PromotionCardVideoList: CustomFC<Props> = ({
  list,
  withLike,
  withComment,
  withStream,
  withFavorite,
  withShare,
  listClassName
}) => (
  <>
    {list.map(({ id, description, url, createTime }) => (
      <PromotionCardVideo
        key={id}
        id={id}
        description={description}
        url={url}
        createTime={createTime}
        withLike={withLike}
        withComment={withComment}
        withStream={withStream}
        withFavorite={withFavorite}
        withShare={withShare}
        className={listClassName}
      />
    ))}
  </>
);
export default PromotionCardVideoList;
