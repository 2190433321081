import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { FAVORITE_TYPE, FavoriteButton, MetaTitle } from '@monorepo/common';
import { SvgIcon } from '@monorepo/icons';
import { useComponentsConfig } from '@monorepo/page';
import { Component } from '@monorepo/type';
import { EllipsisText, FLEX_ALIGN, FlexBox } from '@monorepo/ui-kit';

import { useLineGamesData } from '../../hooks/useGetLineGames';
import { selectCompetitionBySportIdRegionIdCompetitionId } from '../../store/api/selectors';
import { formatIconNameFromAlias } from '../../utils';

import styles from './index.module.css';

type Props = {
  sportId: number;
  regionId: number;
  competitionId: number;
  showTitle?: boolean;
};

const LineCompetitionHeader: Component<Props> = ({
  sportId,
  regionId,
  competitionId,
  className = '',
  showTitle = false
}) => {
  const { data: competition } = useLineGamesData((result) =>
    selectCompetitionBySportIdRegionIdCompetitionId(
      result,
      sportId,
      regionId,
      competitionId
    )
  );
  const { withFavorite } = useComponentsConfig<{ withFavorite?: boolean }>();

  if (isEmpty(competition)) {
    return null;
  }

  const { competitionName, regionAlias } = competition;

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      className={`${styles.wrap} ${className}`}
    >
      <SvgIcon
        name={formatIconNameFromAlias(regionAlias)}
        className={styles.icon}
        defaultIcon="world"
      />
      <EllipsisText className={styles.title}>{competitionName}</EllipsisText>
      {withFavorite && (
        <FavoriteButton
          id={competitionId}
          type={FAVORITE_TYPE.COMPETITION}
          size="big"
          className={styles.favorite}
          dataCustom="competition-head-favorite"
        />
      )}
      {showTitle && <MetaTitle titlePage={competitionName} />}
    </FlexBox>
  );
};

export default LineCompetitionHeader;
