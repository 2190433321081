import {
  combineReducers,
  configureStore,
  createListenerMiddleware
} from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import expireReducer from 'redux-persist-expire';

import {
  authLoginApi,
  authLoginApiV2,
  sessionApi,
  userApi,
  userLocalSlice,
  userSlice
} from '@monorepo/auth';
import { AUTH_QUERY_KEYS } from '@monorepo/auth/src/constants';
import { userSignUp } from '@monorepo/auth/src/store/userSignUp';
import casinoGamesApi from '@monorepo/casino/src/store/api/getGames';
import { casinoApi } from '@monorepo/casino/src/store/casino';
import { casinoJackpots } from '@monorepo/casino/src/store/casinoJackpots';
import { casinoLastGamesApi } from '@monorepo/casino/src/store/casinoLastGamesApi';
import { casinoLimitationsApi } from '@monorepo/casino/src/store/casinoLimitationsApi';
import { casinoRecommenderApi } from '@monorepo/casino/src/store/casinoRecommender';
import { customGameApi } from '@monorepo/casino/src/store/customGameApiStore';
import {
  casinoWallApi,
  cmsApi,
  customApi,
  notificationsLocalSlice
} from '@monorepo/cms';
import { geoApi } from '@monorepo/cms/src/store/geoApi';
import { shareBetApi } from '@monorepo/cms/src/store/shareBetApi';
import {
  appSlice,
  commonReducer,
  exchangeApi,
  mHashApi,
  recaptchaSlice,
  userGeneratedContentApi
} from '@monorepo/common';
import { countriesApi } from '@monorepo/common/src/store/api/getCountries';
import { appLoggerApi } from '@monorepo/common/src/store/appLogger';
import { mediaStorageApi } from '@monorepo/files';
import {
  avatarApi,
  messengerApi,
  messengerApiV2,
  messengerSlice,
  searchFriendsApi
} from '@monorepo/messenger';
import { userNotificationsReducer } from '@monorepo/notification';
import {
  socialsAdditionalApi,
  socialsApi,
  socialsSliceReducer
} from '@monorepo/socials';
import { SPORTS_QUERY_KEYS } from '@monorepo/sports';
import { betslipSlice } from '@monorepo/sports/src/store';
import { sportsBookApi } from '@monorepo/sports/src/store/sportsbook';
import { sportsRecommenderApi } from '@monorepo/sports/src/store/sportsRecommender';
import { sportsStatsApi } from '@monorepo/sports/src/store/sportsstat';
import { tournamentsApi } from '@monorepo/tournaments';
import { WALLET_QUERY_KEYS, walletApi } from '@monorepo/wallet';

const rootReducer = combineReducers({
  [userSlice.name]: userSlice.reducer,
  [userLocalSlice.name]: userLocalSlice.reducer,
  [notificationsLocalSlice.name]: notificationsLocalSlice.reducer,
  [userSignUp.name]: userSignUp.reducer,
  [betslipSlice.name]: betslipSlice.reducer,
  favorites: commonReducer,
  [appSlice.name]: appSlice.reducer,
  userNotifications: userNotificationsReducer,
  [userApi.reducerPath]: userApi.reducer,
  [casinoGamesApi.reducerPath]: casinoGamesApi.reducer, // need to refactor: unite all casino in one api
  [casinoApi.reducerPath]: casinoApi.reducer,
  [tournamentsApi.reducerPath]: tournamentsApi.reducer,
  [casinoLimitationsApi.reducerPath]: casinoLimitationsApi.reducer,
  [casinoLastGamesApi.reducerPath]: casinoLastGamesApi.reducer,
  [cmsApi.reducerPath]: cmsApi.reducer,
  [shareBetApi.reducerPath]: shareBetApi.reducer,
  [geoApi.reducerPath]: geoApi.reducer,
  [exchangeApi.reducerPath]: exchangeApi.reducer,
  [customGameApi.reducerPath]: customGameApi.reducer,
  [walletApi.reducerPath]: walletApi.reducer,
  [sportsBookApi.reducerPath]: sportsBookApi.reducer,
  [sportsStatsApi.reducerPath]: sportsStatsApi.reducer,
  [customApi.reducerPath]: customApi.reducer,
  [casinoWallApi.reducerPath]: casinoWallApi.reducer,
  [casinoJackpots.reducerPath]: casinoJackpots.reducer,
  [socialsApi.reducerPath]: socialsApi.reducer,
  [sessionApi.reducerPath]: sessionApi.reducer,
  [socialsAdditionalApi.reducerPath]: socialsAdditionalApi.reducer,
  [casinoRecommenderApi.reducerPath]: casinoRecommenderApi.reducer,
  socialsSlice: socialsSliceReducer,
  [authLoginApi.reducerPath]: authLoginApi.reducer,
  [authLoginApiV2.reducerPath]: authLoginApiV2.reducer,
  [sportsRecommenderApi.reducerPath]: sportsRecommenderApi.reducer,
  [mediaStorageApi.reducerPath]: mediaStorageApi.reducer,
  [userGeneratedContentApi.reducerPath]: userGeneratedContentApi.reducer,
  [appLoggerApi.reducerPath]: appLoggerApi.reducer,
  [messengerApi.reducerPath]: messengerApi.reducer,
  [messengerApiV2.reducerPath]: messengerApiV2.reducer,
  [avatarApi.reducerPath]: avatarApi.reducer,
  [messengerSlice.name]: messengerSlice.reducer,
  [searchFriendsApi.reducerPath]: searchFriendsApi.reducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [mHashApi.reducerPath]: mHashApi.reducer,
  [recaptchaSlice.name]: recaptchaSlice.reducer
});

const persistKey = import.meta.env.VITE_APP_PERSIST_STORE_KEY || 'root';

const persistConfig = {
  key: persistKey,
  version: 1,
  whitelist: [
    'betslip',
    'favorites',
    'userNotifications',
    userLocalSlice.name,
    notificationsLocalSlice.name,
    userSignUp.name,
    appSlice.name,
    ...(import.meta.env.VITE_APP_CASINO_PERSIST_CACHE === 'true'
      ? [casinoApi.reducerPath, casinoGamesApi.reducerPath]
      : [])
  ],
  storage,
  ...(import.meta.env.VITE_APP_CASINO_PERSIST_CACHE === 'true' // disable caching for casino with .env file
    ? {
        transforms: [
          expireReducer(casinoApi.reducerPath, {
            expireSeconds: 259200, // caching casino response in LocalStorage for 3 days
            autoExpire: true
          })
        ]
      }
    : {})
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  type: REHYDRATE,
  effect: (action, api) => {
    const { expBetsTime } = action?.payload?.[betslipSlice.name] || {};
    const { version } = action?.payload?.[appSlice.name] || {};
    if (expBetsTime && Date.now() > expBetsTime) {
      api.dispatch(betslipSlice.actions.clearBets());
      api.dispatch(betslipSlice.actions.setExpBetsTime(null));
    } else if (version !== import.meta.env.VITE_APP_VERSION) {
      api.dispatch(betslipSlice.actions.clearBets());
      api.dispatch(userLocalSlice.actions.resetUserLocal(undefined));
    }
    if (version !== import.meta.env.VITE_APP_VERSION) {
      api.dispatch(
        appSlice.actions.setAppVersion(import.meta.env.VITE_APP_VERSION || '')
      );
    }

    api.unsubscribe();
  }
});

listenerMiddleware.startListening({
  predicate: (action) => userApi.endpoints.logout.matchFulfilled(action),
  effect: (action, api) => {
    api.dispatch(userApi.util.resetApiState());
    api.dispatch(
      sportsBookApi.util.invalidateTags([
        SPORTS_QUERY_KEYS.BETS,
        SPORTS_QUERY_KEYS.BETS_COUNT
      ])
    );
    api.dispatch(walletApi.util.resetApiState());
    api.dispatch(customApi.util.resetApiState());
    api.dispatch(userLocalSlice.actions.resetUserLocal(undefined));
    api.dispatch(
      userSlice.actions.setIsAuthenticated({
        isAuthenticated: false,
        isAuthFetched: true
      })
    );
    api.dispatch(betslipSlice.actions.clearBets());
  }
});

listenerMiddleware.startListening({
  predicate: (action) => userApi.endpoints.restoreLogin.matchFulfilled(action),
  effect: (action, api) => {
    api.dispatch(
      sportsBookApi.util.invalidateTags([
        SPORTS_QUERY_KEYS.BETS,
        SPORTS_QUERY_KEYS.BETS_COUNT
      ])
    );
    api.dispatch(
      userApi.util.invalidateTags([
        AUTH_QUERY_KEYS.USER,
        AUTH_QUERY_KEYS.BONUSES,
        AUTH_QUERY_KEYS.FREESPINS,
        AUTH_QUERY_KEYS.JWT
      ])
    );
    api.dispatch(
      walletApi.util.invalidateTags([
        WALLET_QUERY_KEYS.GET_PAYMENTS_DETAILS,
        WALLET_QUERY_KEYS.GET_PAYMENTS_LIST
      ])
    );
  }
});

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([
      listenerMiddleware.middleware,
      userApi.middleware,
      casinoApi.middleware,
      geoApi.middleware,
      shareBetApi.middleware,
      customGameApi.middleware,
      tournamentsApi.middleware,
      casinoLimitationsApi.middleware,
      cmsApi.middleware,
      walletApi.middleware,
      sportsBookApi.middleware,
      sportsStatsApi.middleware,
      customApi.middleware,
      appLoggerApi.middleware,
      casinoGamesApi.middleware,
      casinoJackpots.middleware,
      casinoLastGamesApi.middleware,
      socialsApi.middleware,
      socialsAdditionalApi.middleware,
      sessionApi.middleware,
      authLoginApi.middleware,
      authLoginApiV2.middleware,
      casinoWallApi.middleware,
      casinoRecommenderApi.middleware,
      sportsRecommenderApi.middleware,
      mediaStorageApi.middleware,
      userGeneratedContentApi.middleware,
      exchangeApi.middleware,
      messengerApi.middleware,
      messengerApiV2.middleware,
      avatarApi.middleware,
      searchFriendsApi.middleware,
      countriesApi.middleware,
      mHashApi.middleware
    ])
});

export const persistor = persistStore(store);

export default store;
