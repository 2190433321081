import { Client, client, jid, Options } from '@xmpp/client';
import Element from 'ltx/src/Element';

import { googleAnalytics } from '@monorepo/helpers';

import { PRESENCE_TYPE } from '../../constants';
import { XML_NAMESPACES } from '../../constants/namespaces';
import createStanzaId from '../createStanzaId';
import { presenceCreator } from '../messageCreators/presence';

export class XMPPClient {
  xmppClient: Client;

  xmppOptions: Options;

  jid;

  constructor(options: Options) {
    this.xmppOptions = options;
    this.jid = jid(`${options.username}@${options.domain}/${options.resource}`);
    this.xmppClient = client(options);
    this.xmppClient.iqCallee.get(XML_NAMESPACES.PING, 'ping', () => ({}));
    // https://xmpp.org/rfcs/rfc6121.html#roster-add-success
    this.xmppClient.iqCallee.set(XML_NAMESPACES.ROSTER, 'query', () => ({}));
  }

  nonSentStanzas = new Map();

  createdPromises = new Map();

  public connect = () => {
    this.addListeners();
    return this.xmppClient.start();
  };

  public isConnected = () => this.xmppClient.status === 'online';

  public stop = async (status?: string) => {
    if (this.isConnected()) {
      /*
       * https://xmpp.org/rfcs/rfc6121.html#presence-unavailable-gen
       */
      await this.xmppClient.send(
        presenceCreator({ status, type: PRESENCE_TYPE.UNAVAILABLE })
      );
    }
    this.nonSentStanzas = new Map();
    return this.xmppClient.stop();
  };

  private createPromise = (stanza: Element) =>
    new Promise((resolve, reject) => {
      try {
        if (this.isConnected()) {
          this.xmppClient.send(stanza);
          if (this.nonSentStanzas.has(stanza.attrs.id)) {
            this.nonSentStanzas.delete(stanza.attrs.id);
          }
        } else {
          this.nonSentStanzas.set(stanza.attrs.id, stanza);
        }
        if (!this.createdPromises.has(stanza.attrs.id)) {
          this.createdPromises.set(stanza.attrs.id, { resolve, reject });
        }
      } catch (e) {
        console.warn(e);
        // ga  js-error
        const ga = googleAnalytics();
        ga.dispatch({
          event: ga.event.jsError,
          eventParam: {
            event_category: 'js'
          },
          event_options: {
            message: (e as any)?.message,
            data: e
          }
        });
      }
    });

  public send = (stanza: Element) => {
    if (!stanza.attrs.id) {
      const stanzaId = createStanzaId(this.jid.getLocal());
      Object.assign(stanza.attrs, {
        id: stanzaId
      });
    }

    return this.createPromise(stanza);
  };

  public createListener = (
    name: string,
    callback: (...attrs: any[]) => void
  ) => {
    this.xmppClient.on(name, callback);
  };

  public removeListener = (
    name: string,
    callback: (...attrs: any[]) => void
  ) => {
    this.xmppClient.removeListener(name, callback);
  };

  public createReconnectListener = (
    name: string,
    callback: (...attrs: any[]) => void
  ) => {
    this.xmppClient.reconnect.on(name, callback);
  };

  public removeReconnectListener = (
    name: string,
    callback: (...attrs: any[]) => void
  ) => {
    this.xmppClient.reconnect.removeListener(name, callback);
  };

  private onlineCallback = async (myJid: any) => {
    this.jid = myJid;
    this.nonSentStanzas.forEach(this.send);
  };

  private stanzaCallback = (stanza: Element) => {
    if (this.createdPromises.has(stanza.attrs.id)) {
      if (stanza.attrs.type === 'error') {
        this.createdPromises.get(stanza.attrs.id).reject(stanza);
      } else {
        this.createdPromises.get(stanza.attrs.id).resolve(stanza);
      }
      this.createdPromises.delete(stanza.attrs.id);
    }
  };

  private addListeners = () => {
    this.createListener('online', this.onlineCallback);
    this.createListener('stanza', this.stanzaCallback);
  };
}

export const openXMPPClient = (options: Options) => new XMPPClient(options);

export default openXMPPClient;
