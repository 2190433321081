import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { Component } from '@monorepo/type';

import { FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import { DivProps } from '../../types';
import getAvatarPlaceholder from '../../utils/getAvatarPlaceholder';
import FlexBox from '../FlexBox';
import PreloadImage from '../PreloadImage';

import styles from './index.module.css';

type Props = {
  imgSrc?: string;
  nickname?: string;
  avatarClass?: string;
  alt?: string;
} & DivProps;

const Avatar: Component<Props> = ({
  className = '',
  avatarClass = '',
  imgSrc = '',
  nickname = '',
  alt = '',
  children,
  ...props
}) => (
  <div className={`${styles.wrap} ${className}`} {...props}>
    <PreloadImage
      className={`${styles.avatar} ${avatarClass}`}
      imgClassName={styles.img}
      src={imgSrc}
      alt={alt || 'avatar'}
      visibleByDefault={!isEmpty(imgSrc)}
    >
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        className={styles.placeholder}
      >
        {getAvatarPlaceholder(nickname)}
      </FlexBox>
    </PreloadImage>
    {children}
  </div>
);

export default Avatar;
