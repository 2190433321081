import React, { useState } from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import {
  FLEX_DIRECTION,
  FlexBox,
  PaddingWrapper,
  Tab,
  TabsList
} from '@monorepo/ui-kit';

import CasinoWidgetTournaments from './components/WidgetTournamentsList';
import CasinoWinners from './components/Winners';

import styles from './index.module.css';

type TCasinoGoodies = {
  tabs: string[];
  winnersAmount: number;
};

const TABS_CONTENT = [CasinoWidgetTournaments, CasinoWinners];
const ICON_NAMES = ['cup', 'diamond-outline'];

const CasinoGoodies: ComponentWithConfig<TCasinoGoodies> = ({ config }) => {
  const { tabs, winnersAmount = false } = config;
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useI18n(NAMESPACES.CASINO);
  const TabContents = TABS_CONTENT[activeIndex];
  const { isDesktop } = useDeviceDetector();

  return (
    <PaddingWrapper isOnlyMobile className={styles.wrapper}>
      <FlexBox direction={FLEX_DIRECTION.column}>
        <TabsList
          className={styles.tabs}
          activeIndex={activeIndex}
          onChange={setActiveIndex}
          fullWidth
        >
          {tabs.map((tab) => (
            <Tab
              key={tab}
              iconNames={ICON_NAMES}
              isWithIcon
              label={t(tab)}
              activeClass={styles.activeTab}
              className={styles.tab}
              isShowLabel={isDesktop}
            />
          ))}
        </TabsList>
        <FlexBox className={styles.content}>
          {Boolean(TABS_CONTENT[activeIndex]) && (
            <TabContents winnersAmount={winnersAmount} />
          )}
        </FlexBox>
      </FlexBox>
    </PaddingWrapper>
  );
};

export default CasinoGoodies;
