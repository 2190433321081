import promotionSlotsAdapter from '../../../api/adapters/promotionSlotsAdapter';

const getSlots = () => ({
  query: ({
    tags,
    providers,
    limit,
    offset,
    filter,
    order_by,
    sort,
    siteId
  }: {
    tags: string[] | null;
    providers: string[] | null;
    filter?: string | null;
    limit?: number;
    offset?: number;
    order_by?: string;
    sort?: string;
    siteId: number;
  }) => {
    const searchParams = new URLSearchParams({
      partner_id: JSON.stringify(siteId)
    });

    const url = `/features`;
    if (providers) {
      searchParams.set('providers', providers.join(','));
    }
    if (tags) {
      searchParams.set('tags', tags.join(','));
    }
    if (filter) {
      searchParams.set('filter', filter);
    }
    if (limit) {
      searchParams.set('limit', JSON.stringify(limit));
    }
    if (offset) {
      searchParams.set('offset', JSON.stringify(offset));
    }
    if (order_by) {
      searchParams.set('order_by', order_by);
    }
    if (sort) {
      searchParams.set('sort', sort);
    }

    return {
      url: `${url}?${searchParams.toString()}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    };
  },
  transformResponse: (response: any) => promotionSlotsAdapter(response)
});

export default getSlots;
