import BannerJackpots from '../components/BannerJackpots';
import BannersSlider from '../components/BannersSlider';
import CasinoGamesRecommended from '../components/CasinoGamesRecommended';
import CasinoGoodies from '../components/CasinoGoodies';
import CasinoJackpots from '../components/CasinoJackpots';
import CasinoLastPlayedGames from '../components/CasinoLastPlayedGames';
import CasinoSection from '../components/CasinoSection';
import CasinoSelectedFilters from '../components/CasinoSelectedFilters';
import CasinoTournaments from '../components/CasinoTournaments';
import CategoriesMenu from '../components/CategoriesMenu';
import FavoriteGames from '../components/FavoriteGames';
import GamesByCategory from '../components/GamesByCategory';
import GamesLobby from '../components/GamesLobby';
import GamesSearch from '../components/GamesSearch';
import GamesSelected from '../components/GamesSelected';
import GamesSuggested from '../components/GamesSuggested';
import LastPlayedGames from '../components/LastPlayedGames';
import CasinoLauncher from '../components/Launcher';
import Tournament from '../components/Tournament';

import COMPONENT_TYPES from './componentTypes';

const COMPONENTS_MAP = {
  [COMPONENT_TYPES.BANNER_JACKPOTS]: BannerJackpots,
  [COMPONENT_TYPES.CASINO_CATEGORIES_MENU]: CategoriesMenu,
  [COMPONENT_TYPES.CASINO_GAMES_LOBBY]: GamesLobby,
  [COMPONENT_TYPES.CASINO_GAMES_BY_CATEGORY]: GamesByCategory,
  [COMPONENT_TYPES.CASINO_GAMES_RECOMMENDED]: CasinoGamesRecommended,
  [COMPONENT_TYPES.CASINO_LAUNCHER]: CasinoLauncher,
  [COMPONENT_TYPES.CASINO_SELECTED_FILTERS]: CasinoSelectedFilters,
  [COMPONENT_TYPES.CASINO_GAMES_SELECTED]: GamesSelected,
  [COMPONENT_TYPES.CASINO_GAMES_SUGGESTED]: GamesSuggested,
  [COMPONENT_TYPES.CASINO_TOURNAMENTS]: CasinoTournaments,
  [COMPONENT_TYPES.CASINO_TOURNAMENT]: Tournament,
  [COMPONENT_TYPES.FAVORITE_GAMES]: FavoriteGames,
  [COMPONENT_TYPES.CASINO_JACKPOTS]: CasinoJackpots,
  [COMPONENT_TYPES.CASINO_LAST_PLAYED_GAMES]: LastPlayedGames,
  [COMPONENT_TYPES.CASINO_GAMES_LAST_PLAYED]: CasinoLastPlayedGames,
  [COMPONENT_TYPES.SEARCH_CASINO_GAMES]: GamesSearch,
  [COMPONENT_TYPES.BANNERS_SLIDER]: BannersSlider,
  [COMPONENT_TYPES.CASINO_GOODIES]: CasinoGoodies,
  [COMPONENT_TYPES.CASINO_SECTION]: CasinoSection
};

export default COMPONENTS_MAP;
