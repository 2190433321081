import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import {
  Box,
  BUTTON_SIZE,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  LinkButton,
  ModalWrapper,
  TModalHandler
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TModalNotification = {
  handleClose: () => void;
  classOverlayModal?: string;
  classContentModal?: string;
  header: string;
  description: string;
  addDescription?: string;
  buttonText: string;
  page?: string;
};

const ModalNotification: ForwardRefRenderFunction<
  TModalHandler,
  TModalNotification
> = (
  {
    handleClose,
    classOverlayModal = '',
    classContentModal = '',
    header,
    description,
    addDescription,
    buttonText,
    page = '/home'
  },
  ref
) => {
  const stylesOverlay = `${styles.overlay} ${classOverlayModal}`;

  return (
    <ModalWrapper
      ref={ref}
      classOverlayModal={stylesOverlay}
      classContentModal={classContentModal}
      handleClose={handleClose}
      header={header}
    >
      <FlexBox direction={FLEX_DIRECTION.column}>
        <Box className={styles.description}>{description}</Box>
        <FlexBox justify={FLEX_JUSTIFY.between}>
          <LinkButton
            to={page}
            onClick={handleClose}
            size={BUTTON_SIZE.h40}
            className={styles.button}
          >
            {buttonText}
          </LinkButton>
        </FlexBox>
        {addDescription && (
          <Box className={styles.description}>{addDescription}</Box>
        )}
      </FlexBox>
    </ModalWrapper>
  );
};
export default forwardRef(ModalNotification);
