import { useSiteSettings } from '@monorepo/site-settings';
import { TQueryResult } from '@monorepo/type';

import { TGetGamesRequest, TNormalizedSports } from '../../types';
import useGetData from '../useGetData';
import useGetGames from '../useGetGames';
import useLineParameters from '../useLineParameters';

const useGetLineGames = (
  args: Partial<TGetGamesRequest> = {},
  options = {}
) => {
  const {
    gameType,
    sportAlias,
    regionAlias,
    competitionId,
    time,
    isPopular,
    limit,
    search,
    gameIds,
    competitionIds,
    nodeLimits
  } = useLineParameters();

  const { languages } = useSiteSettings();

  return useGetGames(
    {
      gameType,
      sportAlias,
      regionAlias,
      competitionId,
      time,
      isPopular,
      limit,
      search,
      gameIds,
      competitions: competitionIds,
      nodeLimits,
      languages,
      ...args
    },
    options
  );
};

export const useLineGamesData = <
  ReturnType = any,
  QueryResultType = TNormalizedSports,
  RestReturnedData = {}
>(
  dataSelector: (result: TQueryResult<QueryResultType>) => any,
  queryArgs: Partial<TGetGamesRequest> = {},
  queryOptions: Record<string, any> = {},
  restSelectors?: (result: TQueryResult<QueryResultType>) => Record<string, any>
) =>
  useGetData<ReturnType, QueryResultType, RestReturnedData>(
    useGetLineGames,
    dataSelector,
    queryArgs,
    queryOptions,
    restSelectors
  );

export default useGetLineGames;
