import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const LazyComponent = lazy(() => import('./ExternalDialogPage'));

export default (props: ComponentProps<typeof LazyComponent>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <LazyComponent {...props} />
  </Suspense>
);
