import { useTypedSelector } from '@monorepo/redux';

import recaptchaSlice from '../recaptchaSlice';

export const getIsRecaptchaValidated = (state: any) =>
  state[recaptchaSlice.name].isRecaptchaValidated;

const useSelectIsRecaptchaValidated = () =>
  useTypedSelector(getIsRecaptchaValidated);

export default useSelectIsRecaptchaValidated;
