import React from 'react';

import { useIsAuthenticated } from '@monorepo/auth';
import { transformMenuItems, useGetMenu } from '@monorepo/cms';
import {
  CustomButton,
  Logo,
  MetaVerseButton,
  useCustomButton
} from '@monorepo/common';
import { useSiteSettings } from '@monorepo/site-settings';
import { ComponentWithConfig } from '@monorepo/type';
import {
  Box,
  FlexBox,
  HeaderNavMenu as UiHeaderNavMenu,
  TMenuItem
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type THeaderNavMenu = {
  menuId: number;
  menuSecondId: number;
  additional: string[];
  items: TMenuItem[];
};
const HeaderNavMenu: ComponentWithConfig<THeaderNavMenu> = ({ config }) => {
  const { menuId, menuSecondId, additional } = config;
  const { logoSmall } = useSiteSettings();
  const customButton = useCustomButton();
  const { isLoading, data: cmsMenuItems } = useGetMenu(menuId);
  const { isLoading: isLoadingSecond, data: cmsMenuItemsSecond } =
    useGetMenu(menuSecondId);
  const { isAuthenticated } = useIsAuthenticated();

  if (isLoading || isLoadingSecond) {
    return null;
  }
  const transFormedMenuItems = transformMenuItems(
    cmsMenuItems,
    isAuthenticated
  );
  const transFormedMenuItemsSecond = transformMenuItems(
    cmsMenuItemsSecond,
    isAuthenticated
  );

  return (
    <FlexBox className={styles.head}>
      <UiHeaderNavMenu items={transFormedMenuItems} linkClassName={styles.link}>
        {additional && (
          <MetaVerseButton item={additional} linkClassName={styles.link} />
        )}
      </UiHeaderNavMenu>
      <Box className={styles.logo}>
        <Logo
          config={{
            link: '/',
            title: '',
            logoIcon: logoSmall,
            src: ''
          }}
        />
      </Box>
      <UiHeaderNavMenu
        items={transFormedMenuItemsSecond}
        linkClassName={styles.link}
      >
        {customButton && (
          <CustomButton item={customButton} linkClassName={styles.link} />
        )}
      </UiHeaderNavMenu>
    </FlexBox>
  );
};

export default HeaderNavMenu;
