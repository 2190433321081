import React, { ChangeEvent, useState } from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { SvgIcon } from '@monorepo/icons';
import {
  Box,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Radio,
  Spinner
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type TCurrency = {
  CurrencyId: string;
  ClientId: number;
  Balance: number;
};

const Wallet = ({
  balances = [],
  availableBalances = [],
  isCreateNew = false,
  currencyName = '',
  parentCurrency = '',
  handleClick = () => {},
  isLoading = false
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  const knownCurr = ['FTN', 'EUR', 'USD'];
  const [checkedWallet, setCheckedWallet] = useState(currencyName || '');
  const handleChangeCashOutType = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckedWallet(e.currentTarget.value);
  };

  const isMainWallet = (i: TCurrency | null) =>
    i?.CurrencyId === parentCurrency ||
    (i?.CurrencyId === currencyName && parentCurrency === null);

  if (isLoading) {
    return <Spinner className={styles.spinner} />;
  }

  return (
    <FlexBox
      align={FLEX_ALIGN.center}
      justify={FLEX_JUSTIFY.center}
      className={styles.root}
    >
      <>
        {balances?.length > 0 && !isCreateNew && (
          <FlexBox
            direction={FLEX_DIRECTION.column}
            className={styles.walletsWrapper}
          >
            {balances?.map((i: TCurrency | null) => (
              <FlexBox
                direction={FLEX_DIRECTION.row}
                key={i?.ClientId}
                justify={FLEX_JUSTIFY.between}
                align={FLEX_ALIGN.center}
                className={styles.balanceRow}
                onClick={() => handleClick(i?.CurrencyId)}
              >
                <FlexBox align={FLEX_ALIGN.center} className={styles.walletRow}>
                  <Radio
                    className={styles.input}
                    name="wallet"
                    value={i?.CurrencyId}
                    onChange={handleChangeCashOutType}
                    checked={checkedWallet === i?.CurrencyId}
                    toneType={FIELD_TONE.light}
                  />
                  <SvgIcon
                    name={
                      knownCurr?.indexOf(i?.CurrencyId) >= 0
                        ? i?.CurrencyId
                        : 'all-0'
                    }
                    className={styles.logo}
                  />
                  <FlexBox
                    className={styles.currencyCol}
                    direction={FLEX_DIRECTION.column}
                    align={FLEX_ALIGN.start}
                  >
                    <Box>{i?.CurrencyId}</Box>
                    {isMainWallet(i) && (
                      <FlexBox
                        justify={FLEX_JUSTIFY.end}
                        className={styles.mainWallet}
                      >
                        {t('multiwallet.main.wallet')}
                      </FlexBox>
                    )}
                  </FlexBox>
                  <FlexBox justify={FLEX_JUSTIFY.end}>{i?.Balance}</FlexBox>
                </FlexBox>
              </FlexBox>
            ))}
          </FlexBox>
        )}
        <>
          {availableBalances?.length > 0 && isCreateNew && (
            <FlexBox
              direction={FLEX_DIRECTION.column}
              className={styles.walletsWrapper}
            >
              {availableBalances?.map((i) => (
                <FlexBox
                  direction={FLEX_DIRECTION.row}
                  key={i}
                  justify={FLEX_JUSTIFY.between}
                  className={styles.balanceRow}
                  onClick={() => handleClick(i)}
                >
                  <FlexBox align={FLEX_ALIGN.center}>
                    <SvgIcon
                      name={knownCurr?.indexOf(i) >= 0 ? i : 'all-0'}
                      className={styles.logo}
                    />
                    {i}
                  </FlexBox>
                </FlexBox>
              ))}
            </FlexBox>
          )}
        </>
      </>
    </FlexBox>
  );
};
export default Wallet;
