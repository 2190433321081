import React from 'react';

import { CustomFC } from '@monorepo/type';

import Box from '../Box';
import Skeleton from '../skeletons/Skeleton';

type TDefaultSkeletonProps = {};

const DefaultSkeleton: CustomFC<TDefaultSkeletonProps> = () => (
  <Box>
    <Skeleton />
  </Box>
);

export default DefaultSkeleton;
