import React, { ElementRef, useCallback, useRef, useState } from 'react';

import {
  AUTHENTICATION_STATUS,
  AuthLink,
  SubmitButton,
  TAuthData,
  TLoginRequest,
  TwoFactorWrapper,
  useActions,
  useLocalActions,
  useLogin
} from '@monorepo/auth';
import { useLogoutMutation } from '@monorepo/auth/src/store/user';
import { Recaptcha, RECAPTCHA_ACTIONS } from '@monorepo/common';
import {
  CheckboxField,
  Form,
  InputField,
  requiredValidator
} from '@monorepo/forms';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  useShutterRef
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  redirectTo?: string;
};

const defaultValues: TLoginRequest = {
  login: '',
  password: ''
};

const LoginForm: Component<Props> = ({ redirectTo }) => {
  const recaptchaHandlers = useRef<ElementRef<typeof Recaptcha>>(null);
  const formRef = useRef<ElementRef<typeof Form>>(null);
  const [isValidCaptcha, setIsValidCaptcha] = useState<boolean>(true);
  const { t } = useI18n(NAMESPACES.FORMS);
  const { generalRoutes } = useSiteSettings();
  const { restorePassword } = generalRoutes || {};
  const { onLogin, isLoading, handleRedirect } = useLogin({
    redirectTo,
    onError: (error: any) => {
      if (error.status === 27) {
        recaptchaHandlers.current?.setV2Key(error.captchaData);
        setIsValidCaptcha(false);
      }
    }
  });
  const { setIsAuthenticated } = useActions();
  const [signAuthData, setSignAuthData] = useState<TLoginRequest | {}>({});
  const { setAuthData } = useLocalActions();
  const { shutterRef, setShow, setHide } = useShutterRef();
  const onSignInHandle = useCallback(() => {
    const { userId, authToken } = signAuthData as TAuthData;
    setHide();
    setAuthData({ userId, authToken });
    setIsAuthenticated({
      isAuthenticated: true,
      isAuthFetched: true
    });
    handleRedirect();
  }, [JSON.stringify(signAuthData)]);
  const setIsValidForm = async (token: string | boolean) => {
    const data = formRef.current?.getValues();
    setIsValidCaptcha(!!token);
    if (data) {
      const payload =
        typeof token === 'string'
          ? { ...data, gRecaptchaResponse: token }
          : data;
      const signUpResponse = await onLogin(payload as TLoginRequest);
      if (!signUpResponse) {
        return;
      }
      setSignAuthData(signUpResponse);

      if (
        signUpResponse?.authenticationStatus ===
        AUTHENTICATION_STATUS.NEED_AUTHENTICATION
      ) {
        setShow();
      }
    }
  };
  const [logoutMutation] = useLogoutMutation();
  const handleClose = async () => {
    await logoutMutation(undefined);
    setHide();
  };

  const handleSubmit = () => recaptchaHandlers.current?.validateRecaptcha();

  return (
    <>
      <TwoFactorWrapper
        handleChange={onSignInHandle}
        toneType={FIELD_TONE.light}
        handleClose={handleClose}
        shutterRef={shutterRef}
        signAuthData={signAuthData}
      />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        formSettings={{ defaultValues, mode: 'onChange' }}
      >
        <InputField<TLoginRequest>
          id="userName"
          name="login"
          label={`${t('login.form.email.label')} / ${t(
            'registration.form.nickName.label'
          )}`}
          placeholder={`${t('login.form.email.placeholder')} / ${t(
            'registration.form.nickName.placeholder'
          )}`}
          className={styles.loginInput}
          isClearable
          rules={{
            ...requiredValidator()
          }}
          toneType={FIELD_TONE.light}
        />
        <InputField<TLoginRequest>
          id="password"
          name="password"
          type="password"
          label={t('login.form.password.label')}
          placeholder={t('login.form.password.placeholder')}
          className={styles.loginInput}
          rules={{ ...requiredValidator() }}
          toneType={FIELD_TONE.light}
        />

        <FlexBox
          justify={FLEX_JUSTIFY.between}
          align={FLEX_ALIGN.center}
          className={styles.wrapper}
        >
          <CheckboxField
            label={t('login.form.remember')}
            id="remember"
            name="remember"
            className={styles.checkbox}
            toneType={FIELD_TONE.light}
          />

          <AuthLink to={restorePassword}>
            {t('login.link.forgotpassword')}
          </AuthLink>
        </FlexBox>

        <Recaptcha
          isV2Only
          isManual
          action={RECAPTCHA_ACTIONS.LOGIN}
          onValidated={setIsValidForm}
          ref={recaptchaHandlers}
        />

        <SubmitButton
          disabled={!isValidCaptcha}
          processing={isLoading}
          data-custom="login-submit-button"
        >
          {t('login.form.submit')}
        </SubmitButton>
      </Form>
    </>
  );
};

export default LoginForm;
