import { camelizeKeys } from 'humps';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { TMarketTypeItem } from '../../../types';

export const normalizeMarketTypesResponse = (
  rawResponse: Record<string, any>
): Array<TMarketTypeItem> =>
  sortBy(get(camelizeKeys(rawResponse), 'details', []), 'order');
