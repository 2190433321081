import { xml } from '@xmpp/client';

import { IQ_TYPE } from '../../constants';

export const getIqQuery = (ns: string, to?: string) => {
  const iq = xml('iq', { type: IQ_TYPE.GET });
  const query = xml('query', ns);

  if (to) {
    iq.attrs.to = to;
  }

  iq.append(query);

  return iq;
};
