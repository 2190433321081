import { camelizeKeys } from 'humps';

import { UGC_QUERY_TAG } from '../../../constants';
import { TGetChatInfoRequest } from '../../../types';

const getChatInfo = () => ({
  query: ({ roomId, jwt }: TGetChatInfoRequest) => ({
    url: `/chat_info/${roomId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    }
  }),
  providesTags: (
    result: unknown,
    error: unknown,
    { roomId }: TGetChatInfoRequest
  ) => [{ type: UGC_QUERY_TAG.CHAT_INFO, id: roomId }],
  transformResponse: camelizeKeys
});

export default getChatInfo;
