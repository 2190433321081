import { useCallback } from 'react';

import { useSelectJwtAuth } from '@monorepo/auth';
import {
  useGetFavoriteIdsData,
  useUpdateFavoritesMutation
} from '@monorepo/cms';

const useUpdateFavoritesIds = () => {
  const favorites = useGetFavoriteIdsData();
  const jwt = useSelectJwtAuth();
  const [onSave, { isLoading }] = useUpdateFavoritesMutation();

  const onSaveFavIds = useCallback(
    (id: number | string, type: string) =>
      onSave({
        jwt,
        favorites: {
          ...favorites,
          [type]: [...(favorites[type] || []), id]
        }
      }).reset(),
    [onSave, favorites, jwt]
  );

  const onRemoveAllFavIds = useCallback(
    (type: string) =>
      onSave({
        jwt,
        favorites: { ...favorites, [type]: [] }
      }).reset(),
    [onSave, favorites, jwt]
  );

  const onRemoveFavIds = useCallback(
    (id: number | string, type: string) => {
      const updatedFavorites = favorites[type]?.filter(
        (favoriteId: number | string) => favoriteId !== id
      );

      return onSave({
        jwt,
        favorites: { ...favorites, [type]: updatedFavorites }
      }).reset();
    },
    [onSave, favorites, jwt]
  );

  return {
    onSaveFavIds,
    onRemoveAllFavIds,
    onRemoveFavIds,
    isLoading,
    isDisabled: !jwt
  };
};

export default useUpdateFavoritesIds;
