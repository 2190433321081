import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { Modal, ModalWrap, Portal, TShutterHandler } from '@monorepo/ui-kit';
import { Deposit, DepositMobile } from '@monorepo/wallet';

import styles from './index.module.css';

type TShutterCancelBonus = {
  handleClose: () => void;
  classOverlayModal?: string;
  classContentModal?: string;
  config?: any;
};

const DepositModal: ForwardRefRenderFunction<
  TShutterHandler,
  TShutterCancelBonus
> = (
  { handleClose, classOverlayModal = '', classContentModal = '', config },
  ref
) => {
  const { isDesktop } = useDeviceDetector();

  return (
    <Portal>
      {/* @ts-ignore */}
      <Modal
        classOverlay={classOverlayModal}
        classContent={classContentModal}
        ref={ref}
        isHasOverlay
        alwaysCenter
      >
        <ModalWrap
          handleClose={handleClose}
          wrapClassName={styles.modalWrap}
          headerClassName={styles.header}
        >
          {isDesktop ? (
            <Deposit config={config} />
          ) : (
            <DepositMobile config={config} />
          )}
        </ModalWrap>
      </Modal>
    </Portal>
  );
};
export default forwardRef(DepositModal);
