import React, { useMemo } from 'react';

import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { Box, Carousel, PaddingWrapper } from '@monorepo/ui-kit';
import { PaymentImage, useGetPayments } from '@monorepo/wallet';

import styles from './index.module.css';

type TPayment = Record<string, any>;
const PaymentsList: Component = () => {
  const payments = useGetPayments();
  const cmsHost = import.meta.env.VITE_APP_CMS_IMAGES_HOST;
  const { siteId } = useSiteSettings();

  const slides = useMemo(
    () =>
      (payments || []).map((payment: TPayment) => ({
        id: payment.PaymentSystem_id,
        component: (
          <PaymentImage
            imgSrc={`${cmsHost}/content/images/payments/custom/${siteId}/${payment.PaymentSystem_id}.png`}
            displayName={payment.displayName}
            imageFromCms
            serviceId={payment.PaymentSystem_id}
            className={styles.wrap}
            imgClassName={styles.img}
          />
        )
      })),
    [payments, cmsHost, siteId]
  );

  if (slides?.length < 1) return null;

  return (
    <PaddingWrapper isOnlyMobile className={styles.container}>
      <Box>
        <Carousel
          slides={slides}
          className={styles.slider}
          slideClassName={styles.item}
          useSwiper
          params={{
            slidesPerView: 'auto',
            spaceBetween: 12,
            autoplay: {
              pauseOnMouseEnter: true,
              disableOnInteraction: false,
              delay: 6000
            },
            speed: 1000,
            freeMode: {
              enabled: true,
              momentumBounce: false,
              momentumRatio: 0.2,
              minimumVelocity: 0.01,
              momentum: true
            },
            lazy: true
          }}
        />
      </Box>
    </PaddingWrapper>
  );
};
export default PaymentsList;
