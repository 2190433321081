import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const Carousel = lazy(() => import('./Carousel'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Carousel {...props} />
  </Suspense>
);
