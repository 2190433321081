import { useSelectJwtAuth } from '@monorepo/auth';
import { TPrivacySettings, useUpdatePlayerInfoMutation } from '@monorepo/cms';
import {
  useNotifications,
  userNotificationActions
} from '@monorepo/notification';

const useSetCookiesNotification = () => {
  const jwt = useSelectJwtAuth();
  const [onSave, { isLoading }] = useUpdatePlayerInfoMutation();
  const { errorNotification } = useNotifications();
  const { setCookiesSettings } = userNotificationActions();

  const onSaveCookies = async (userValue: TPrivacySettings) => {
    const { alwaysActive, value, perfomanceData, targetingData } = userValue;
    try {
      await onSave({
        jwt,
        // @TODO: change private_settings to privacy_settings
        private_settings: {
          // @TODO: add third_party_content
          always_active: alwaysActive,
          value,
          perfomance_data: perfomanceData,
          targeting_data: targetingData
        }
      }).unwrap();
      setCookiesSettings(userValue);
    } catch (error) {
      const { data } = error;
      const { message } = data;
      errorNotification(message);
    }
  };
  return { onSaveCookies, isLoading };
};
export default useSetCookiesNotification;
