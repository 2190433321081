import React, { useMemo } from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { useIsAuthenticated } from '@monorepo/auth';
import { transformMenuItems, useGetMenu } from '@monorepo/cms';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import { Box } from '@monorepo/ui-kit';

import Menu from './components/Menu';
import MenuMobile from './components/MenuMobile';

import styles from './index.module.css';

type Props = {
  menuId: number;
};
const FooterNavigationMenu: Component<Props> = ({ menuId }) => {
  const { isLoading, data: cmsMenuItems } = useGetMenu(menuId);
  const { isAuthenticated } = useIsAuthenticated();
  const { isDesktop } = useDeviceDetector();
  const { generalRoutes } = useSiteSettings();
  const { contentPage } = generalRoutes || {};
  const menuItem = useMemo(
    () => transformMenuItems(cmsMenuItems, isAuthenticated, contentPage),
    [cmsMenuItems]
  );

  if (isLoading) {
    return null;
  }

  return menuItem.length ? (
    <Box className={styles.menu}>
      {isDesktop ? (
        <Box className={styles.wrap}>
          <Menu items={menuItem} />
        </Box>
      ) : (
        <Box className={styles.mobileWrap}>
          <MenuMobile items={menuItem} />
        </Box>
      )}
    </Box>
  ) : null;
};
export default FooterNavigationMenu;
