import React from 'react';
import { ToastContainer } from 'react-toastify';

import { useIsAuthenticated } from '@monorepo/auth';
import { Component } from '@monorepo/type';

import { LIMIT_HEADER_NOTIFICATIONS } from '../../constants';

import 'react-toastify/dist/ReactToastify.css';
import styles from './index.module.css';

const Toasts: Component = ({ className }) => {
  const { isAuthenticated } = useIsAuthenticated();
  const classes = className || styles.toastContainer;

  if (!isAuthenticated) {
    return null;
  }

  return (
    <ToastContainer
      className={classes}
      toastClassName={styles.toast}
      bodyClassName={styles.toastBody}
      progressClassName={styles.progress}
      pauseOnHover
      theme="light"
      limit={LIMIT_HEADER_NOTIFICATIONS}
      closeOnClick={false}
    />
  );
};

export default Toasts;
