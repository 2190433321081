import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const CasinoLastPlayedGames = lazy(() => import('./CasinoLastPlayedGames'));

export default ({ config }: ComponentProps<typeof CasinoLastPlayedGames>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <CasinoLastPlayedGames config={config} />
  </Suspense>
);
