import React from 'react';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { FIELD_TONE, ModalWrapper } from '@monorepo/ui-kit';

import TwoFactorAuth from '../TwoFactorAuth';

import styles from './index.module.css';

const TwoFactorWrapper = ({
  handleClose,
  handleChange,
  shutterRef,
  toneType,
  signAuthData
}: {
  handleClose?: () => void;
  handleChange?: ((data?: any) => void | Promise<void>) | undefined;
  shutterRef: any;
  toneType?: FIELD_TONE;
  signAuthData?: any;
}) => {
  const { t } = useI18n(NAMESPACES.ACCOUNT);
  return (
    <ModalWrapper
      classOverlayModal={styles.overlay}
      handleClose={handleClose}
      titleClassName={styles.title}
      header={t('two.factor.authentication')}
      ref={shutterRef}
    >
      <TwoFactorAuth
        qrCode={signAuthData?.qrCodeOrigin || ''}
        toneType={toneType}
        onCustomHandler={handleChange}
      />
    </ModalWrapper>
  );
};

export default TwoFactorWrapper;
