import React from 'react';

import { CustomFC } from '@monorepo/type';

import { SvgIconProps } from '../../types';

import svgIcons from './icons';

import styles from './index.module.css';

const SvgIcon: CustomFC<SvgIconProps> = ({
  name,
  className = '',
  title = '',
  defaultIcon = ''
}) => {
  const Component =
    svgIcons[name as keyof typeof svgIcons] ||
    svgIcons[defaultIcon as keyof typeof svgIcons];

  if (!Component) {
    return null;
  }

  return <Component title={title} className={`${styles.svg} ${className}`} />;
};

export default SvgIcon;
