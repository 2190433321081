import useAuthLogin from '../useAuthLoginV2';
import useGetJWT from '../useGetJWT';

const useAuthLoginSWARM = () => {
  const { data } = useGetJWT();

  useAuthLogin(data);
};

export default useAuthLoginSWARM;
