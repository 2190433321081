import { useGetPlayerProfileSettingsQuery } from '@monorepo/cms';
import { useSiteSettings } from '@monorepo/site-settings';

import { useSelectJwtAuth } from '../../index';

const useGetPlayerProfileSettings = (target: number, options = {}) => {
  const { skip = false, ...restOptions } = options;
  const { siteId } = useSiteSettings();
  const jwt = useSelectJwtAuth();

  return useGetPlayerProfileSettingsQuery(
    {
      userId: target,
      partnerId: siteId,
      jwt
    },
    {
      skip: !jwt || skip,
      ...restOptions
    }
  );
};

export default useGetPlayerProfileSettings;
