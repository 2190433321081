import React from 'react';

import { Component } from '@monorepo/type';

import { useMenuRegionsData } from '../../../../hooks/useGetMenuRegions';
import useSportsURLParams from '../../../../hooks/useSportsURLParams';
import { selectCompetitionsByRegionIdCompetitionId } from '../../../../store/api/selectors';
import { formatIconNameFromAlias } from '../../../../utils';
import CompetitionMenuItem from '../../../CompetitionMenuItem';

type TCompetitionProps = {
  competitionId: number;
  regionId: number;
  withIcons: boolean;
  basePath: string;
  activeSport?: string;
  region?: string;
};

const Competition: Component<TCompetitionProps> = ({
  withIcons,
  basePath,
  competitionId,
  regionId,
  region,
  activeSport
}) => {
  const { time } = useSportsURLParams();
  const { data: competition } = useMenuRegionsData(
    (result) =>
      selectCompetitionsByRegionIdCompetitionId(
        result,
        regionId,
        competitionId
      ),
    { sportAlias: activeSport },
    { refetchOnMountOrArgChange: false }
  );
  const { id, name, game } = competition;

  return (
    <CompetitionMenuItem
      link={`${basePath}/${id}${time ? `?time=${time}` : ''}`}
      title={name}
      count={game}
      iconName={formatIconNameFromAlias(region)}
      withIcons={withIcons}
    />
  );
};

export default Competition;
