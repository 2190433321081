export const TOURNAMENT_STATUS = {
  ALL: '',
  CANCELED: -1,
  FINISHED: 1,
  LIVE: 2,
  UPCOMING: 3
};

export enum STATUS_VALUE {
  CANCELED = 'Canceled',
  FINISHED = 'Finished',
  LIVE = 'Live',
  UPCOMING = 'Upcoming',
  ALL = 'All'
}

export const TOURNAMENT_LABELS = {
  [TOURNAMENT_STATUS.ALL]: STATUS_VALUE.ALL,
  [TOURNAMENT_STATUS.CANCELED]: STATUS_VALUE.CANCELED,
  [TOURNAMENT_STATUS.FINISHED]: STATUS_VALUE.FINISHED,
  [TOURNAMENT_STATUS.LIVE]: STATUS_VALUE.LIVE,
  [TOURNAMENT_STATUS.UPCOMING]: STATUS_VALUE.UPCOMING
};

export const TOURNAMENTS_QUERY_KEYS = {
  TOURNAMENT: 'TOURNAMENT',
  TOURNAMENT_LIST: 'TOURNAMENT_LIST'
};

export enum TOURNAMENT_TYPE_ID {
  CLASSIK = 1,
  TICKETBASED = 2
}

export enum ENTRY_TYPE {
  BUY_IN = 'Buy-in',
  FREE = 'Free Entry'
}

export enum REGISTRATION_STATUS {
  FINISHED = 'Registration Finished',
  STARTED = 'Registration Started'
}
