import { TQueryResult } from '@monorepo/type';

import { useGetCompetitionsWithSportsQuery } from '../../store/sportsbook';
import { TGetCompetitionsWithSportsArgs } from '../../types';
import useGetData from '../useGetData';

const useGetCompetitionsWithSports = (
  args: TGetCompetitionsWithSportsArgs,
  options = {}
) => useGetCompetitionsWithSportsQuery(args, options);

export const useCompetitionsWithSportsData = <
  ReturnType = any,
  QueryResultType = any
>(
  dataSelector: (result: TQueryResult<QueryResultType>) => any,
  queryArgs: Partial<TGetCompetitionsWithSportsArgs> = {},
  queryOptions: Record<string, any> = {}
) =>
  useGetData<ReturnType, QueryResultType>(
    useGetCompetitionsWithSports,
    dataSelector,
    queryArgs,
    queryOptions
  );

export default useGetCompetitionsWithSports;
