import React, { forwardRef, ReactElement } from 'react';

import { SvgIcon } from '@monorepo/icons/';

import { FIELD_TONE, FLEX_ALIGN, FLEX_JUSTIFY } from '../../constants';
import { DropdownProps } from '../../types';
import FlexBox from '../FlexBox';
import Select from '../forms/Select';

import styles from './index.module.css';

const Dropdown = forwardRef<HTMLSelectElement, DropdownProps>(
  (
    {
      toneType = FIELD_TONE.dark,
      options,
      disabled,
      className = '',
      register,
      icon
    },
    ref
  ): ReactElement => (
    <FlexBox className={styles.wrapper}>
      {icon && (
        <FlexBox
          align={FLEX_ALIGN.center}
          justify={FLEX_JUSTIFY.center}
          className={styles.icon}
        >
          <SvgIcon name={icon as any} className={styles.iconSvg} />
        </FlexBox>
      )}
      <Select
        ref={ref}
        className={`${className} ${styles.select}`}
        controlClassName={`${icon ? styles.selectControl : ''}`}
        options={options}
        disabled={disabled}
        toneType={toneType}
        {...register}
      />
    </FlexBox>
  )
);

export default Dropdown;
