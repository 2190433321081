import React, { lazy, Suspense } from 'react';

import DefaultSkeleton from '../DefaultSkeleton';

const LazyLoadImage = lazy(() => import('./LazyLoadImage'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <LazyLoadImage {...props} />
  </Suspense>
);
