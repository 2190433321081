import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { FILTER_PARAMS, useSearchParamsWithParsing } from '@monorepo/helpers';
import { ICON_TYPE, SvgIcon } from '@monorepo/icons';
import { useComponentsConfig } from '@monorepo/page';
import { Component } from '@monorepo/type';
import {
  Box,
  EllipsisText,
  getTooltipDataAttributes,
  HighlightedSearchText
} from '@monorepo/ui-kit';

import MenuCount from '../MenuCount';
import SportColor from '../SportColor';
import SportIcon from '../SportIcon';

import styles from './index.module.css';

type Props = {
  link: string;
  count: number;
  title: string;
  iconName?: string;
  iconType?: string;
};

const Competition: Component<Props> = ({
  link,
  count,
  title,
  iconName = '',
  iconType = ICON_TYPE.SVG,
  className = ''
}) => {
  const { searchParams } = useSearchParamsWithParsing();
  const [isOverflowed, setIsOverflow] = useState(false);
  const menuItemRef = useRef<HTMLSpanElement>(null);

  const { withIcons } = useComponentsConfig<{ withIcons?: boolean }>();

  useEffect(() => {
    if (menuItemRef.current) {
      const { scrollWidth, clientWidth } = menuItemRef.current;
      setIsOverflow(scrollWidth > clientWidth);
    }
  }, [menuItemRef.current]);

  const tooltipAttrs = useMemo(
    () =>
      getTooltipDataAttributes({
        place: 'top',
        content: title,
        hidden: !isOverflowed
      }),
    [isOverflowed, title]
  );

  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        `${styles.wrap} ${className} ${isActive ? styles.active : ''}`
      }
    >
      {withIcons &&
        (iconType === ICON_TYPE.SVG ? (
          <Box className={styles.iconContainer}>
            <SvgIcon
              defaultIcon="world"
              name={iconName}
              className={styles.icon}
            />
          </Box>
        ) : (
          <SportColor alias={iconName}>
            <Box className={styles.icon}>
              <SportIcon
                name={iconName.toLowerCase()}
                className={styles.sport}
              />
            </Box>
          </SportColor>
        ))}
      <EllipsisText
        ref={menuItemRef}
        className={styles.title}
        {...tooltipAttrs}
      >
        <HighlightedSearchText
          searchText={searchParams[FILTER_PARAMS.search] as string}
          className={styles.searched}
          replacingText={title}
        />
      </EllipsisText>
      <MenuCount count={count} />
    </NavLink>
  );
};

export default Competition;
