import { TUpdatePlayerInformation } from '../../../../types';
import { CUSTOM_API_QUERY_KEYS } from '../../constants';

const updatePlayerInformation = () => ({
  query: ({ jwt, ...args }: TUpdatePlayerInformation) => ({
    url: '/player',
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    },
    body: args
  }),
  invalidatesTags: [CUSTOM_API_QUERY_KEYS.PLAYER_INFORMATION]
  // async onQueryStarted(data: any, { dispatch, queryFulfilled }) {
  //   const { data: bodyParams, ...args } = data;
  //   const patchResult = dispatch(
  //     customApi.util.updateQueryData('getPlayerInfo', args, (draft: any) => {
  //       Object.assign(draft, bodyParams);
  //     })
  //   );
  //   try {
  //     await queryFulfilled;
  //   } catch {
  //     patchResult.undo();
  //   }
  // }
});

export default updatePlayerInformation;
