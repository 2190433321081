import React, { ChangeEvent, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import concat from 'lodash/concat';
import pull from 'lodash/pull';

import {
  useGetUser,
  useIsAuthenticated,
  useSelectAuthData
} from '@monorepo/auth';
import {
  getLocalStorageValue,
  setLocalStorageValue,
  toSeconds,
  useAnimationFrame
} from '@monorepo/helpers';
import { Component } from '@monorepo/type';
import { ModalWrapper, TModalHandler } from '@monorepo/ui-kit';

import CMSPopup from '../../common/CMSPopup';

import styles from './index.module.css';

type TModalNotification = {
  classOverlayModal?: string;
  classContentModal?: string;
  popup: Record<string, any>;
  page?: string;
};

const getPathNames = (urls: string[]) =>
  urls.map((url) => {
    try {
      const urlObject = new URL(url);
      return urlObject.pathname;
    } catch {
      return `/${url}`;
    }
  });

const Popup: Component<TModalNotification> = ({
  popup,
  classOverlayModal = '',
  classContentModal = ''
}) => {
  const stylesOverlay = `${styles.overlay} ${classOverlayModal}`;
  const ref = useRef<TModalHandler>(null);
  const { showTo, showOnPages, depositType, id, repeatTime, title } = popup;

  const { pathname } = useLocation();
  const { isAuthenticated, isAuthFetched } = useIsAuthenticated();
  const authData = useSelectAuthData();
  const { data: profile } = useGetUser();
  const { id: userId, balance } = profile || {};
  const addToDontShow = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;

    const storageKey = `blockedPopups_${!userId ? 'guest' : userId}`;

    const blockedPopups = getLocalStorageValue(storageKey, []);

    if (isChecked) {
      const idsList = blockedPopups?.includes(id)
        ? blockedPopups
        : concat(blockedPopups, id);
      setLocalStorageValue(storageKey, idsList);
    } else {
      const idsList = blockedPopups?.includes(id)
        ? pull(blockedPopups, id)
        : blockedPopups;
      setLocalStorageValue(storageKey, idsList);
    }
  };
  const handleClose = () => {
    if (ref?.current) {
      ref.current.setIsShow(false);
      if (repeatTime !== 0) {
        const currentDateInSec = toSeconds();
        const showTime = currentDateInSec + repeatTime * 60 * 60;
        setLocalStorageValue(
          `popup_${!userId ? 'guest' : userId}_${id}`,
          showTime
        );
      } else {
        setLocalStorageValue(`popup_${!userId ? 'guest' : userId}_${id}`, 0);
      }
    }
  };

  const isShowPopup = useMemo(() => {
    const showByBalance =
      (balance === 0 && depositType === 2) ||
      (balance !== 0 && depositType === 1) ||
      depositType === 0;
    const showFor =
      showTo === 0 || (isAuthenticated ? showTo === 1 : showTo === 2);
    const pathFromPopup = getPathNames(showOnPages);
    return pathFromPopup.includes(pathname) && showByBalance && showFor;
  }, [balance, depositType, isAuthenticated, showTo, showOnPages]);

  const handleShowPopup = () => {
    const popupShowTime = getLocalStorageValue(
      `popup_${!isAuthenticated ? 'guest' : authData?.userId}_${id}`
    );
    if (
      popupShowTime === undefined ||
      (popupShowTime && toSeconds() > popupShowTime)
    ) {
      ref.current?.setIsShow(true);
    }
  };

  useEffect(() => {
    if (isAuthFetched && isShowPopup) {
      handleShowPopup();
    }
  }, [isAuthenticated, isAuthFetched, isShowPopup]);

  useAnimationFrame({
    delay: 60000,
    shouldAnimate: isShowPopup,
    callback: handleShowPopup
  });

  return (
    <ModalWrapper
      ref={ref}
      classOverlayModal={stylesOverlay}
      classContentModal={classContentModal}
      handleClose={handleClose}
      iconClass={styles.iconPopup}
      header={title}
      alwaysCenter
    >
      <CMSPopup
        popup={popup}
        handleClose={handleClose}
        isWithCheckbox
        addToDontShow={addToDontShow}
      />
    </ModalWrapper>
  );
};
export default Popup;
