import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TGetCurrentPlayerArgs,
  TGetSearchPlayerArgs,
  TPlayerInformation,
  TPlayerInformationGetArgs,
  TPlayerProfileResponse,
  TUpdatePlayerInformation,
  TUpdatePublicProfileInformation
} from '../../types';

import getPlayerInformation from './api/getPlayerInformation';
import getPlayerProfileSettings from './api/getPlayerProfileSettings';
import getSearchPlayers from './api/getSearchPlayers';
import updateFavorites from './api/updateFavorites';
import updatePlayerInformation from './api/updatePlayerInformation';
import updatePlayerProfileSettings from './api/updatePlayerProfileSettings';
import { CUSTOM_API_QUERY_KEYS } from './constants';

export const customApi = createApi({
  reducerPath: 'customApi',
  baseQuery: fetchBaseQuery({
    // validateStatus: validateApiResponse,
    baseUrl: `/api/v1`
  }),
  tagTypes: [CUSTOM_API_QUERY_KEYS.PLAYER_INFORMATION],
  endpoints: (builder) => ({
    getPlayerInfo: builder.query<TPlayerInformation, TGetCurrentPlayerArgs>(
      getPlayerInformation()
    ),
    getSearchPlayers: builder.query<TPlayerInformation[], TGetSearchPlayerArgs>(
      getSearchPlayers()
    ),
    updatePlayerInfo: builder.mutation<unknown, TUpdatePlayerInformation>(
      updatePlayerInformation()
    ),
    getPlayerProfileSettings: builder.query<
      TPlayerProfileResponse | null,
      TPlayerInformationGetArgs
    >(getPlayerProfileSettings()),
    updatePlayerProfileSettings: builder.mutation<
      unknown,
      TUpdatePublicProfileInformation
    >(updatePlayerProfileSettings()),
    updateFavorites: builder.mutation<unknown, TUpdatePlayerInformation>(
      updateFavorites()
    )
  })
});

export const {
  useGetPlayerInfoQuery,
  useLazyGetPlayerInfoQuery,
  useUpdatePlayerInfoMutation,
  useUpdateFavoritesMutation,
  useGetSearchPlayersQuery,
  useGetPlayerProfileSettingsQuery,
  useUpdatePlayerProfileSettingsMutation
} = customApi;
// todo actualize types
