import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TAvailableTournamentsIds,
  TAvailableTournamentsRequest,
  TCategories,
  TCategoriesRequest,
  TGetGamesImagesRequest,
  TGetGamesRequest,
  TImages,
  TNormalizedGame,
  TNormalizedGamesResponse,
  TTournamentGamesRequest
} from '../types';

import getAvailableTournaments from './api/getAvailableTournaments';
import getCategories from './api/getCategories';
import getGamesByIds from './api/getGamesByIds';
import getGamesImages from './api/getGamesImages';
import getLobbyGames from './api/getLobbyGames';
import getProviders from './api/getProviders';
import getTopGame from './api/getTopGame';
import getTournamentGames from './api/getTournamentGames';

const cmsHost = import.meta.env.VITE_APP_CMS_HOST;

export const casinoApi = createApi({
  reducerPath: 'casinoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${cmsHost}/casino/`,
    validateStatus: (response, result) =>
      response.status === 200 && result?.status === 'ok'
  }),
  endpoints: (builder) => ({
    getCategories: builder.query<TCategories[], TCategoriesRequest>(
      getCategories()
    ),
    getProviders: builder.query(getProviders()),
    getTopGame: builder.query(getTopGame()),
    getLobbyGames: builder.query(getLobbyGames()),
    getTournamentGames: builder.query<
      TNormalizedGamesResponse,
      TTournamentGamesRequest
    >(getTournamentGames()),
    getAvailableTournaments: builder.query<
      TAvailableTournamentsIds,
      TAvailableTournamentsRequest
    >(getAvailableTournaments()),
    getGamesByIds: builder.query<TNormalizedGame[], TGetGamesRequest>(
      getGamesByIds()
    ),
    getGamesImages: builder.query<TImages, TGetGamesImagesRequest>(
      getGamesImages()
    )
  })
});

export const {
  useGetGamesImagesQuery,
  useGetCategoriesQuery,
  useGetProvidersQuery,
  useGetTopGameQuery,
  useGetLobbyGamesQuery,
  useGetTournamentGamesQuery,
  useGetAvailableTournamentsQuery,
  useGetGamesByIdsQuery
} = casinoApi;
