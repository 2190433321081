import Element from 'ltx/src/Element';

import { parseStanzaTypeError } from '../../../../utils/stanzaParsers';

export default class IqErrorHandler {
  public processStanza = (stanza: Element) => {
    const error = parseStanzaTypeError(stanza);
    console.error(`IQ error: ${JSON.stringify(error)}`);
  };
}
