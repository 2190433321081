import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import getGeo from './api/getGeo';

const geoApiHost = import.meta.env.VITE_APP_GEO_URL;

export const geoApi = createApi({
  reducerPath: 'geoApi',
  baseQuery: fetchBaseQuery({ baseUrl: geoApiHost }),
  endpoints: (builder) => ({
    getCountry: builder.query<{ countryCode: string }, void>(getGeo())
  })
});

export const { useGetCountryQuery } = geoApi;
