import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const PreMatchSportTabs = lazy(() => import('./PreMatchSportTabs'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <PreMatchSportTabs {...props} />
  </Suspense>
);
