import get from 'lodash/get';

import { TNormalizedCompetitions } from '../../../types';

export const normalizeCompetitionsResponse = (
  rawResponse: Record<string, any>
): TNormalizedCompetitions => {
  const competition = get(rawResponse, 'data.competition', {});
  const subid = get(rawResponse, 'subid', '');
  return {
    competition,
    subid
  };
};
