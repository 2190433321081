import { jid } from '@xmpp/client';
import Element from 'ltx/src/Element';

import {
  createBareJidFromId,
  createStanzaId,
  getBareJID,
  getIdFromJID,
  isJIDsEqual,
  MESSAGES_TYPES,
  MUC_PRESENCE_STATUS,
  parseJid,
  XML_NAMESPACES
} from '@monorepo/xmpp';

import { MESSAGE_DIRECTION, MESSAGE_MARK } from '../../constants/messages';
import {
  TCommunityDiscoInfo,
  TCommunityItem,
  TCommunityPermission,
  TInboxConversationMessage,
  TMucUserXData,
  TPermissionItem,
  TRoomRegisterResult,
  TStanzaError,
  TStoreInboxMessage,
  TStoreMessage
} from '../../types';
import { getUserIdFromNick } from '../nickname';

const getDelay = (stanza: Element) => {
  const delay = stanza.getChild('delay', XML_NAMESPACES.DELAY);
  const { stamp, from } = delay?.attrs || {};
  return { stamp, from };
};

const parseGroupFrom = (from: string) => {
  const { resource } = parseJid(from);
  const roomJid = getBareJID(from).toString();
  const roomId = getIdFromJID(from);
  if (!resource) {
    return { roomJid, roomId };
  }
  const userId = getUserIdFromNick(resource);
  return {
    nickname: resource,
    userId,
    userJid: createBareJidFromId(userId).toString(),
    roomJid,
    roomId
  };
};

const getDirection = (myJid: string, from?: string, type?: string) => {
  if (!from) {
    return MESSAGE_DIRECTION.OUT;
  }
  if (type === MESSAGES_TYPES.SINGLE) {
    return isJIDsEqual(from, myJid)
      ? MESSAGE_DIRECTION.OUT
      : MESSAGE_DIRECTION.IN;
  }
  if (type === MESSAGES_TYPES.GROUP) {
    const { userJid } = parseGroupFrom(from);
    if (userJid) {
      return isJIDsEqual(userJid, myJid)
        ? MESSAGE_DIRECTION.OUT
        : MESSAGE_DIRECTION.IN;
    }
  }
  return MESSAGE_DIRECTION.SYSTEM;
};

const messageParse = (
  myJid: string,
  stanza?: Element
): Omit<TStoreMessage, 'delay' | 'date' | 'roomJid'> => {
  const { type, from, to, id } = stanza?.attrs || {};
  const text = stanza?.getChildText('body') || '';
  const sid = stanza?.getChild('stanza-id', XML_NAMESPACES.STANZA_ID);

  return {
    id: id || createStanzaId(jid(from).getLocal()),
    from: from || to,
    to,
    type,
    text,
    mark: MESSAGE_MARK.TRANSFERRED,
    direction: getDirection(myJid, from, type),
    mamId: sid?.attrs.id
  };
};

export const chatMessageParse = (
  stanza: Element,
  myJid: string
): TStoreMessage => {
  const message = messageParse(myJid, stanza);
  const { stamp } = getDelay(stanza);
  const roomJid = getBareJID(message.from).toString();

  return {
    ...message,
    roomJid,
    delay: stamp,
    date: stamp || new Date().toString()
  };
};

const parseMamMessage = (
  myJid: string,
  stanza?: Element
): Omit<TStoreMessage, 'delay' | 'date' | 'mamId' | 'queryId'> => {
  const parsedMessage = messageParse(myJid, stanza);
  const { to, from, type, direction } = parsedMessage;

  if (type === MESSAGES_TYPES.GROUP) {
    const groupDirection = getDirection(myJid, from, type);
    const { roomJid, userJid } = parseGroupFrom(from);

    return {
      ...parsedMessage,
      from: userJid || roomJid,
      to: roomJid,
      direction: groupDirection,
      roomJid
    };
  }
  const roomJid = getBareJID(
    direction === MESSAGE_DIRECTION.OUT ? to : from
  ).toString();

  return {
    ...parsedMessage,
    roomJid
  };
};

export const mamMessageParse = (
  stanza: Element,
  myJid: string
): TStoreMessage => {
  const result = stanza.getChild('result', XML_NAMESPACES.MAM);
  const { queryid: queryId, id: mamId } = result?.attrs || {};
  const forwarded = result?.getChild('forwarded', XML_NAMESPACES.FORWARD);
  const { stamp = '' } = forwarded ? getDelay(forwarded) : {};
  const message = forwarded?.getChild('message');
  const parsedMessage = parseMamMessage(myJid, message);

  return {
    ...parsedMessage,
    queryId,
    mamId,
    delay: stamp,
    date: stamp || new Date().toString()
  };
};

export const groupMessageParse = (
  stanza: Element,
  myJid: string
): TStoreMessage => {
  const message = messageParse(myJid, stanza);
  const { stamp } = getDelay(stanza);
  const { roomJid, userJid } = parseGroupFrom(message.from);

  return {
    ...message,
    roomJid,
    from: userJid || roomJid,
    delay: stamp,
    date: stamp || new Date().toString()
  };
};

/*
 * https://xmpp.org/extensions/xep-0045.html#invite-mediated
 */
export const mucUserXDataParse = (stanza: Element): TMucUserXData => {
  const { from: roomJid } = stanza?.attrs;
  const xData = stanza.getChild('x', XML_NAMESPACES.MUC_USER);
  const [firstChild] = xData?.getChildElements() || [];
  const { from } = firstChild?.attrs || {};
  const reason = firstChild?.getChildText('reason') || '';
  const text = stanza.getChildText('body') || '';

  return { from, reason, roomJid, invite: firstChild?.is('invite'), text };
};

/*
 * According to https://xmpp.org/rfcs/rfc3920.html#stanzas #9.3.2
 * message, presence, iq - have same error xml structure
 */
export const parseStanzaTypeError = (stanza: Element): TStanzaError => {
  let errorDefinedCondition;
  const { id } = stanza.attrs;
  const errorElement = stanza.getChild('error');
  const { type: errorType, code: errorCode } = errorElement?.attrs || {};
  const { children = [] } = errorElement || {};
  const textElement = errorElement?.getChild('text', XML_NAMESPACES.STANZAS);
  const errorText = textElement?.text();

  children.forEach((child: any) => {
    const { name, attrs } = child;
    const { xmlns } = attrs;
    if (name !== 'text' && xmlns === XML_NAMESPACES.STANZAS) {
      errorDefinedCondition = name;
    }
  });

  return {
    id,
    errorCode,
    errorType,
    errorText,
    errorDefinedCondition
  };
};

export const parsePresence = (stanza: Element) => {
  const { type, from, to } = stanza.attrs;
  const show = stanza.getChildText('show') || undefined;
  const status = stanza.getChildText('status') || undefined;
  const { stamp } = getDelay(stanza);

  return {
    type,
    from,
    to,
    status,
    show,
    delay: stamp
  };
};

export const parseField = (stanza: Element) => {
  const { var: key, type } = stanza.attrs;
  const value = stanza.getChildText('value');

  return { key, type, value };
};

export const parseXDada = (stanza?: Element) => {
  const fields = stanza?.getChildren('field') || [];

  return fields.reduce((acc: Record<string, any>, field: any) => {
    const { key, type, value } = parseField(field);
    if (type) {
      return acc;
    }
    return Object.assign({}, acc, { [key]: value });
  }, {});
};

export const parseXDataInstructions = (stanza: Element) => {
  const fields = stanza.getChildren('field');

  return fields.reduce((acc: Record<string, any>, field: any) => {
    const { key, type, value } = parseField(field);
    // eslint-disable-next-line
    return Object.assign({}, acc, { [key]: {value, type} });
  }, {});
};

export const parseRsm = (stanza?: Element) => {
  const first = stanza?.getChildText('first');
  const last = stanza?.getChildText('last');
  const count = stanza?.getChildText('count');
  const max = stanza?.getChildText('max');

  return {
    first,
    last,
    count: count ? Number(count) : 0,
    max: max ? Number(max) : 0
  };
};

export const parseUniqueRoomName = (stanza: Element): string =>
  stanza.getChildText('unique', XML_NAMESPACES.MUC_UNIQUE) || '';

export const parseCommunityList = (stanza: Element): TCommunityItem[] => {
  const query = stanza.getChild('query', XML_NAMESPACES.DISCO_ITEMS);
  const items = query?.getChildren('item') || [];

  return items.map((item: any) => {
    const { jid: JID, name } = item.attrs;

    return { jid: getBareJID(JID).toString(), id: getIdFromJID(JID), name };
  });
};

export const parseRoomDisco = (stanza: Element): TCommunityDiscoInfo => {
  const { from } = stanza.attrs;
  const query = stanza.getChild('query', XML_NAMESPACES.DISCO_INFO);
  const identity = query?.getChild('identity');
  const { name } = identity?.attrs || {};
  const xData = query?.getChild('x', XML_NAMESPACES.X_DATA);
  const parsedXData = parseXDada(xData);
  const description = parsedXData['muc#roominfo_description'] || '';
  const occupants = parsedXData['muc#roominfo_occupants']
    ? Number(parsedXData['muc#roominfo_occupants'])
    : 0;

  return {
    jid: getBareJID(from).toString(),
    id: getIdFromJID(from),
    name,
    description,
    occupants
  };
};

export const parseMucUserPresence = (stanza: Element): TCommunityPermission => {
  const { from, to, type } = stanza.attrs;
  const { roomId, roomJid, userJid, userId, nickname } = parseGroupFrom(from);
  const x = stanza.getChild('x', XML_NAMESPACES.MUC);
  const item = x?.getChild('item');
  const { jid: JID, affiliation, role } = item?.attrs || {};
  const contactJid = JID
    ? getBareJID(JID).toString()
    : userJid || getBareJID(to).toString();
  const contactId = JID ? getIdFromJID(JID) : userId || getIdFromJID(to);
  const reason = item?.getChildText('reason');
  const destroy = x?.getChild('destroy');
  const destroyReason = destroy?.getChildText('reason');
  const statuses = x?.getChildren('status') || [];
  const status = statuses.reduce((acc: MUC_PRESENCE_STATUS[], el: any) => {
    const code = el.getAttr('code');
    return code ? [...acc, code] : acc;
  }, []);

  const actor = item?.getChild('actor'); // todo delete after tests
  if (actor) {
    // eslint-disable-next-line no-console
    console.log('actor', actor.toString());
  }

  return {
    nickname,
    roomJid,
    roomId,
    userJid: contactJid,
    userId: contactId,
    affiliation,
    role,
    status,
    type,
    destroy: Boolean(destroy),
    reason: reason || destroyReason
  };
};

export const parseRoomRegister = (stanza: Element): TRoomRegisterResult => {
  const { from } = stanza.attrs;
  const query = stanza.getChild('query', XML_NAMESPACES.REGISTER);
  const registered = Boolean(query?.getChild('registered'));
  const userName = query?.getChildText('username') || '';
  const xData = query?.getChild('x', XML_NAMESPACES.X_DATA);
  const instructions = xData ? parseXDataInstructions(xData) : {};

  return {
    jid: getBareJID(from).toString(),
    id: getIdFromJID(from),
    registered,
    userName,
    instructions
  };
};

const parseRoomMemberItem = (stanza: Element): TPermissionItem => {
  const { jid: userJid, nick, affiliation, role } = stanza.attrs;

  return {
    userJid,
    userId: getIdFromJID(userJid),
    nick,
    affiliation,
    role
  };
};

export const parseRoomPermissionList = (stanza: Element): TPermissionItem[] => {
  const query = stanza.getChild('query');
  const itemElements = query?.getChildren('item') || [];

  return itemElements.map(parseRoomMemberItem);
};

const inboxMessageParse = (
  stanza: Element,
  myJid: string
): TStoreMessage | TMucUserXData => {
  const { type } = stanza?.attrs || {};

  if (type === MESSAGES_TYPES.GROUP) {
    return groupMessageParse(stanza, myJid);
  }
  if (type === MESSAGES_TYPES.SINGLE) {
    return chatMessageParse(stanza, myJid);
  }

  return mucUserXDataParse(stanza);
};

export const inboxParse = (
  stanza: Element,
  myJid: string
): TStoreInboxMessage => {
  const result = stanza.getChild('result', XML_NAMESPACES.INBOX);
  const { unread, queryid: queryId } = result?.attrs || {};
  const forwarded = result?.getChild('forwarded', XML_NAMESPACES.FORWARD);
  const { stamp = '' } = forwarded ? getDelay(forwarded) : {};
  const message = forwarded?.getChild('message');
  const parsedMessage = inboxMessageParse(message as Element, myJid);
  const box = result?.getChildText('box') || '';
  const mute = result?.getChildText('mute') || '';

  return {
    ...parsedMessage,
    delay: stamp,
    date: stamp || new Date().toString(),
    unread,
    queryId,
    box,
    mute
  };
};

export const inboxConversationMessageParse = (
  stanza: Element
): TInboxConversationMessage => {
  const x = stanza.getChild('x', XML_NAMESPACES.INBOX_CONVERSATION);
  const { jid: roomJid } = x?.attrs || {};
  const read = x?.getChildText('read') || '';
  const mute = x?.getChildText('mute') || '';

  return { roomJid, mute, unread: read === 'true' ? '0' : '1' };
};
