import { camelizeKeys } from 'humps';

import { TBaseTournament, TTournamentsResponse } from '../../../../types';

const getTournamentsAdapter = (
  response: TTournamentsResponse
): TBaseTournament[] => {
  const { result } = response;
  return result.map((item) => camelizeKeys(item)) as TBaseTournament[];
};

export default getTournamentsAdapter;
