import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const CommonChat = lazy(() => import('./CommonChat'));

export default (props: ComponentProps<typeof CommonChat>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <CommonChat {...props} />
  </Suspense>
);
