import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const SportFilters = lazy(() => import('./SportFilters'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <SportFilters {...props} />
  </Suspense>
);
