import React, { FC, useMemo } from 'react';

import { FLEX_ALIGN, FLEX_JUSTIFY, FlexBox } from '@monorepo/ui-kit';

import { MARKET_TYPES, MARKETS_ORDER_MAP } from '../../../../constants';
import { useLineGamesData } from '../../../../hooks/useGetLineGames';
import { useLineMarketsData } from '../../../../hooks/useGetLineMarkets';
import useLineParameters from '../../../../hooks/useLineParameters';
import {
  selectGameBySportIdRegionIdCompetitionIdGameId,
  selectGameOrderedMarketTitles
} from '../../../../store/api/selectors';
import getTeamId from '../../../../utils/getTeamId';
import Team from '../Team';
import Teams from '../Teams';

import MarketTitlesSkeleton from './MarketTitlesSkeleton';

import styles from './index.module.css';

type TMarketTitles = {
  gameId: number;
  className?: string;
  competitionId: number;
  sportId: number;
  regionId: number;
};

const MarketEventTitles: FC<TMarketTitles> = ({
  gameId,
  className = '',
  competitionId,
  sportId,
  regionId
}) => {
  const { marketType = MARKET_TYPES.SINGLE } = useLineParameters();
  const displayKeys = useMemo(
    () => MARKETS_ORDER_MAP[marketType],
    [marketType]
  );
  const { data: game, isFetching } = useLineGamesData((result) =>
    selectGameBySportIdRegionIdCompetitionIdGameId(
      result,
      sportId,
      regionId,
      competitionId,
      gameId,
      ['team1_name', 'team2_name', 'team1_id', 'team2_id']
    )
  );
  const { team1Name, team2Name } = game || {};

  const {
    data: titles2 = [],
    isSuccess,
    isFetching: isFetching2
  } = useLineMarketsData((result) =>
    selectGameOrderedMarketTitles(
      result,
      gameId,
      displayKeys,
      team1Name,
      team2Name
    )
  );
  const [marketTitles = []] = titles2;

  const isWithTitles = marketTitles?.length;

  if (isFetching || isFetching2) {
    return (
      <MarketTitlesSkeleton
        skeletonWrappClassName={styles.skeletonWrap}
        skeletonItemClassName={styles.skeletonItem}
      />
    );
  }

  if (isWithTitles && game !== null) {
    return (
      <FlexBox
        justify={FLEX_JUSTIFY.center}
        align={FLEX_ALIGN.center}
        className={className}
      >
        {marketTitles.map((title: string) => {
          const teamId = getTeamId(team1Name, team2Name, title);
          const itemId = game[teamId] || '';

          return <Team key={title} itemId={itemId} title={title} />;
        })}
      </FlexBox>
    );
  }

  if (isSuccess && !isWithTitles) {
    return <Teams game={game} className={className} />;
  }

  return null;
};

export default MarketEventTitles;
