import React, { ReactElement, useMemo } from 'react';

import { FAVORITE_LINK_TYPES, FavoriteLinkButton } from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { ComponentWithConfig } from '@monorepo/type';
import { TabsNavigation, TMenuItem } from '@monorepo/ui-kit';

import styles from './index.module.css';

type TConfig = {
  items: TMenuItem[];
  replace?: boolean;
  withFavorite?: boolean;
  favoriteType?: FAVORITE_LINK_TYPES;
};

const DesktopPreMatchSportTabs: ComponentWithConfig<TConfig> = ({
  config
}): ReactElement => {
  const { items, replace = false, withFavorite, favoriteType } = config;
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const translatedItems = useMemo<TMenuItem[]>(
    () =>
      items.map((item) => ({
        ...item,
        title: t(item.title)
      })),
    [t, items]
  );
  return (
    <TabsNavigation
      replace={replace}
      items={translatedItems}
      listClassName={styles.list}
      wrapperClassName={styles.wrap}
      itemClassName={styles.item}
      activeClassName={styles.active}
    >
      {withFavorite && <FavoriteLinkButton type={favoriteType} />}
    </TabsNavigation>
  );
};

export default DesktopPreMatchSportTabs;
