import { useEffect } from 'react';

import { useSelectJwtAuthToken } from '@monorepo/auth';

import xmppInstance from '../../store/api/xmppInstance';
import { getDiscoInfo, getDiscoItems } from '../../utils/messageCreators/room';

export const instance = xmppInstance();

const useXMPPConnect = () => {
  const jwtAuthToken = useSelectJwtAuthToken();

  useEffect(() => {
    if (jwtAuthToken && !instance.isConnected()) {
      instance.connect();
      /* temporary part start */
      /*  todo delete  */
      window.getDiscoInfo = (to: string) => instance.send(getDiscoInfo(to));
      window.getDiscoItems = (to: string) => instance.send(getDiscoItems(to));

      /* temporary part end */
    }
    if (!jwtAuthToken && instance.isConnected()) {
      instance.stop();
    }
  }, [jwtAuthToken]);

  useEffect(
    () => () => {
      instance.stop();
    },
    []
  );

  return instance;
};

export default useXMPPConnect;
