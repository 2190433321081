import Element from 'ltx/src/Element';

import { MESSAGE_MARK } from '../../../../constants/messages';
import { parseStanzaTypeError } from '../../../../utils/stanzaParsers';
import MessageHandler from '../MessageHandler';

class ErrorMessageHandler {
  messageHandler: MessageHandler;

  constructor(handler: MessageHandler) {
    this.messageHandler = handler;
  }

  public processStanza = (stanza: Element) => {
    const { updateMessage } = this.messageHandler.reduxActions;
    const message = parseStanzaTypeError(stanza);

    console.error(`Message error: ${JSON.stringify(message)}`);

    updateMessage({ ...message, mark: MESSAGE_MARK.ABORTED });
  };
}

export default ErrorMessageHandler;
