import React from 'react';
import camelCase from 'lodash/camelCase';

import { Component } from '@monorepo/type';

import { TMenuItem } from '../../../types';
import NavItem from '../NavItem';

import styles from './index.module.css';

const Menu: Component<{
  items: TMenuItem[];
  isSubmenu?: boolean;
  linkClassName?: string;
  children?: React.ReactNode;
}> = ({
  items = [],
  isSubmenu = false,
  className = '',
  linkClassName = '',
  children
}) => (
  <ul className={`${styles.list} ${className}`}>
    {items.map(
      ({
        title,
        link,
        items: subItems,
        iconName = '',
        disabled = false,
        target = ''
      }) => (
        <NavItem
          key={title}
          title={title}
          link={link}
          target={target}
          iconName={iconName}
          disabled={disabled}
          subItems={subItems}
          isSubItem={isSubmenu}
          linkClassName={linkClassName}
          data-custom={`navigation-${camelCase(title)}`}
        />
      )
    )}
    {children}
  </ul>
);

export default Menu;
