import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import keys from 'lodash/keys';

import { useTypedSelector } from '@monorepo/redux';

import { TBetInfo } from '../../../types';
import { checkAllUniqueElementsOffArray } from '../../../utils';

const getBetsId = (state: any) => state.betslip.betsId;

export const useSelectBetsId = () => useTypedSelector(getBetsId);

const getBetsInfo = (state: any) => state.betslip.betsInfo;

export const useSelectBetsInfo = () => useTypedSelector(getBetsInfo);

const getGamesId = (state: any) => state.betslip.gamesId;

export const useSelectGamesId = () => useTypedSelector(getGamesId);

const getActiveBet = (eventId: number) => (state: any) =>
  state.betslip.betsInfo.some((bet: TBetInfo) => bet.eventId === eventId);

export const useSelectActiveBet = (eventId: number) =>
  useTypedSelector(getActiveBet(eventId));

const getBetInfoById = (eventId: number) => (state: any) =>
  state.betslip.betsInfo.find((bet: TBetInfo) => bet.eventId === eventId);

export const useSelectBetById = (eventId: number) =>
  useTypedSelector(getBetInfoById(eventId));

export const getBetslipStatus = (state: any) => state.betslip.status;
export const useSelectBetslipStatus = () => useTypedSelector(getBetslipStatus);

const getOddMode = (state: any) => state.betslip.oddMode;
export const useSelectOddMode = () => useTypedSelector(getOddMode);

const getOddChanged = (state: any) => state.betslip.isOddChanged;
export const useSelectOddChanged = () => useTypedSelector(getOddChanged);

const getChangedBets = (state: any) => state.betslip.changedBets;
export const useSelectChangedBets = () => useTypedSelector(getChangedBets);

const getBetslipType = (state: any) => state.betslip.type;
export const useSelectBetslipType = () => useTypedSelector(getBetslipType);

const getMultipleMessage = (state: any) =>
  state.betslip.isMultipleMessageVisible;
export const useSelectMultipleMessage = () =>
  useTypedSelector(getMultipleMessage);

const getBetslipIsOpened = (state: any) => state.betslip.isOpened;
export const useBetslipIsOpened = () => useTypedSelector(getBetslipIsOpened);

const getPreviousIndex = (state: any) => state.betslip.previousIndex;
export const useBetslipPreviousIndex = () => useTypedSelector(getPreviousIndex);

const getExpBetsTime = (state: any) => state.betslip.expBetsTime;
export const useGetExpBetsTime = () => useTypedSelector(getExpBetsTime);

const getBetslipSuccessTime = (state: any) => state.betslip.betslipSuccessTime;
export const useGetBetslipSuccessTime = () =>
  useTypedSelector(getBetslipSuccessTime);

const getSingleSavedValue = (state: any) => state.betslip.singleSavedValue;

export const useSelectSingleSavedValue = () =>
  useTypedSelector(getSingleSavedValue);

const getMultipleSavedValue = (state: any) => state.betslip.multipleSavedValue;

export const useSelectMultipleSavedValue = () =>
  useTypedSelector(getMultipleSavedValue);

export const getExpressValues = (state: any) => state.betslip.expressValues;

export const allExpressValuesUnique = (state: any) =>
  Object.values(state.betslip.expressValues).every((game) =>
    checkAllUniqueElementsOffArray(Object.values(game))
  );

export const useAllExpressValuesUnique = () =>
  useTypedSelector(allExpressValuesUnique);

export const getConflictingBetIds = (state: any) =>
  flatMap(state.betslip.expressValues, (game) => {
    const groupedByExpress = groupBy(keys(game), (key) => game[key]);

    const betsWithDuplicateExpress = filter(
      groupedByExpress,
      (bet) => bet.length > 1
    );

    return flatten(betsWithDuplicateExpress);
  });

export const useConflictingBetIds = () =>
  useTypedSelector(getConflictingBetIds);
