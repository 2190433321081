import React from 'react';

import { Component } from '@monorepo/type';

import { PAY_METHOD } from '../../../constants';
import { TPaymentAction } from '../../../types';
import convertFormToUrl from '../../../utils/convertFormToUrl';
import PaymentIFrame from '../PaymentIFrame';
import PaymentSubmitForm from '../PaymentSubmitForm';

type TDepositActionProps = {
  actionData: TPaymentAction | null;
  setResult?: () => void;
  quickDeposit?: boolean;
};
const DepositAction: Component<TDepositActionProps> = ({
  actionData,
  setResult,
  quickDeposit
}) => {
  if (!actionData) {
    return null;
  }

  const url = convertFormToUrl(actionData.action, actionData.fields);

  if (
    actionData.method === PAY_METHOD.GET ||
    actionData.method === PAY_METHOD.POST
  ) {
    return (
      <PaymentSubmitForm
        formData={actionData}
        url={url}
        quickDeposit={quickDeposit}
      />
    );
  }

  if (actionData.method === PAY_METHOD.IFRAME) {
    return <PaymentIFrame url={url} setResult={setResult} />;
  }

  return (
    <div>
      <div>unknown method</div>
      <div>{JSON.stringify(actionData)}</div>
    </div>
  );
};

export default DepositAction;
