import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const Backheader = lazy(() => import('./Backheader'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <Backheader config={config} />
  </Suspense>
);
