import { camelizeKeys } from 'humps';

const deleteBlog = () => ({
  query: ({ id, jwt }) => ({
    url: `/post/${id}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwt}`
    }
  }),
  keepUnusedDataFor: 0,
  transformResponse: (response: any): any => camelizeKeys(response)
});

export default deleteBlog;
