import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const MobilePopularCompetitions = lazy(
  () => import('./MobilePopularCompetitions')
);

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <MobilePopularCompetitions {...props} />
  </Suspense>
);
