import { useSiteSettings } from '@monorepo/site-settings';

import { useGetGameInformationQuery } from '../../store/customGameApiStore';

const useGetGameInformation = (
  gameId: string,
  source: string,
  name?: string
) => {
  const { siteId } = useSiteSettings();
  return useGetGameInformationQuery(
    {
      gameId,
      source,
      partnerId: siteId,
      name
    },
    {
      selectFromResult: (result) => ({
        data: result.data?.filter(({ game_id }) => game_id === gameId)
      })
    }
  );
};

export default useGetGameInformation;
