/* eslint-disable no-param-reassign */

import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';

import { TPayload } from '@monorepo/type';

import { TSignUpData, TSignUpDataState } from '../types/signUpData';

const initialState = {
  signUpData: {}
};

export const userSignUp = createSlice<
  TSignUpDataState,
  SliceCaseReducers<TSignUpDataState>
>({
  name: 'userSignUp',
  initialState,
  reducers: {
    resetSignUpData: (state) => {
      state = initialState;
      return state;
    },
    setSignUpData: (state, { payload }: TPayload<TSignUpData>) => {
      state.signUpData = payload;
      return state;
    }
  }
});

export const { actions } = userSignUp;
