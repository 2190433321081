import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import {
  AuthLink,
  TLoginRequest,
  TPartnerConfig,
  TRegisterCurasaoRequest,
  TRegisterRequest,
  useGetPartnerConfig,
  useLogin,
  useRegister
} from '@monorepo/auth';
import {
  GooglePrivacyBadge,
  Recaptcha,
  RECAPTCHA_ACTIONS
} from '@monorepo/common';
import {
  CheckboxField,
  emailRegExpValidator,
  Form as CommonForm,
  InputField,
  minLengthValidator,
  passwordRegExpValidator,
  requiredValidator,
  SelectField
} from '@monorepo/forms';
import { COOKIE_KEYS, getCookie } from '@monorepo/helpers';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import {
  Box,
  Button,
  BUTTON_SIZE,
  FIELD_TONE,
  FLEX_ALIGN,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox
} from '@monorepo/ui-kit';

import CitizenshipCurrency from '../CitizenshipCurrency';
import RulesLabel from '../RulesLabel';

import { FIELD_TO_ERROR_MAP } from './constants';

import styles from './index.module.css';

const Form = ({
  defaultCountry = '',
  defaultCurrency = '',
  snippets = {},
  countries,
  countriesFromJSON,
  className = ''
}: {
  defaultCountry: string;
  defaultCurrency: string;
  snippets: Record<string, any>;
  countries: any;
  countriesFromJSON: any;
  className?: string;
}) => {
  const { t } = useI18n(NAMESPACES.FORMS);
  const { data: partnerConfig } = useGetPartnerConfig();
  const [isValidCaptcha, setIsValidCaptcha] = useState<boolean | string>(false);
  const { generalRoutes, defaultLanguage } = useSiteSettings();
  const { deposit, login } = generalRoutes;
  const navigate = useNavigate();

  const { regCurns, supportedCurrencies = [] } =
    (partnerConfig as TPartnerConfig) || {};
  const isEmptyConfig = isEmpty(partnerConfig);
  const currencies = (regCurns || supportedCurrencies).map((item: string) => ({
    value: item,
    label: item
  }));

  const defaultValues: TRegisterRequest = {
    confirmEmailUses: false,
    username: '',
    citizenship: defaultCountry || countries[0]?.value,
    email: '',
    password: '',
    rules: true,
    currency: defaultCurrency,
    langCode: defaultLanguage,
    promocode: getCookie(COOKIE_KEYS.BTAG, '')
  };
  const formContextRef = useRef(null);

  const onError = (error: any) =>
    // @ts-ignore
    formContextRef.current?.setError(error?.field, {
      message: error.message,
      type: 'custom'
    });
  const { onLogin } = useLogin();
  const onSuccess = async () => {
    // @ts-ignore
    const regData = formContextRef.current.getValues();
    await onLogin({
      login: regData.username,
      password: regData.password
    } as TLoginRequest);
    navigate(deposit);
  };

  const { onRegister } = useRegister(onError, FIELD_TO_ERROR_MAP, onSuccess);
  const save = async () => {
    try {
      // @ts-ignore
      const signUpData = formContextRef.current.getValues();
      // @ts-ignore
      return onRegister({
        ...(signUpData as TRegisterCurasaoRequest),
        langCode: defaultLanguage
      });
    } catch {
      return false;
    }
  };

  return defaultCurrency && !isEmptyConfig && countries ? (
    <CommonForm
      ref={formContextRef}
      // @ts-ignore
      onSubmit={save}
      className={className}
      formSettings={{
        defaultValues,
        values: {
          rawPhone: '',
          currency: defaultCurrency,
          citizenship: defaultCountry || countries[0]?.value
        },
        mode: 'onBlur'
      }}
    >
      <FlexBox direction={FLEX_DIRECTION.column} className={styles.wrap}>
        <InputField<TRegisterCurasaoRequest>
          id="username"
          name="username"
          label={t('registration.form.nickName.label')}
          placeholder={t('registration.form.nickName.placeholder')}
          rules={{
            ...requiredValidator(),
            ...minLengthValidator(2, t('validators.nickname.min'))
          }}
          toneType={FIELD_TONE.light}
          styledClassName={styles.input}
          labelClassName={styles.label}
          className={styles.inputRegistration}
          errorClassName={styles.error}
        />
        <InputField<TRegisterCurasaoRequest>
          id="email"
          name="email"
          label={t('registration.form.email.label')}
          autoComplete="off"
          placeholder={t('registration.form.email.placeholder')}
          isClearable
          rules={{
            ...requiredValidator(),
            ...emailRegExpValidator(
              t('registration.field.validate.email.format')
            )
          }}
          toneType={FIELD_TONE.light}
          styledClassName={styles.input}
          labelClassName={styles.label}
          className={styles.inputRegistration}
          errorClassName={styles.error}
        />

        <FlexBox
          className={styles.countryCurrency}
          direction={FLEX_DIRECTION.column}
        >
          <SelectField<TRegisterCurasaoRequest>
            id="citizenship"
            name="citizenship"
            options={countriesFromJSON}
            label={t('updateprofile.form.country.label')}
            toneType={FIELD_TONE.light}
            inputClassName={styles.select}
            labelClassName={styles.label}
            className={`${styles.inputRegistration} ${styles.country}`}
            errorClassName={styles.error}
          />
          <CitizenshipCurrency
            currencies={currencies as { value: string; label: string }[]}
            desktopTone={FIELD_TONE.light}
            countries={countriesFromJSON}
            partnerConfig={partnerConfig as TPartnerConfig}
            watchField="citizenship"
          />
        </FlexBox>

        <InputField<TRegisterCurasaoRequest>
          id="password"
          name="password"
          type="password"
          autoComplete="new-password"
          label={t('registration.form.password.label')}
          placeholder={t('registration.form.password.placeholder')}
          rules={{ ...requiredValidator(), ...passwordRegExpValidator() }}
          toneType={FIELD_TONE.light}
          styledClassName={styles.input}
          className={styles.inputRegistration}
          labelClassName={styles.label}
          errorClassName={styles.error}
        />
        <InputField<TRegisterCurasaoRequest>
          id="promocode"
          name="promocode"
          autoComplete="off"
          label={t('registration.form.promocode.label')}
          placeholder={t('registration.form.promocode.placeholder')}
          toneType={FIELD_TONE.light}
          styledClassName={styles.input}
          labelClassName={styles.label}
          className={styles.inputRegistration}
          errorClassName={styles.error}
        />
        <CheckboxField<TRegisterCurasaoRequest>
          id="rules"
          name="rules"
          label={<RulesLabel snippets={snippets} />}
          rules={{ ...requiredValidator() }}
          toneType={FIELD_TONE.light}
          inputClassName={styles.checkbox}
          labelClassName={styles.checkboxLabel}
          className={styles.checkmark}
          errorClassName={styles.error}
        />
        <Recaptcha
          action={RECAPTCHA_ACTIONS.REGISTRATION}
          onValidated={setIsValidCaptcha}
        />

        <GooglePrivacyBadge toneType={FIELD_TONE.light} />
      </FlexBox>
      <Box className={styles.bottom}>
        <Button
          disabled={!isValidCaptcha}
          // @ts-ignore
          type="submit"
          size={BUTTON_SIZE.h40}
          className={styles.submit}
        >
          {t('registration.form.submit')}
        </Button>
        <FlexBox
          align={FLEX_ALIGN.end}
          justify={FLEX_JUSTIFY.center}
          className={styles.login}
        >
          <Box className={styles.loginText}>
            {t('registration.link.alreadyhaveaccount')}
          </Box>
          <AuthLink to={login} className={styles.loginLink}>
            {t('login.form.submit')}
          </AuthLink>
        </FlexBox>
      </Box>
    </CommonForm>
  ) : null;
};

export default Form;
