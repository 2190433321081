import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getSearchString } from '@monorepo/helpers';

import {
  TMUCRoomsRequest,
  TMUCRoomsRequestResult,
  TMUCRoomsResponse
} from '../types';

export const GET_MUC_ROOMS_TAG = 'GET_MUC_ROOMS_TAG';

export const socialsAdditionalApi = createApi({
  reducerPath: 'socialsAdditionalApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `/api/v1`
  }),
  tagTypes: [GET_MUC_ROOMS_TAG],
  endpoints: (build) => ({
    getMucRooms: build.query<TMUCRoomsResponse, TMUCRoomsRequest>({
      query: ({
        jwt,
        host,
        limit = 20,
        sort = 'desc',
        category,
        title,
        offsetId
      }) => {
        const url = `/mim/chats${getSearchString({
          xmpp_host: host,
          category,
          title,
          limit,
          offset_id: offsetId,
          sort
        })}`;
        return {
          url,
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwt}`
          }
        };
      },
      providesTags: [GET_MUC_ROOMS_TAG],
      transformResponse: (response: TMUCRoomsRequestResult) => {
        const {
          xmpp_muc_items_prev_offset_id: prevOffsetId,
          xmpp_muc_items_next_offset_id: nextOffsetId,
          xmpp_muc_items: items = [],
          xmpp_host: host
        } = response || {};

        const list = items.map((item) => {
          const { id, room_name: roomName, options } = item;
          return { id, roomName, ...options };
        });

        return { prevOffsetId, nextOffsetId, host, list };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { offsetId, ...rest } = queryArgs;

        return rest;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        const { offsetId } = currentArg || {};
        const { offsetId: prevOffsetId } = previousArg || {};

        return Boolean(offsetId) && offsetId !== prevOffsetId;
      },
      merge: (currentCacheData, responseData) => {
        const { prevOffsetId, nextOffsetId, list } = responseData;
        currentCacheData.list.push(...list);
        Object.assign(currentCacheData, { prevOffsetId, nextOffsetId });
      }
    })
  })
});

export const { useGetMucRoomsQuery } = socialsAdditionalApi;
