import React, { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { useSiteSettings } from '@monorepo/site-settings';
import { CustomFC } from '@monorepo/type';

import {
  MESSENGER_VIEW,
  SENT_POST_MESSAGE_TYPE,
  SOCIAL_FRAME_NAME
} from '../../../../constants/frames';
import useSelectFriends from '../../../../hooks/selectors/useSelectRosterFriends';
import AvatarWithName from '../FriendAvatar';

import styles from './index.module.css';

type Props = {
  isHorizontal?: boolean;
  isInFrame?: boolean;
};

const FriendsList: CustomFC<Props> = ({ isHorizontal, isInFrame = false }) => {
  const friends = useSelectFriends();
  const { generalRoutes } = useSiteSettings();
  const { personalChats } = generalRoutes;

  const handleClick = (id: string) => (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    parent.postMessage(
      {
        type: SENT_POST_MESSAGE_TYPE.REDIRECT,
        data: {
          name: SOCIAL_FRAME_NAME.MESSENGER,
          frameData: {
            defaultView: MESSENGER_VIEW.PERSONAL,
            chatId: id
          }
        }
      },
      '*'
    );
  };

  return (
    <>
      {friends?.map((id) => (
        <Link
          key={id}
          to={`${personalChats}/${id}`}
          onClick={isInFrame ? handleClick(id) : undefined}
          className={` ${styles.item} ${
            isHorizontal ? styles.horizont : styles.vertical
          }`}
        >
          <AvatarWithName id={id} isHorizontal={isHorizontal} />
        </Link>
      ))}
    </>
  );
};

export default FriendsList;
