import React, { ComponentProps, lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const MatchPreviewPage = lazy(() => import('./MatchPreviewPage'));

export default (props: ComponentProps<typeof MatchPreviewPage>) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <MatchPreviewPage {...props} />
  </Suspense>
);
