import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const ChatsProfile = lazy(() => import('./ChatsProfile'));

export default ({ config }: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <ChatsProfile config={config} />
  </Suspense>
);
