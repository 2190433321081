import React, { lazy, Suspense } from 'react';

import { DefaultSkeleton } from '@monorepo/ui-kit';

const SportsLineSearch = lazy(() => import('./SportsLineSearch'));

export default (props: any) => (
  <Suspense fallback={<DefaultSkeleton />}>
    <SportsLineSearch {...props} />
  </Suspense>
);
