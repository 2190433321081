import { useMatch } from 'react-router-dom';

import { useSiteSettings } from '@monorepo/site-settings';

type TUseHeaderSearch = () => {
  searchPageLink: string;
};

const useHeaderSearch: TUseHeaderSearch = () => {
  const { generalRoutes } = useSiteSettings();
  const { searchCasino, searchSports, casinoCategory } = generalRoutes;
  const matchCasino = useMatch({
    path: casinoCategory,
    end: false
  });

  return {
    searchPageLink: matchCasino ? searchCasino : searchSports
  };
};

export default useHeaderSearch;
