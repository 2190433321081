import { useDeviceDetector } from '@bc-betkit/device-detector';

import { useGeoIP } from '@monorepo/cms';
import { useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';

import { useGetGamesImagesQuery } from '../../store/casino';
import { TImageItem } from '../../types';

const useGetWinnersList = (useGetWinnersQuery: any, amount: number) => {
  const { language } = useI18n();
  const { siteId } = useSiteSettings();
  const { isIOSOld, isDesktopDevice } = useDeviceDetector();
  const { data, isLoading } = useGeoIP();
  const { data: winners, isFetching } = useGetWinnersQuery(
    { count: amount },
    { pollingInterval: 30000 }
  );

  const getIdList = winners?.reduce((acc, { gameId }) => {
    const isConsist = acc.includes(gameId);
    if (!isConsist) return [...acc, gameId];
    return acc;
  }, []);

  const { data: imagesList, isFetching: isFetching1 } = useGetGamesImagesQuery(
    {
      partnerId: siteId,
      isMobile: isDesktopDevice ? 0 : 1,
      language,
      useWebp: isIOSOld ? 0 : 1,
      externalIds: getIdList,
      country: data?.countryCode,
      onlyImages: 1
    },
    { skip: !getIdList?.length || isLoading }
  );

  const winnersFetching = isFetching || isFetching1;

  const fullWinnersData = winners?.reduce((acc, el) => {
    const { gameId } = el;
    const itemForElement = imagesList?.find(
      (item: TImageItem) => Number(item.extearnalGameId) === gameId
    ) as TImageItem;

    const fullWinnerData = { ...el, ...itemForElement };
    return [...acc, fullWinnerData];
  }, []);

  return { fullWinnersData, winnersFetching };
};

export default useGetWinnersList;
