import { TCmsMenuItem, TMenuItem } from '@monorepo/ui-kit';

const ICONS = {
  'two-column': 'wall_0',
  'balance-management': 'wallet_1',
  withdraw: 'money_bag_0',
  history: 'history_0',
  wallet: 'wallet_2',
  user: 'edit_0',
  casino: 'roulette_2',
  sport: 'football_1',
  SpecialBets: 'my_bets-0',
  results: 'results',
  promotion: 'cup',
  favorite: 'favorite',
  bonus: 'bonuses-0',
  live: 'broadcast-0',
  goal: 'world-0',
  jackpots: 'diamond-outline',
  help: 'messages',
  notification: 'notification',
  Football: 'football_1',
  Esports: 'demo-esports',
  play: 'demo-inplay',
  'live-casino': 'demo-live-casino',
  minigames: 'demo-mini-games',
  Poker: 'demo-pocker',
  tvgames: 'demo-tv-games',
  VirtualFootballLeague: 'demo-virtual-sports',
  search: 'search_1',
  'block-to-right': 'meta_logo',
  deposit: 'wallet',
  fruits: 'invite_friends',
  layers: 'user',
  filmslot: 'contact',
  Vip: 'home-0',
  payment1: 'money_bag_0',
  payment2: 'wallet_2',
  payment3: 'money_bag_0',
  payment4: 'crypto-0',
  payment5: 'bank_transfer_0',
  payment6: 'open_banking-0',
  payment8: 'mobile-0',
  paymentall: 'all-0',
  paymentothers: 'others_cards-0',
  'blogs-category-sport': 'football_1',
  'blogs-category-casino': 'roulette_2',
  'blogs-category-video': 'videocam',
  'blogs-category-analytics': 'user'
};

export const transformCmsIcons = (cmsIcon: string): string =>
  ICONS[cmsIcon as keyof typeof ICONS] || cmsIcon;

/**
 * @param items
 * @param contentUrl
 * @param isAuthenticated null - not filter, true - filter items for authenticated users
 */
export const transformMenuItems = (
  items: TCmsMenuItem[] = [],
  isAuthenticated: boolean | null = null,
  contentUrl: string = ''
): TMenuItem[] => {
  const formatedItems = items.map((item: TCmsMenuItem) => ({
    id: item.id,
    title: item.title,
    link: item.type === 'content' ? `${contentUrl}/${item.value}` : item.value,
    iconName: transformCmsIcons(item.icon),
    type: item.type,
    target: item.target,
    items: item.children?.length
      ? transformMenuItems(item.children, isAuthenticated, contentUrl)
      : [],
    showTo: item.showTo
  }));
  if (isAuthenticated === null || isAuthenticated) {
    return formatedItems;
  }
  return formatedItems.filter(
    (menuItem) => !isAuthenticated && menuItem.showTo !== 1
  );
};
