import React from 'react';
import Helmet from 'react-helmet';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component as TComponent } from '@monorepo/type';

type TMetaTagsProps = {
  title: string;
  description: string;
};

const MetaTags: TComponent<TMetaTagsProps> = (props) => {
  const { title, description } = props;
  const { t } = useI18n(NAMESPACES.PAGES);
  const { isDesktop } = useDeviceDetector();
  const metaScalable = isDesktop ? (
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  ) : (
    <meta
      name="viewport"
      content="width=device-width,
          initial-scale=1,
          user-scalable=no"
    />
  );
  return (
    <Helmet>
      {metaScalable}
      {description && <meta name="description" content={t(description)} />}
      <title>{title ? t(title) : t('title.defaulttitle')}</title>
    </Helmet>
  );
};

export default MetaTags;
