import React, { useCallback } from 'react';

import { Component } from '@monorepo/type';

import { useMenuSportsData } from '../../../../hooks/useGetMenuSports';
import { selectSportsBySportId } from '../../../../store/api/selectors';
import { TSport } from '../../../../types';
import SelectedSportMenu from '../../../SportNavigation/components/SelectedSportMenu';
import SportMenuItemAccordion from '../SportMenuItemAccordion';

type TSportItemProps = {
  sportId: number;
  activeSport: string;
  setSport: (sportAlias: string) => void;
  region?: string;
  rootPath: string;
};

const SportItem: Component<TSportItemProps> = ({
  sportId,
  setSport,
  activeSport,
  className = '',
  region = '',
  rootPath
}) => {
  const { data: sport } = useMenuSportsData<TSport>((result) =>
    selectSportsBySportId(result, sportId)
  );

  const { alias, name, game: count } = sport;

  const onClick = useCallback(() => {
    setSport(alias);
  }, [alias, setSport]);

  return (
    <SportMenuItemAccordion
      isActive={activeSport === alias}
      onClick={onClick}
      iconName={alias?.toLowerCase()}
      name={name}
      count={count}
      className={className}
      alias={alias}
    >
      <SelectedSportMenu
        sport={sport?.alias}
        region={region}
        rootPath={rootPath}
      />
    </SportMenuItemAccordion>
  );
};

export default SportItem;
