import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { AVATAR_SIZE } from '@monorepo/files';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  Button,
  BUTTON_INTENT,
  BUTTON_SIZE,
  FIELD_TONE,
  FlexBox,
  NotificationLayout,
  NotificationText,
  NotificationTitle,
  TEXT_SIZE
} from '@monorepo/ui-kit';
import { getIdFromJID } from '@monorepo/xmpp';

import useChatUser from '../../hooks/useChatUser';
import useRosterActions from '../../hooks/useRosterActions';
import { TNotificationProps } from '../../types';
import FriendAvatar from '../FriendAvatar';

import styles from './index.module.css';

type Props = TNotificationProps & { toneType: FIELD_TONE; textSize: TEXT_SIZE };

const FriendRequestNotification: Component<Props> = ({
  userJid,
  closeToast,
  className = '',
  toneType = FIELD_TONE.light,
  textSize = TEXT_SIZE.small
}) => {
  const { t } = useI18n(NAMESPACES.SOCIAL);
  const { generalRoutes } = useSiteSettings();
  const { profile } = generalRoutes;
  const { acceptSubscription, denySubscription } = useRosterActions();
  const userId = getIdFromJID(userJid);
  const { displayName, jid } = useChatUser(userId);

  const friendLink = `${profile}/${userId}`;

  const handleAccept = useCallback(() => {
    if (closeToast) {
      closeToast();
    }
    return acceptSubscription(jid?.toString() as string, displayName);
  }, [jid, displayName, acceptSubscription]);

  const handleDecline = useCallback(() => {
    if (closeToast) {
      closeToast();
    }
    return denySubscription(jid?.toString() as string);
  }, [jid, denySubscription]);

  return (
    <NotificationLayout
      className={`${styles.wrap} ${className}`}
      image={
        <Link to={friendLink}>
          <FriendAvatar
            id={userId}
            className={styles.avatar}
            avatarClass={styles.avatarImg}
            isWithStatus={false}
            size={AVATAR_SIZE.S}
          />
        </Link>
      }
    >
      <Link to={friendLink}>
        <NotificationTitle
          textSize={textSize}
          toneType={toneType}
          className={styles.title}
        >
          {displayName}
        </NotificationTitle>
      </Link>
      <NotificationText
        textSize={textSize}
        toneType={toneType}
        className={styles.text}
      >
        {t('notification.friend.request.text', { nickname: displayName })}
      </NotificationText>
      <FlexBox className={styles.controls}>
        <Button
          className={styles.button}
          intent={BUTTON_INTENT.danger}
          size={BUTTON_SIZE.h28}
          onClick={handleDecline}
        >
          {t('notification.friend.request.action.decline')}
        </Button>
        <Button
          className={styles.button}
          intent={BUTTON_INTENT.success}
          size={BUTTON_SIZE.h28}
          onClick={handleAccept}
        >
          {t('notification.friend.request.action.accept')}
        </Button>
      </FlexBox>
    </NotificationLayout>
  );
};

export default FriendRequestNotification;
