import React, { Children, cloneElement, isValidElement } from 'react';

import { Component } from '@monorepo/type';

import { SPORTS_COLORS } from '../../constants';

type Props = {
  alias: string;
};

const SportColor: Component<Props> = ({ alias = '', children }) => {
  const color = { style: { color: SPORTS_COLORS[alias] || '' } };
  return (
    <>
      {Children.map(children, (child) =>
        isValidElement(child) ? cloneElement(child, color) : null
      )}
    </>
  );
};
export default SportColor;
