import React from 'react';

import { Icon } from '@monorepo/icons';
import { useSiteSettings } from '@monorepo/site-settings';
import { Component } from '@monorepo/type';
import {
  FLEX_ALIGN,
  FLEX_JUSTIFY,
  FlexBox,
  PreloadImage
} from '@monorepo/ui-kit';

import styles from './index.module.css';

type Props = {
  displayName?: string;
  serviceId: string;
  imageFromCms?: boolean;
  imgSrc?: string;
  imgClassName?: string;
};

const PaymentImage: Component<Props> = ({
  serviceId = '',
  imgSrc = '',
  displayName = '',
  className,
  imgClassName,
  imageFromCms = false
}) => {
  const cmsHost = import.meta.env.VITE_APP_CMS_IMAGES_HOST;
  const { siteId } = useSiteSettings();
  const imagePaymentSource = imageFromCms
    ? `${cmsHost}/content/images/payments/custom/${siteId}/${serviceId}.png`
    : imgSrc;

  return (
    <PreloadImage
      src={imagePaymentSource}
      alt={displayName}
      className={`${styles.wrap} ${className}`}
      imgClassName={`${styles.image} ${imgClassName}`}
    >
      <FlexBox
        align={FLEX_ALIGN.center}
        justify={FLEX_JUSTIFY.center}
        className={styles.placeholder}
      >
        <Icon name="money_bag_0" className={styles.icon} />
      </FlexBox>
    </PreloadImage>
  );
};

export default PaymentImage;
