import React, { useRef } from 'react';
import { useDeviceDetector } from '@bc-betkit/device-detector';

import {
  TYPE_BY_PROVIDER,
  VIDEO_TYPE_HLS,
  VideoPlayer
} from '@monorepo/common';
import { NAMESPACES, useI18n } from '@monorepo/i18n';
import {
  Accordion,
  Box,
  BUTTON_INTENT,
  BUTTON_SIZE,
  EllipsisText,
  FLEX_DIRECTION,
  FLEX_JUSTIFY,
  FlexBox,
  Iframe
} from '@monorepo/ui-kit';

import useGetStream from '../../hooks/useGetStream';

import styles from './index.module.css';

const LiveStreamFrame = ({
  stream,
  withStream
}: {
  stream: Record<string, any>;
  withStream: boolean;
}) => {
  const { isDesktop } = useDeviceDetector();
  const { t } = useI18n(NAMESPACES.SPORTSBOOK);
  const frame = useRef();
  const { video_id: videoId, provider_id: providerId } = stream;
  const type: string | null =
    TYPE_BY_PROVIDER[providerId as keyof typeof TYPE_BY_PROVIDER] || null;
  const { data } = useGetStream(videoId, providerId, type, withStream);

  if (data?.status) {
    return (
      <FlexBox
        justify={FLEX_JUSTIFY.center}
        direction={FLEX_DIRECTION.column}
        className={styles.answer}
      >
        <Box className={styles.head}>{data?.status}</Box>
        <Box className={styles.message}>{data?.message}</Box>
      </FlexBox>
    );
  }

  const VideoFrame = data ? (
    <>
      {type === VIDEO_TYPE_HLS ? (
        <Box className={styles.videoWrapper}>
          <VideoPlayer className={styles.videoContainer} streamUrl={data} />
        </Box>
      ) : (
        <Box className={styles.iframeWrapper}>
          <Iframe
            ref={frame}
            className={styles.videoContainer}
            src={data}
            allowfullscreen
          />
        </Box>
      )}
    </>
  ) : null;

  return isDesktop ? (
    <FlexBox className={styles.wrap}>
      <Accordion
        className={styles.accordion}
        headerClassName={styles.head}
        bodyClassName={styles.accordionAnimationBody}
        activeClassName={styles.active}
        intentType={BUTTON_INTENT.tertiary}
        size={BUTTON_SIZE.h30}
        isDefaultOpen
        label={<EllipsisText>{t('sportsbook.video.stream')}</EllipsisText>}
      >
        {VideoFrame}
      </Accordion>
    </FlexBox>
  ) : (
    <>{VideoFrame}</>
  );
};

export default LiveStreamFrame;
