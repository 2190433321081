import React from 'react';
import { Link } from 'react-router-dom';

import { NAMESPACES, useI18n } from '@monorepo/i18n';
import { Component } from '@monorepo/type';

import styles from './index.module.css';

type Props = {
  snippets: Record<string, any>;
};

const RulesLabel: Component<Props> = ({ snippets = {} }) => {
  const { termsConditions, privacyPolicy } = snippets;
  const { t } = useI18n(NAMESPACES.FORMS);

  return (
    <>
      {t('registration.form.rules.curacao.label')}
      <Link
        to={`/content-page/${privacyPolicy}`}
        target="_blank"
        className={styles.link}
      >
        {t('registration.form.rules.curacao.link')}
      </Link>
      ,&nbsp;
      <Link
        to={`/content-page/${termsConditions}`}
        target="_blank"
        className={styles.link}
      >
        {t('registration.form.rules.curacao.link2')}
      </Link>
    </>
  );
};

export default RulesLabel;
