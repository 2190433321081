import get from 'lodash/get';

export const favoriteTeamMatchesAdapter = (
  rawResponse: Record<string, any>
): number[] | null => {
  const game = get(rawResponse, 'data.game', null);
  if (game) {
    return Object.keys(game).map((key) => Number(key));
  }
  return null;
};
